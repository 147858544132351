import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import {GetBillingRequest} from "@/typings/api/getBilling";
import { CreateEmailAutomationRequest, DeleteEmailAutomationRequest, GetEmailAutomationRequest, GetEmailAutomationVariableRequest, UpdateEmailAutomationRequest } from "@/typings/api/emailAutomationRequest";



export function fetchEmailAutomationListRequest({ query}: FetchEmailAutomationOptions) {
  const url = URLParamBuilder(PATHS.FETCH_EMAIL_AUTOMATION, {
    queryString: query
  })

  return request<GetEmailAutomationRequest["response"]>(url, {
    method: 'GET'
  });
}

export function createEmailAutomationRequest({ payload }) {
  return request<CreateEmailAutomationRequest["response"]>(PATHS.CREATE_EMAIL_AUTOMATION, {
    method: 'POST',
    data: payload,
  });
}
type ChangeEmailAutomationOptions = {
  params: UpdateEmailAutomationRequest["parameters"]
  payload: UpdateEmailAutomationRequest["payload"]
}
export function updateEmailAutomationRequest({ params, payload }: ChangeEmailAutomationOptions) {
const finalUrl = URLParamBuilder(PATHS.UPDATE_EMAIL_AUTOMATION, {
  params
})
return request<UpdateEmailAutomationRequest["response"]>(finalUrl, {
  method: 'PUT',
  data: payload,
});
}
type DeleteEmailAutomationRequestOptions = {
  params: DeleteEmailAutomationRequest["parameters"]
}
export function deleteEmailAutomationRequest({params}: DeleteEmailAutomationRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_EMAIL_AUTOMATION, {
    params
  })

  return request<DeleteEmailAutomationRequest["response"]>(url, {
    method: 'DELETE'
  });
}

export function fetchEmailAutomationVariableListRequest() {
  const url = URLParamBuilder(PATHS.FETCH_EMAIL_AUTOMATION_VARIABLES, {
  })

  return request<GetEmailAutomationVariableRequest["response"]>(url, {
    method: 'GET'
  });
}
import * as React from "react"
import {useEffect} from "react"
import {Avatar, AvatarProps, SelectProps} from "antd";
import {merge} from "lodash";

import {F} from "@/utils/intl";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import {useDispatch, useIntl, useModel, useSelector} from "umi";

import * as LeadSelectors from "@/selectors/leadSelectors"
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import {AssigneeModel} from "@/typings/models/Assignee";
import {UserAddOutlined, UserDeleteOutlined} from "@ant-design/icons";
import {hasAccess} from "@/utils/access";
import {SALE_AGENT} from "@/typings/roleMapping";
import isEmpty from "lodash/isEmpty";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import get from "lodash/get";

type ILeadAssigneeDropdownFiltersProps = {
  filterMode?: string,
  onChange?: (assigneeId: string) => void,
  onSelectionChange?: (assigneeId: string) => void,
  showUnassigned?: boolean,
  showCreate?: boolean,
  autoFocus?: boolean,
  value?: string
  selectProps?: SelectProps,
  closeContainerPopover?: () => void,

}
const LeadAssigneeDropdownFilters = (props: ILeadAssigneeDropdownFiltersProps) => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const assignees = useSelector(LeadSelectors.selectLeadAssignees)
  const intl = useIntl()
  const dispatch = useDispatch()
  const assigneeFilter = useSelector((state: DefaultRootState) => PreferencesSelectors.selectFilters(state, props.filterMode || FILTER_PLACES.LEAD_LIST))?.assignee

  const isSalesAgent = hasAccess(initialState.currentUser, SALE_AGENT)

  useEffect(() => {
    if (isEmpty(assignees)) {
      dispatch({
        type: 'leads/fetchAssignees'
      })
    }
  }, []);

  useEffect(() => {
    if (isSalesAgent && !assigneeFilter && assignees.length === 1) {
      dispatch({
        type: 'preferences/changeFilters',
        payload: {
          assignee: assignees[0].id,
          place: props.filterMode || FILTER_PLACES.LEAD_LIST,
          persist: true
        }
      })
    }
  }, [JSON.stringify(assignees)]);

  const getAssigneeName = (assignee: AssigneeModel) => `${assignee.firstname} ${assignee.lastname}`
  const getAssigneeIcon = (assignee: AssigneeModel) => {
    if (assignee.unassigned) return <UserDeleteOutlined style={{marginRight: 5}}/>
    if (assignee.create) return <UserAddOutlined style={{marginRight: 5}}/>

    const avatarProps: AvatarProps = {}
    if (assignee.avatar_link) {
      avatarProps.src = assignee.avatar_link
    }
    return <div style={{width: 24, height: 24, marginRight: 8}}>
      <Avatar {...avatarProps} size="small"
              style={{
                marginRight: 8,
                width: 24,
                height: 24,
                verticalAlign: "baseline"
              }}>{!assignee.avatar_link && getAssigneeName(assignee).slice(0,2)}</Avatar>
    </div>
  }
  const optionRender = (assignee: AssigneeModel) => {
    return <div style={{display: 'flex', alignItems: 'center'}}>
      {getAssigneeIcon(assignee)}
      <span>
        {isSalesAgent && !assignee.unassigned && `(${intl.formatMessage({
          id: 'pages.lead.assignee.me',
          defaultMessage: 'Me'
        })}) `}
        {getAssigneeName(assignee)}

      </span>
    </div>
  }

  const onChange = (assigneeId) => {
    if (assigneeId === 'create') {
      props.closeContainerPopover && props.closeContainerPopover()
      return dispatch({
        type: 'user/toggleDrawer',
        payload: {
          onCreate: (createResponse) => {
            const createdUserId = get(createResponse, 'data.user.id')
            if (createdUserId) props.onChange(createdUserId)
          }
        }
      })
    }

    const assignee = assignees.find(assignee => assignee.id === assigneeId)

    props.onSelectionChange && props.onSelectionChange(assigneeId);
    props.onChange && props.onChange(assigneeId, assignee);
  };
  const handleFilter = (input: string, option: any) => {
    if (option?.meta?.create) return true;
    return option.label?.toLowerCase().includes(input.toLowerCase());
  };

  const getOptions = () => {
    let options = assignees.map(assignee => ({...assignee, label: `${assignee.firstname} ${assignee.lastname}`}))
    if (props.showUnassigned)
      options = options.concat({
        firstname: intl.formatMessage({id: 'pages.lead.assignee.unassigned', defaultMessage: 'Unassigned'}),
        lastname: '',
        unassigned: true,
        id: 'null'
      })
    if (props.showCreate && !isSalesAgent)
      options = options.concat({
        firstname: intl.formatMessage({id: 'pages.lead.assignee.createAssignee', defaultMessage: 'Add Assignee'}),
        lastname: '',
        create: true,
        id: 'create'
      })

    return options
  }
  return <LeadDropdownFilter placeholder={<F id={"pages.leads.filters.assignee.placeholder"} defaultMessage={"Agent"}/>}
                             optionRender={optionRender}
                             selectProps={merge(props?.selectProps, {
                               style: {
                                 minWidth: 180
                               }
                             })}
                             filterKey={'assignee'}
                             filterOption={handleFilter}
                             options={getOptions()}
                             {...props}
                             onChange={onChange}
  />
}

export default LeadAssigneeDropdownFilters;

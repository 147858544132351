import React, {useState} from 'react';
import {Divider} from 'antd';
import {useDispatch, useSelector} from "umi";
import {closestCenter, DndContext, DragOverlay} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';

import * as PreferencesSelectors from "@/selectors/preferencesSelectors"

import SearchBar from './SearchBar';
import FieldList from './FieldList';
import {F} from "@/utils/intl";
import Property from "@/components/Kanban/VisibilitySettings/Property";
import isEmpty from "lodash/isEmpty";
import {
  INITIAL_PROPERTY_VISIBILITY_SETTINGS,
  ORDERED_LEAD_PROPERTIES
} from "@/components/Kanban/VisibilitySettings/constants";

export default function PropertyVisibilitySettings({hide = false}: {hide?: boolean}) {
  let fields = useSelector(PreferencesSelectors.selectLeadsVisibilitySettings)
  if (isEmpty(fields)) fields = INITIAL_PROPERTY_VISIBILITY_SETTINGS


  const [search, setSearch] = useState('');
  const [activeItem, setActiveItem] = useState(null); // Track the active item being dragged

  const dispatch = useDispatch()


  const updateSettings = (setter) => {
    const newSettings = setter(fields)
    dispatch({
      type: 'preferences/update',
      payload: {
        key: 'leadsVisibilitySettings',
        value: newSettings
      }
    })
  }
  const handleFieldToggle = (field, isVisible) => {
    updateSettings((prev) => {
      if (isVisible) {
        const orderedFields = prev.shown.filter(field => ORDERED_LEAD_PROPERTIES.includes(field))
        const notOrderedFields = prev.shown.filter(field => !ORDERED_LEAD_PROPERTIES.includes(field))
        return {
          hidden: prev.hidden.filter((f) => f !== field),
          shown: [...orderedFields, field, ...notOrderedFields]
        };
      } else {
        return {
          shown: prev.shown.filter((f) => f !== field),
          hidden: [...prev.hidden, field]
        };
      }
    });
  };

  const handleDragStart = (event) => {
    const {active} = event;
    setActiveItem(active.id);  // Set the item being dragged
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
      updateSettings((prev) => ({
        ...prev,
        shown: arrayMove(prev.shown, prev.shown.indexOf(active.id), prev.shown.indexOf(over.id))
      }));
    }
    setActiveItem(null); // Reset active item after drag ends
  };

  const filteredShownFields = fields?.shown?.filter((field) => field.toLowerCase().includes(search.toLowerCase())) || []
  const filteredHiddenFields = fields?.hidden?.filter((field) => field.toLowerCase().includes(search.toLowerCase())) || []

  if (hide) return null
  return (
    <div>
      <SearchBar value={search} onSearch={setSearch}/>
      <Divider><F id={'pages.lead.visibilitySettings.shown'} defaultMessage={'Shown'}/></Divider>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={filteredShownFields} strategy={verticalListSortingStrategy}>
          <FieldList fields={filteredShownFields} onToggle={handleFieldToggle} isVisible/>
        </SortableContext>

        <DragOverlay>
          {activeItem ? <Property field={activeItem} isVisible hasSort={false} isDragging/>
            : null}
        </DragOverlay>
      </DndContext>

      <Divider><F id={'pages.lead.visibilitySettings.hidden'} defaultMessage={'Hidden'}/></Divider>
      <FieldList fields={filteredHiddenFields} onToggle={handleFieldToggle} isVisible={false}/>
    </div>
  );
}

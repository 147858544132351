import pick from "lodash/pick";
import omit from "lodash/omit";

import {selectCurrentJourneyId} from "@/models/journey";
import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {message} from "antd";
import * as LeadSelectors from "@/selectors/leadSelectors"
import * as LeadStateSelectors from "@/selectors/leadStateSelectors"
import {
  createCSVLeadsRequest,
  createLeadRequest,
  editLeadsRequest,
  fetchAssignees,
  fetchLeads,
  fetchSingleLeadDetails,
} from "@/services/api/lead";
import set from "lodash/set";
import get from "lodash/get";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {fetchMeRequest} from "@/services/api/user";
import {extractMagicFieldsFromLeadState, parseSort} from "@/utils/functions";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import {LeadModel} from "@/typings/models/Lead";

const initialState = {
  leadFields: {
    default: [],
    meta: []
  },
  list: {},
  details: {},
  lead: {
    drawer: {
      visible: false
    },
    status: {}
  },
  assigneeList: [],
  leadSourceList: [],
  serviceList: [],
  leadStatusList: [],
  unassignedLeadsList: [],
  addLeadDrawer: null,
  leadDetailsDrawer: null,
  assignmentDrawerVisible: false,
  addLead: {
    drawer: {
      visible: false
    }

  },
  leadSourceDetail: {lead_source: {}}

}
export default {
  namespace: 'leads',

  state: initialState,

  effects: {
    * fetchLeadsByUrl({payload}, {call, put}) {
      const {response, data} = yield call(fetchLeads, {url: payload.url});
      if (payload.then) payload.then()
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeads',
            payload: data,
            options: payload
          })
        }
      })
    },
    * fetch({payload = {}}: { payload: { search?: string, url?: string, order_by?: string } }, {call, put, select}) {
      const leadView = yield select(PreferencesSelectors.selectLeadView)
      const date = yield select(selectDateFilter)
      const filters = yield select(state => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_LIST))
      const filteredObj = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      if (filteredObj.sort) {
        const {sort_by, sort_direction} = parseSort(filteredObj.sort)
        if (sort_by && sort_direction) {
          delete filteredObj.sort
          filteredObj.sort_by = sort_by
          filteredObj.sort_direction = sort_direction
        }
      }
      if (leadView === 'kanban') {
        filteredObj.group_by = 'lead_state'
        delete filteredObj.lead_state
      }
      const query = {...date, ...filteredObj, ...payload};
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchLeads, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeads',
            payload: data,
            options: query
          })
        }
      })
    },
    * fetchSingleLead({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {leadId} = payload
      const {response, data} = yield call(fetchSingleLeadDetails, {params: {leadId}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadDetails',
            payload: data,
          })
        }
      }, 'data')
    },
    * fetchUnassignedLeads({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const query = {assignee: 'null', sort_by: 'firstname', sort_direction: 'desc', ...payload};
      const {response, data} = yield call(fetchLeads, {url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveUnassignedLeads',
            payload: data
          })
        }
      })
    },
    * edit({payload = {}}: { payload: any }, {call, put, select}) {
      const params = pick(payload, ['leadId', 'applyLate'])
      const body = omit(payload, ['leadId', 'applyLate', 'leadOverride'])
      const lead = yield select(state => LeadSelectors.selectLeadData(state, params.leadId))
      if (payload.lead_state_id) {
        const statusSettings = yield select(LeadStateSelectors.selectStates)
        const leadState = statusSettings.find(leadStatus => leadStatus.id === payload.lead_state_id)
        const magicFields = extractMagicFieldsFromLeadState(leadState)
        if (magicFields?.optional || magicFields?.mandatory) {
          yield put({
            type: 'openMagicFieldsEditor',
            payload: {
              magicFields,
              leadId: lead.id
            }
          })
        }
      }
      yield put({
        type: 'editLeadLocally',
        payload
      })
      const {response, data} = yield call(editLeadsRequest, {params, data: body});

      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'editLeadDetails',
            payload: {lead: data}
          })
        }
      }, 'data')
    },
    * fetchAssignees({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchAssignees, {query: {all: 1}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setAssignees',
            payload: {
              assigneeList: data
            }
          })
        }
      })
    },
    * create({payload}, {call, put, select}) {
      const {response, data} = yield call(createLeadRequest, {
        params: {sourceId: payload.sourceId},
        data: [{
          ...payload.data,
          priority: 0,
          meta: []
        }]
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'toggleAddLeadsDrawer'
          })
          yield put({
            type: 'fetch',
          })
          payload.intl && message.success(payload.intl.formatMessage({
            id: "pages.leads.createLeadSuccess",
            defaultMessage: 'Lead created successfully'
          }))
        }
        , onError(error) {
          message.error(error?.message || payload.intl.formatMessage({
            id: "pages.leads.createLeadFailed",
            defaultMessage: 'Creating lead failed'
          }))
        }
      })

    },

    * fetchMe({payload = {}}, {call, put, select}) {
      const {response, data} = yield call(fetchMeRequest);
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'journey/gotJourneys',
            payload: data?.Journeys
          })
        }
      })
    },

    * createCSVLeads({payload}, {call, put, select}) {
      const callback = payload?.cb
      payload = {
        ...payload.allData,
        lead_state_id: payload.allData?.incoming_lead_state_id,
        leads: payload.mappedNestedData,
        sourceId: payload.sourceId
      }
      const {response, data} = yield call(createCSVLeadsRequest, {
        data: {...payload},
        params: {
          sourceId:
          payload?.sourceId
        }
      });
      yield processAPIResponse({response, data}, {

        * onSuccess(data) {
          message.success("Leads imported successfully")
          callback && callback(null, true)

          yield put({
            type: 'fetch',
          })
          yield put({
            type: 'fetchMe',
          })
        }
        , onError(error) {
          callback && callback(error, false)

          message.error(error?.error?.message)

        }
      })


    },


  },

  reducers: {
    saveLeads(state, action) {
      let newList = action.payload
      const isPaged = action?.options?.concat || action?.options?.url
      const leads = state.leads || {}
      // Kanban pagination
      if (action?.options?.group_by || action?.options?.url) {
        if (!isPaged) {
          newList.data.forEach(list => {
            list.data.forEach(lead => {
              leads[lead.id] = lead
            })
          })
          const newGroupedList = newList.data.map(groupedLeads => ({
            ...groupedLeads,
            data: groupedLeads.data.map(lead => lead.id)
          }))

          return {
            ...state,
            leads,
            groupedList: {
              data: newGroupedList
            }
          }
        }
        newList.data.forEach(lead => {
          leads[lead.id] = lead
        })
        return {
          ...state,
          leads,
          groupedList: {
            ...state.groupedList,
            data: state.groupedList.data.map(groupedLead => {
              if (groupedLead.filter_id === action.options.groupId) {
                return {
                  ...groupedLead,
                  ...newList,
                  data: groupedLead.data.concat(newList.data.map(lead => lead.id))
                }
              }
              return groupedLead
            }),
          }
        }

      }
      newList.data.forEach(lead => {
        leads[lead.id] = lead
      })

      // Table pagination
      if (isPaged) {
        newList = {
          ...newList,
          ...state.list,
          ...action.payload,
          data: state.list.data.concat(newList.data.map(lead => lead.id)),
        }
        return {
          ...state,
          leads,
          list: newList
        }
      }

      return {
        ...state,
        leads,
        list: {
          ...newList,
          data: newList.data.map(lead => lead.id)
        },
      };
    },
    saveUnassignedLeads(state, action) {
      return {
        ...state,
        unassignedLeadsList: action.payload,
      };
    },

    saveLeadsData(state, action) {
      const leads = action.payload?.list || []
      const newLeads = {}
      leads.forEach(lead => {
        newLeads[lead.id] = lead
      })

      return {
        ...state,
        leads: {
          ...state.leads,
          ...(newLeads || {})
        }
      };
    },
    saveLeadDetails(state, action) {
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.id]: action.payload
        },
      };
    },
    editLeadLocally(state, action) {
      const {leadId} = action.payload
      if (action.payload.priority) {
        action.payload.priority = {
          value: action.payload.priority
        }
      }
      console.log('action.payload', action.payload)
      const mergeLead = (lead: LeadModel) => {
        const toOverride = {...action.payload, ...(action.payload.leadOverride || {})}
        Object.keys(omit(toOverride, ['leadId'])).forEach(key => {
          set(lead, key, toOverride[key])
        })
        lead.lastEditAt = new Date().toISOString()
        return lead
      }
      const prevLead = state.leads[leadId]
      if (!prevLead) return state

      const newLead = mergeLead(prevLead)
      return {
        ...state,
        leads: {
          ...state.leads,
          [leadId]: {
            ...newLead
          }
        }
      }
    },
    editLeadDetails(state, action) {
      const {lead} = action.payload
      return {
        ...state,
        leads: {
          ...state.leads,
          [lead.id]: lead
        }
      }
    },
    toggleAssigneeDrawer(state, {payload}) {
      return {
        ...state,
        assignmentDrawerVisible: !state.assignmentDrawerVisible
      };
    },
    toggleLeadDetailsDrawer(state, {payload}) {
      const lead = get(payload, 'lead')
      return {
        ...state,
        leadDetailsDrawer: {
          leadId: lead?.id
        }
      };
    },
    setAssignees(state, action) {
      const {
        payload: {assigneeList},
      } = action;

      return {
        ...state,
        assigneeList
      };
    },
    toggleAddLeadsDrawer(state, {payload}) {
      const addLead = {...state.addLead, drawer: {visible: !state.addLead?.drawer?.visible}}
      return {
        ...state,
        addLead
      }
    },
    saveLeadSourceDetail(state, action) {
      return {
        ...state,
        leadSourceDetail: action.payload,
      };
    },
    reset(state){
      return initialState
    },
    openMagicFieldsEditor(state, action) {
      return {
        ...state,
        magicFieldEditor: action.payload
      }
    },
    closeMagicFieldEditor(state, action) {
      return {
        ...state,
        magicFieldEditor: null
      }
    }
  }
};

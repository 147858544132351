import { Form, Row, Col, Select, Button } from 'antd';
import { useIntl, FormattedMessage as F } from 'umi';

const { Option } = Select;

const MappingForm = ({
  form,
  handleMappingSubmit,
  systemFields,
  payloadFields,
  onClose,
  prevStep,
}) => {
  const intl = useIntl();
  return (
    <>
      <Form layout="vertical" form={form} onFinish={handleMappingSubmit}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          {(systemFields?.default || [])?.map((field) => (
            <Col span={12} key={field}>
              <Form.Item
                key={field.key}
                label={field.label}
                name={field.key}
                style={{ marginBottom: 20 }}
                tooltip={field.tooltip}
              >
                <Select
                  mode="multiple"
                  placeholder={`${
                    intl.formatMessage({
                      id: 'pages.common.selectFor',
                      defaultMessage: 'Select a field for',
                    }) + field.label
                  }`}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {payloadFields?.map((field) => (
                    <Option key={field.key} value={field.key}>
                      {field.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
            <F id="pages.common.cancel" />
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={prevStep}>
            <F id="pages.common.previous" />
          </Button>
          <Button type="primary" htmlType="submit">
            <F id="pages.common.submit" />
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default MappingForm;

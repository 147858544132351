export default {
    'tour.common.back': 'Terug',
    'tour.common.close': 'Sluiten',
    'tour.common.last': 'Laatste',
    'tour.common.next': 'Volgende',
    'tour.common.skip': 'Overslaan',
    'tour.leads.rangePicker': 'Selecteer het datumbereik om de gegevens te filteren.',
    'tour.leads.filtersCard': 'Hier kunt u gegevens filteren op agent, filiaal, marketingkanaal of bron.',
    'tour.leads.metricSection': 'U kunt selecteren welke statistieken in de grafiek worden weergegeven en de gegevens groeperen op verschillende categorieën.',
    'tour.leads.groupBySection': 'U kunt de gegevens selecteren en groeperen op verschillende categorieën.',
    'tour.leads.exportBtn': 'Klik hier om de grafiekgegevens naar een CSV-bestand te exporteren.',
    'tour.leads.barChartCard': 'Dit is de staafdiagram die de gefilterde en gegroepeerde statistieken toont volgens uw selectiecriteria.',
  
  }
import React, { useEffect, useState } from 'react';
import ProForm, {
  ProFormSelect,
} from '@ant-design/pro-form';
import { Form } from 'antd';
import { useSelector, useIntl, useDispatch } from 'umi';

import { BranchModel, ServiceModel } from '@/typings/models/Location';

interface LocationDetailsFormProps {
  selectedService: ServiceModel;
  isNew: boolean;
  onChange?: (service: ServiceModel) => void;
  branch: BranchModel;
}

const LocationAgentsForm: React.FC<LocationDetailsFormProps> = ({
  selectedService,
  branch,
  isNew = false,
  onChange,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { agentList } = useSelector(
    ({ location: { userBranches, agentList } }: DefaultRootState) => ({
      userBranches,
      agentList,
    }),
  );
 const options= agentList?.data?.map((agent) => ({
      label: `${agent.firstname} ${agent.lastname}`,
      value: agent.id,
    }))
  

  const handleAddOption = () => {
    dispatch({
      type: 'user/toggleDrawer',
      payload: { branch_id: branch?.id },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      form.setFieldsValue({
        agents: selectedService ? selectedService?.agents?.map((agent) => agent.id) : [],
      });
    }, 100);
  }, [JSON.stringify(selectedService)]);

  function onValueChange(name: string, value: any) {
    if (isNew) return;
    dispatch({
      type: 'location/updateService',
      payload: {
        ...selectedService,
        agents: selectedService?.agents?.map((a) => a.id),

        [name]: value,
      },
    });
  }
  const fetchAgents = () => {
    if (branch?.id)
      dispatch({
        type: 'location/fetchAgents',
        payload: {
          branchId: branch?.id,
        },
      });
  };
  useEffect(() => {
    dispatch({
      type: 'user/getUserBranches',
    });
    if (branch?.id) {
      fetchAgents();
    }
  }, []);
  function onFormChange(changedValues, values) {
    if (!isNew) return;
    onChange && onChange(values);
  }
  return (
    <div>
      <ProForm form={form} submitter={false} onValuesChange={onFormChange}>
        <Form.Item
          name="agents"
          label={intl.formatMessage({
            id: 'pages.locations.agents',
            defaultMessage: 'Agents',
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'pages.locations.agentsRequired',
                defaultMessage: 'Please select at least 1 agent!',
              }),
            },
          ]}
        >
          <ProFormSelect
            mode="multiple"
            fieldProps={{
              onFocus: fetchAgents,
              onChange: (value) => {
                onValueChange('agents', value);
              },
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      padding: 8,
                    }}
                  >
                    <a
                      style={{
                        flex: 'none',
                        padding: '8px',
                        display: 'block',
                        cursor: 'pointer',
                      }}
                      onClick={handleAddOption}
                    >
                      <span>
                        {intl.formatMessage({
                id: 'pages.locations.addNewAgent',
                defaultMessage: 'Add new agent',
              })}
                        </span>
                    </a>
                  </div>
                </>
              ),
            }}
            required
            options={options}
          />
        </Form.Item>
      </ProForm>
    </div>
  );
};

export default LocationAgentsForm;

export const DEALS = 'deals';
export const REVENUE = 'revenue';
export const APPOINTMENT = 'appointment';
export const CONVERSION_RATE = 'conversion_rate';
export const LEADS = 'count';

export const DEFAULT_METRICS = [LEADS, REVENUE, CONVERSION_RATE]
export const ALL_METRICS = [LEADS, REVENUE, CONVERSION_RATE];

export const GROUP_BY_BRANCH = 'branch';
export const GROUP_BY_STATUS = 'lead_state';
export const GROUP_BY_SERVICE = 'service';
export const GROUP_BY_MARKETING_CHANNEL = 'marketing_channel';
export const GROUP_BY_LOCATION = 'location';
export const GROUP_BY_ASSIGNEE = 'assignee';

export const DEFAULT_GROUP = GROUP_BY_BRANCH;

export const ALL_GROUP_BY_OPTIONS = [GROUP_BY_BRANCH, GROUP_BY_MARKETING_CHANNEL, GROUP_BY_ASSIGNEE];


export const similarColorsMap = {
    pink: ['#FFC0CB', '#FFB6C1', '#FF99CC', '#FF85B2', '#DB7093', '#FF69B4', '#FF1493'],
    red: ['#FF7F7F', '#FF6347', '#FF4500', '#FF0000', '#DC143C', '#B22222', '#8B0000'],
    orange: ['#FFB347', '#FFA500', '#FF8C00', '#FF7518', '#FF7F50', '#FF6347', '#FF4500'],
    yellow: ['#FFFACD', '#FAFAD2', '#FFD700', '#FFFF00', '#FFD700', '#FFA500', '#FF8C00'],
    green: ['#ADFF2F', '#98FB98', '#00FA9A', '#00FF00', '#32CD32', '#008000', '#006400'],
    blue: ['#87CEEB', '#6495ED', '#5F9EA0', '#4682B4', '#4169E1', '#0000FF', '#0000CD'],
    purple: ['#EE82EE', '#DA70D6', '#BA55D3', '#9932CC', '#9400D3', '#8A2BE2', '#800080'],
    cyan: ['#E0FFFF', '#AFEEEE', '#00FFFF', '#40E0D0', '#00CED1', '#48D1CC', '#20B2AA'],
    magenta: ['#FFCCFF', '#FF99FF', '#FF66FF', '#FF33FF', '#FF00FF', '#FF00CC', '#FF0099'],
    lime: ['#F0FFF0', '#98FB98', '#00FF00', '#32CD32', '#008000', '#006400', '#228B22'],
  };
  export const colorMap = {
    pink: '#FFC0CB',
    green: '#98FB98',
    orange: '#FFA500',
    red: '#FF4500',
    blue: '#0000FF',
    purple: '#800080',
    yellow: '#FFFF00',
    cyan: '#00FFFF',
    magenta: '#FF00FF',
    lime: '#00FF00',
  };

import React from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {SortAscendingOutlined} from '@ant-design/icons';
import {F} from "@/utils/intl";
import styles from "./styles.less"
import {useDispatch, useSelector} from "umi";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";

const LeadToolbarSorter = () => {
  const dispatch = useDispatch()
  const filters = useSelector((state: DefaultRootState) => PreferencesSelector.selectFilters(state, FILTER_PLACES.LEAD_LIST))
  const leadView = useSelector(PreferencesSelector.selectLeadView)

  const SORT_OPTIONS = [{
    key: '-priority',
    label: <F id={"pages.lead.sort.highestPriority"} defaultMessage={'Highest priority'}/>
  }, {
    key: '+firstname',
    label: <F id={"pages.lead.sort.nameAscending"} defaultMessage={'Name A-Z'}/>
  }, {
    key: '-firstname',
    label: <F id={"pages.lead.sort.nameDescending"} defaultMessage={'Name Z-A'}/>
  }, {
    key: '-created',
    label: <F id={"pages.lead.sort.createdAscending"} defaultMessage={'Created closest to now'}/>
  }, {
    key: '+created',
    label: <F id={"pages.lead.sort.createdDescending"} defaultMessage={'Created farthest from now'}/>
  }, {
    key: '-modified',
    label: <F id={"pages.lead.sort.modifiedAscending"} defaultMessage={'Modified closest to now'}/>
  }, {
    key: '+modified',
    label: <F id={"pages.lead.sort.modifiedDescending"} defaultMessage={'Modified farthest from now'}/>
  }]
  const handleMenuClick = (e) => {
    dispatch({
      type: 'preferences/changeFilters',
      payload: {
        sort: e.key,
        place: FILTER_PLACES.LEAD_LIST
      }
    })
  };

  const menu = (
    <Menu className={styles.sorterMenu} onClick={handleMenuClick} activeKey={filters.sort}>
      {SORT_OPTIONS.map(sortOption => <Menu.Item key={sortOption.key}>
        {sortOption.label}
      </Menu.Item>)}
    </Menu>
  );

  if (leadView === 'calendar') return null
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={<SortAscendingOutlined />}/>
    </Dropdown>
  );
};

export default LeadToolbarSorter;

import React from "react";

import {LeadModel} from "@/typings/models/Lead";

import styles from "./index.less"
import EditableTextField from "@/components/Lead/EditableTextField";

const LeadDealSize = ({lead}: {lead: LeadModel}) => {
  return <EditableTextField lead={lead} property={'order_volume'}>
    {lead.order_volume ?
      <div className={styles.leadDealSize}>{`${lead.order_volume}`.replace(/\d(?=(\d{3})+$)/g, '$&,')}</div>
      : '-'}
  </EditableTextField>
}

export default LeadDealSize

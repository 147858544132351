import React, { useEffect, useState } from 'react';
import { useDispatch, useIntl, useSelector } from 'umi';
import isEmpty from 'lodash/isEmpty';
import { Button, Form, Popconfirm, Row, Segmented } from 'antd';
import ProForm from '@ant-design/pro-form';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import getLocationTypes from '@/constants/locationTypes';
import LocationAgentsForm from './LocationAgentsForm';
import LocationAppointmentForm from './LocationAppointmentForm';
import LocationAvailabilityForm from './LocationAvailabilityForm';
import LocationMetaForm from './LocationMetaForm';
import LocationPeriodsForm from './LocationPeriodsForm';
import DrawerSection from '@/components/Drawer/DrawerSection';
import { ServiceAvailability, ServiceModel } from '@/typings/models/Location';
import styles from './index.less';

const ServiceForm: React.FC = ({ services,branch }) => {
  const [newServiceFormData, setNewServiceFormData] = useState<ServiceModel>({
    service_type: '',
  });
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const intl = useIntl();
  const { selectedService, selectedBranch, settingServiceLoading } = useSelector(
    ({
      location: { changeLoadings, selectedService, selectedBranch },
      loading,
    }: DefaultRootState) => ({
      createLocationLoading: loading.effects['location/createService'],
      availabilityLoading: changeLoadings.Availability,
      selectedService,
      selectedBranch,
      settingServiceLoading: loading.effects['location/setService'],
    }),
  );
const setSelectedService = () => {
  selectedBranch?.services &&
    form.setFieldsValue({
      service_type: selectedBranch?.services[0]?.service_type,
    });
}
useEffect(() => {
  form.resetFields();
  setSelectedService();

  if (!isEmpty(selectedBranch) && selectedBranch?.services) {
    setSelectedService()
    dispatch({
      type: 'location/setService',
      payload: selectedBranch?.services[0],
    });
  } else {
    dispatch({
      type: 'location/setService',
      payload: {},
    });
  }
}, [JSON.stringify(selectedBranch)]);
useEffect(() => {
  setNewServiceFormData({
    ...newServiceFormData,
    branch_id: branch?.id,
  });
}, [branch]);

  function isNewService() {
    return selectedService?.id === undefined;
  }

  function handleAvailabilityChange(availability: ServiceAvailability[]) {
    setNewServiceFormData({
      ...newServiceFormData,
      weekdays: availability.map((a) => a.allow_day_and_time),
    });
  }

  function handleFormChange(locationData) {
    setNewServiceFormData({
      ...newServiceFormData,

      ...locationData,

    });
  }

  function onCreateService() {
    dispatch({
      type: 'location/createService',
      payload: {
        ...newServiceFormData,
      },
    });
  }

  const isServiceCreatedForBranch = (serviceType: string) => {
    return services?.some((service) => service.service_type === serviceType);
  }
  const serviceTypes = getLocationTypes().map((option) => ({
    ...option,
    label: (
      <span>
        {option.label}{' '}
        {isServiceCreatedForBranch(option.value) && <CheckOutlined style={{ color: 'green' }} />}
      </span>
    ),
  }));
  const deleteService = (id: string) => {
    dispatch({
      type: 'location/deleteService',
      payload: {
        serviceId: id,
        cb: ()=>{
          form.resetFields();
          dispatch({
            type: 'location/toggleBranchDrawer',
          });

        },
        intl,
      },
    });
  };
  return (
    <div className={styles.drawerContainer}>
      <ProForm form={form} submitter={false} onValuesChange={handleFormChange}>
        <Form.Item name="service_type">
          <Segmented
            block
            onChange={(value) => {
              setNewServiceFormData({ ...newServiceFormData, service_type: value });
              if (isServiceCreatedForBranch(value)) {
                dispatch({
                  type: 'location/setService',
                  payload: selectedBranch?.services?.filter(
                    (service) => service.service_type === value,
                  )[0],
                });
              } else {
                dispatch({
                  type: 'location/setService',
                  payload: {},
                });
              }
            }}
            options={serviceTypes}
            style={{ marginBottom: 20 }}
          />
        </Form.Item>
        {selectedService?.id && (
          <Row style={{ justifyContent: 'flex-end' }}>
            <Popconfirm
              onConfirm={() =>
                new Promise((resolve) => deleteService(selectedService?.id, resolve))
              }
              placement={'topRight'}
              title={intl.formatMessage({
                id: 'pages.locations.deleteServiceMessage',
                defaultMessage: 'Are you sure to remove this service?',
              })}
            >
              <Button type="link" icon={<DeleteOutlined />}>
                {' '}
                {intl.formatMessage({
                  id: 'pages.locations.deleteService',
                  defaultMessage: 'Delete Service',
                })}
              </Button>
            </Popconfirm>
          </Row>
        )}

        {/* <Spin spinning={availabilityLoading}> */}
        <DrawerSection
          title={intl.formatMessage({
            id: 'pages.locations.availability',
            defaultMessage: 'Open Hours',
          })}
        >
          <LocationAvailabilityForm
            onChange={isNewService() ? handleAvailabilityChange : undefined}
            service={selectedService}
            isNew={isNewService()}
          />
        </DrawerSection>
        <DrawerSection
          title={intl.formatMessage({
            id: 'pages.locations.appointments',
            defaultMessage: 'Appointments',
          })}
        >
          <LocationAppointmentForm
            newServiceFormData={newServiceFormData}
            onChange={isNewService() ? handleFormChange : undefined}
            service={selectedService}
            isNew={isNewService()}
          />
        </DrawerSection>
        {!isNewService() && (
          <>
            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.locations.blockHours',
                defaultMessage: 'Block Hours',
              })}
            >
              <LocationPeriodsForm mode={'block'} service={selectedService} />
            </DrawerSection>
            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.locations.additionalOpenHours',
                defaultMessage: 'Additional Open Hours',
              })}
            >
              <LocationPeriodsForm mode={'open'} service={selectedService} />
            </DrawerSection>
          </>
        )}
        {/* </Spin> */}
        <DrawerSection
          title={intl.formatMessage({
            id: 'pages.locations.agents',
            defaultMessage: 'Agents',
          })}
        >
          <LocationAgentsForm
            isNew={isNewService()}
            onChange={isNewService() ? handleFormChange : undefined}
            selectedService={selectedService}
            branch={branch}
          />
        </DrawerSection>
        {/*branch can have no lead source */}
        {selectedBranch?.lead_source?.id && (
          <LocationMetaForm
            isNew={isNewService()}
            selectedService={selectedService}
            branch={selectedBranch}
            onChange={isNewService() ? handleFormChange : undefined}
          />
        )}
        {isNewService() && (
          <Form.Item>
            <Button type="primary" onClick={onCreateService} htmlType="submit">
              {intl.formatMessage({
                id: 'pages.locations.createService',
                defaultMessage: 'Create Service',
              })}
            </Button>
          </Form.Item>
        )}
      </ProForm>
    </div>
  );
};

export default ServiceForm;

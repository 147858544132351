export default {
  // region At A Glance - Dashboard Page
  'component.atAGlance.totalCustomers': 'Registrierungen',
  'component.atAGlance.totalCustomers.tooltip':
    'Alle Kunden, die den Fragebogen abgeschlossen haben.',
  'component.atAGlance.totalRequests': 'Termine',
  'component.atAGlance.totalRequests.tooltip': 'Alle Kunden, die einen Termin angefragt haben.',
  'component.atAGlance.totalSubscribers': 'Abonnenten',
  'component.atAGlance.sentMails': 'Verschickte Mails',
  'component.atAGlance.totalSubscribers.tooltip':
    'Alle Kunden ohne Termin, die Ihre E-Mailadresse zum Retargeting bestätigt haben.',
  // endregion

  // region Registrations - Dashboard Page
  'component.registrationsChart.day': 'Tag',
  'component.registrationsChart.week': 'Woche',
  'component.registrationsChart.month': 'Monat',
  'component.registrationsChart.year': 'Jahr',
  'component.registrationsChart.subscribers': 'Abonnenten',
  // endregion

  // region ServiceRequest - Dashboard Page
  'component.serviceRequest.cardTitle': 'Letzte Termintermine',
  'component.serviceRequest.cardTooltip':
    'Zeigt aktuelle Termine von Kunden, die einen Termin angefragt haben',
  'component.serviceRequest.showAll': 'Alle anzeigen',
  'component.serviceRequest.scrollMore': 'Nach unten scrollen, um mehr zu laden',
  'component.serviceRequest.title': 'Terminanfrage',
  'component.serviceRequest.drawerDefaultLabel': 'Terminanfrage',
  'component.serviceRequest.noMoreLoad': 'Nichts weiteres zu laden...',
  // endregion

  // region CustomerDrawer
  'component.CustomerDrawer.qualification': 'Qualifizierung',
  'component.CustomerDrawer.status': 'Status',
  'component.CustomerDrawer.notes': 'Notizen',
  'component.CustomerDrawer.profile': 'Profil',
  'component.CustomerDrawer.styles': 'Stil',
  'component.CustomerDrawer.results': 'Ergebnisse',
  'component.CustomerDrawer.unsubscribe': 'Abmelden',
  'component.CustomerDrawer.showProfile': 'Profil anzeigen',

  // endregion

  // region LocationDrawer - Locations Page
  'component.locationDrawer.availability': 'Verfügbarkeit',
  'component.locationDrawer.addOpeningHours': 'Wiederkehrende Öffnungszeiten hinzufügen',
  'component.locationDrawer.currentOpeningHours': 'Aktuelle wiederkehrende Öffnungszeiten',
  'component.locationDrawer.saturday': 'Samstag',
  'component.locationDrawer.sunday': 'Sonntag',
  'component.locationDrawer.monday': 'Montag',
  'component.locationDrawer.tuesday': 'Dienstag',
  'component.locationDrawer.wednesday': 'Mittwoch',
  'component.locationDrawer.thursday': 'Donnerstag',
  'component.locationDrawer.friday': 'Freitag',
  'component.locationDrawer.from': 'Von',
  'component.locationDrawer.to': 'Bis',
  'component.locationDrawer.blockHours': 'Spezielle Zeiten blocken',
  'component.locationDrawer.add': 'Hinzufügen',
  // endregion

  'component.tagSelect.expand': 'Erweitern',
  'component.tagSelect.collapse': 'Minimieren',
  'component.tagSelect.all': 'Alle',

  // region PeriodSelector
  'component.periodSelector.day': 'Tag',
  'component.periodSelector.week': 'Woche',
  'component.periodSelector.month': 'Monat',
  // endregion

  // region Table
  'component.table.downloadCSV': 'CSV herunterladen',
  'component.table.showing': 'Zeige',
  'component.table.of': 'von',
  'component.table.items': 'Einträge',
  'component.table.page': 'Seite',
  // endregion

  // region Account component
  'component.account.profile': 'Profil',
  'component.account.billings': 'Abrechnungen',
  'component.account.language': 'Sprache',
  'component.account.language.select': 'Bitte wählen Sie Ihre Sprache:',
  // endregion

  // region Registration Growth
  'component.requestGrowth.title': 'Termine',
  'component.requestGrowth.hint': 'Anzahl der Kunden mit wenigstens einer Terminanfrage.',

  'component.subscriberGrowth.title': 'Retargeting Kunden',
  'component.subscriberGrowth.hint': 'Anzahl der aktiven Abonnenten für E-Mail Retargeting.',

  'component.registrationGrowth.title': 'Registrierungen',
  'component.registrationGrowth.hint': 'Anzahl der neu registrierten Nutzer mit und ohne Termin.',

  'component.registrationGrowth.change': 'Veränderung',
  'component.registrationGrowth.cumulative': 'Gesamt',

  // endregion

  // region User Menu Dropdown
  'component.userMenu.settings': 'Einstellungen',
  'component.userMenu.billing': 'Abrechnung',
  'component.userMenu.support': 'Support',
  'component.userMenu.signOut': 'Ausloggen',
  // endregion

  // region MApWithAutoComplete

  'component.mapWithAutoComplete.addressPlaceHolder': 'Bitte geben Sie die Adresse ein',
  'component.mapWithAutoComplete.addressError':
    'Adresse nicht gefunden. Nutzen Sie eine gültige Adresse, wenn Sie die Standortsuche verwenden möchten.',
  // endregion

  // region Account
  'component.settings.visibility': 'Sichtbarkeit',
  // endregion

  // region Lead Sources
  'components.leadSourceSwitcher.webhook': 'Webhook',
  'components.leadSourceSwitcher.csvFile': 'CSV Datei',
  'components.leadSourceSwitcher.manual': 'Manuell',
  // endregion

  'component.saveChangeNotification.unsavedViewTitle': 'Ungespeichert Ansicht',
  'component.saveChangeNotification.saveConfirmMessage': 'Du hast diese Ansicht verändert. Möchtest Du die Änderungen für Deinen nächsten Besuch speichern?',
  'component.saveChangeNotification.save': 'Speichern',
  'component.saveChangeNotification.reset': 'Zurücksetzen'

};

import * as React from 'react'
import styles from "./index.less"
import LogoAndTitle from "@/components/LogoAndTitle";
import UserLayoutFooter from "@/components/Footer/UserLayoutFooter";

interface UserFormLayoutProps {
children?: any
}

const UserFormLayout: React.FC<UserFormLayoutProps> = ({children}: UserFormLayoutProps) => {

  return <div className={styles.userFormLayout}>
    <div className={styles.logo}>
      <LogoAndTitle titleClassName={styles.brandTitle}/>
    </div>
    <div>
      {children}
    </div>
    <UserLayoutFooter/>
  </div>
}

export default (UserFormLayout)

import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {GetPerformanceChartDataRequest} from "@/typings/api/getPerformanceChartDataRequest";
import {GetFunnelChartDataRequest} from "@/typings/api/getFunnelChartDataRequest";

export function fetchPerformanceChartData({
                                            url,
                                            query = {}
                                          }: FetchPerformanceChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_PERFORMANCE_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

type FetchPerformanceChartData = {
  url?: string,
  query?: GetPerformanceChartDataRequest["query"]
}
export function fetchFunnelChartData({
                                       url,
                                       query = {}
                                     }: FetchFunnelChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_FUNNEL_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

type FetchFunnelChartData = {
  url?: string,
  query?: GetFunnelChartDataRequest["query"]
}

export function fetchRoiFunnelChartData({
                                          url,
                                          query = {}
                                        }: FetchFunnelChartData) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_LEAD_MANAGEMENT_ROI_FUNNEL_CHART_DATA, {
    queryString: query,
  })
  return request<GetPerformanceChartDataRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

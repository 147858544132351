import React from 'react';
import {Card, CardProps, Popover, Spin, Typography} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import styles from './index.less';
import classNames from "classnames";

interface CardComponentProps extends CardProps {
  title?: string;
  children: React.ReactNode;
  hint?: string | React.ReactNode;
  className?: string;
  loading?: boolean
  mini?: boolean;
}

const CardComponent: React.FC<CardComponentProps> = ({
                                                       title,
                                                       children,
                                                       className,
                                                       hint,
                                                       loading = false,
                                                       mini = false,
                                                       ...props
                                                     }: CardComponentProps) => {
  return (
    <Spin spinning={loading}>
      <section className={classNames(className, styles.Card, {
        [styles.mini]: mini
      })}>
        {title && (
          <div className={styles.Card__header}>
            <Typography.Title level={2} className={styles.Card__header__title}>
              {title}
            </Typography.Title>
            {hint && (
              <Popover content={hint} trigger="click">
                {/* <Button shape="circle" icon={<ExclamationCircleOutlined />} /> */}
                <ExclamationCircleOutlined className={styles.Card__header__hint}/>
              </Popover>
            )}
          </div>
        )}
        <Card {...props} className={styles.Card__content}>{children}</Card>
      </section>
    </Spin>
  );
};

export default CardComponent;

import React from 'react';
import { Row, Spin, Typography } from 'antd';
import { useIntl, FormattedMessage as F } from 'umi';

const PollingSpinner = ({}) => {
  const intl = useIntl();

  return (
    <>
      <Row justify="center" style={{ marginTop: 24 }}>
        <Spin tip={intl.formatMessage({ id: 'pages.leadsources.webhook.checkingForData' })} />
        <Typography.Text style={{ marginLeft: 10 }} type="secondary">
        <F id='pages.leadsources.webhook.polling' />{' '}
        </Typography.Text>
      </Row>
    </>
  );
};

export default PollingSpinner;
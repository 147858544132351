import {useSelector} from "umi";
import {Space} from "antd";
import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CommentOutlined,
  DeliveredProcedureOutlined,
  EnvironmentOutlined,
  EuroOutlined,
  FormOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {INITIAL_PROPERTY_VISIBILITY_SETTINGS, LEAD_PROPERTIES} from "@/components/Kanban/VisibilitySettings/constants";

import {LeadModel} from "@/typings/models/Lead";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {F} from "@/utils/intl";

// region Lead Components
import LeadName from "@/components/Lead/LeadName";
import LeadEmail from "@/components/Lead/LeadEmail";
import LeadSource from "@/components/Lead/LeadSource";
import LeadDealSize from "@/components/Lead/LeadDealSize";
import LeadMarketingChannel from "@/components/Lead/LeadMarketingChannel";
import LeadPhone from "@/components/Lead/LeadPhone";
import LeadAssigneeOverview from "@/components/Lead/LeadAssigneeOverview";
import LeadState from "@/components/Lead/LeadState";
import LeadCreatedAt from "@/components/Lead/LeadCreatedAt";
import LeadUpdatedAt from "@/components/Lead/LeadUpdatedAt";
import LeadAppointmentAt from "@/components/Lead/LeadAppointmentAt";
import LeadPriority from "@/components/Lead/LeadPriority";
import LeadBranch from "@/components/Lead/LeadBranch";
import LeadNote from "@/components/Lead/LeadNote";
// endregion
import styles from "./index.less"

const LEAD_PROPERTY_ICONS = {
  [LEAD_PROPERTIES.NAME]: null,
  [LEAD_PROPERTIES.EMAIL]: <MailOutlined/>,
  [LEAD_PROPERTIES.ASSIGNEE]: <TeamOutlined/>,
  [LEAD_PROPERTIES.COMMENTS]: <CommentOutlined/>,
  [LEAD_PROPERTIES.CREATED_AT]: <CalendarOutlined/>,
  [LEAD_PROPERTIES.UPDATED_AT]: <ClockCircleOutlined/>,
  [LEAD_PROPERTIES.APPOINTMENT_AT]: <CalendarOutlined/>,
  [LEAD_PROPERTIES.DEAL_SIZE]: <EuroOutlined/>,
  [LEAD_PROPERTIES.PHONE]: <PhoneOutlined/>,
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: <GlobalOutlined/>,
  [LEAD_PROPERTIES.BRANCH]: <EnvironmentOutlined/>,
  [LEAD_PROPERTIES.SOURCE]: <DeliveredProcedureOutlined/>,
  [LEAD_PROPERTIES.NOTE]: <FormOutlined/>,
};

const LEAD_PROPERTY_COMPONENTS = {
  [LEAD_PROPERTIES.NAME]: LeadName,
  [LEAD_PROPERTIES.EMAIL]: LeadEmail,
  [LEAD_PROPERTIES.PHONE]: LeadPhone,
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: LeadMarketingChannel,
  [LEAD_PROPERTIES.DEAL_SIZE]: LeadDealSize,
  [LEAD_PROPERTIES.CREATED_AT]: LeadCreatedAt,
  [LEAD_PROPERTIES.UPDATED_AT]: LeadUpdatedAt,
  [LEAD_PROPERTIES.APPOINTMENT_AT]: LeadAppointmentAt,
  [LEAD_PROPERTIES.ASSIGNEE]: LeadAssigneeOverview,
  [LEAD_PROPERTIES.STATUS]: LeadState,
  [LEAD_PROPERTIES.PRIORITY]: LeadPriority,
  [LEAD_PROPERTIES.BRANCH]: LeadBranch,
  [LEAD_PROPERTIES.SOURCE]: LeadSource,
  [LEAD_PROPERTIES.NOTE]: LeadNote,
}

const LEAD_PROPERTY_VALIDATORS = {
  [LEAD_PROPERTIES.NAME]: (lead: LeadModel) => get(lead, 'customer.firstname') || get(lead, 'customer.lastname'),
  [LEAD_PROPERTIES.EMAIL]: (lead: LeadModel) => get(lead, 'customer.email'),
  [LEAD_PROPERTIES.PHONE]: (lead: LeadModel) => get(lead, 'customer.phone'),
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: (lead: LeadModel) => get(lead, 'customer.utm_medium'),
  [LEAD_PROPERTIES.DEAL_SIZE]: (lead: LeadModel) => get(lead, 'order_volume'),
  [LEAD_PROPERTIES.CREATED_AT]: () => true,
  [LEAD_PROPERTIES.UPDATED_AT]: () => true,
  [LEAD_PROPERTIES.APPOINTMENT_AT]: () => true,
  [LEAD_PROPERTIES.ASSIGNEE]: (lead: LeadModel) => !isEmpty(lead?.assignee),
  [LEAD_PROPERTIES.STATUS]: (lead: LeadModel) => !isEmpty(lead?.lead_state),
  [LEAD_PROPERTIES.PRIORITY]: (lead: LeadModel) => !isEmpty(lead?.priority),
  [LEAD_PROPERTIES.BRANCH]: (lead: LeadModel) => !isEmpty(lead?.branch),
  [LEAD_PROPERTIES.SOURCE]: (lead: LeadModel) => !isEmpty(lead?.lead_source),
  [LEAD_PROPERTIES.NOTE]: (lead: LeadModel) => get(lead, 'customer.notes'),
}

export const validateLeadField = (lead, property) => {
  const validator = LEAD_PROPERTY_VALIDATORS[property]
  if (!validator) return true
  return validator(lead)
}

export const LEAD_PROPERTY_COLUMN_SETTINGS = {
  [LEAD_PROPERTIES.NAME]: {
    title: {
      id: 'pages.lead.fields.name',
      defaultMessage: 'Name'
    },
    extraProps: {
      fixed: 'left'
    }
  },
  [LEAD_PROPERTIES.EMAIL]: {
    title: {
      id: 'pages.lead.fields.email',
      defaultMessage: 'Email'
    }
  },
  [LEAD_PROPERTIES.ASSIGNEE]: {
    title: {
      id: 'pages.lead.fields.assignee',
      defaultMessage: 'Assignee'
    },
  },
  [LEAD_PROPERTIES.CREATED_AT]: {
    title: {
      id: 'pages.lead.fields.createdAt',
      defaultMessage: 'Create date'
    }
  },
  [LEAD_PROPERTIES.UPDATED_AT]: {
    title: {
      id: 'pages.lead.fields.updatedAt',
      defaultMessage: 'Update date'
    }
  },
  [LEAD_PROPERTIES.APPOINTMENT_AT]: {
    title: {
      id: 'pages.lead.fields.appointmentAt',
      defaultMessage: 'Appointment date'
    }
  },
  [LEAD_PROPERTIES.DEAL_SIZE]: {
    title: {
      id: 'pages.lead.fields.dealSize',
      defaultMessage: 'Deal size'
    }
  },
  [LEAD_PROPERTIES.LEAD_SOURCE]: {
    title: {
      id: 'pages.lead.fields.leadSource',
      defaultMessage: 'Source'
    }
  },
  [LEAD_PROPERTIES.PHONE]: {
    title: {
      id: 'pages.lead.fields.phone',
      defaultMessage: 'Phone'
    }
  },
  [LEAD_PROPERTIES.MARKETING_CHANNEL]: {
    title: {
      id: 'pages.lead.fields.marketingChannel',
      defaultMessage: 'Channel'
    }
  },
  [LEAD_PROPERTIES.STATUS]: {
    title: {
      id: 'pages.lead.fields.status',
      defaultMessage: 'Status'
    }
  },
  [LEAD_PROPERTIES.PRIORITY]: {
    title: {
      id: 'pages.lead.fields.priority',
      defaultMessage: 'Priority'
    }
  },
  [LEAD_PROPERTIES.BRANCH]: {
    title: {
      id: 'pages.lead.fields.branch',
      defaultMessage: 'Branch'
    }
  }
}

type ILeadPropertyComponentProps = {
  lead: LeadModel,
  property: string,
  isTable?: boolean
}
export const LeadPropertyComponent = ({lead, property, isTable}: ILeadPropertyComponentProps) => {
  if (!isTable && [LEAD_PROPERTIES.STATUS].includes(property)) return null
  const Component = LEAD_PROPERTY_COMPONENTS[property]
  if (!Component) return null

  // @ts-ignore
  return <Component lead={lead} isTable={isTable}/>
}

type IOrderedLeadPropertiesType = {
  lead: LeadModel,
  showAllProperties?: boolean,
  showFieldName?: boolean,
  spaceSize?: number,
  overrideVisibleProperties?: string[]
}
export const LeadCardOrderedProperties = React.memo(({
                                            lead,
                                            showAllProperties,
                                            showFieldName = false,
                                            spaceSize = 8,
                                            overrideVisibleProperties = []
                                          }: Readonly<IOrderedLeadPropertiesType>) => {

  let visibleProperties = useSelector(showAllProperties ?
    PreferencesSelectors.selectAllLeadsProperties :
    PreferencesSelectors.selectShownLeadsProperties
  )
  if (isEmpty(visibleProperties))
    visibleProperties = INITIAL_PROPERTY_VISIBILITY_SETTINGS.shown

  const getVisibleProperties = () => isEmpty(overrideVisibleProperties) ? visibleProperties : overrideVisibleProperties


  return <div className={styles.propertiesContainer} style={{gap: spaceSize}}>
    {getVisibleProperties().map(property => {
      const Presentation = LeadPropertyComponent({lead, property})
      if (!Presentation) return null
      const Icon = LEAD_PROPERTY_ICONS[property]
      const fieldWithIcon = <div key={property} className={styles.propertyRow}>
        {Icon && <div className={styles.propertyIcon}>{Icon}</div>}
        {Presentation && <div className={styles.propertyPresentation}>{Presentation}</div>}
      </div>
      if (!showFieldName) return fieldWithIcon
      return <Space direction={"vertical"} key={`${lead.id}_${property}`}>
        <strong><F id={`pages.lead.fields.${property}`}/></strong>
        {fieldWithIcon}
      </Space>
    }).filter(property => property)}
  </div>
}, (prevProps, nextProps) => {
  return isEqual(prevProps.lead, nextProps.lead) && isEqual(prevProps.overrideVisibleProperties, nextProps.overrideVisibleProperties)
})


import {Segmented, Tooltip} from "antd";
import {F} from "@/utils/intl";
import {BarsOutlined, CalendarOutlined, ProjectOutlined} from "@ant-design/icons";
import React from "react";
import {useDispatch, useSelector} from "umi";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";

export default function LeadViewSwitch(){
  const leadView = useSelector(PreferencesSelector.selectLeadView)
  const dispatch = useDispatch()

  const changeLeadView = (mode) => {
    dispatch({
      type: 'preferences/update',
      payload: {
        key: 'leadListView',
        value: {
          mode
        },
      },
    });
  }
  return <Segmented
    value={leadView}
    onChange={changeLeadView}
    options={[
      {
        value: 'kanban',
        icon: (
          <Tooltip title={<F id={`pages.lead.toolbar.view.kanban`}/>}>
            <ProjectOutlined/>
          </Tooltip>
        ),
      },
      {
        value: 'table',
        icon: (
          <Tooltip title={<F id={`pages.lead.toolbar.view.table`}/>}>
            <BarsOutlined/>
          </Tooltip>
        ),
      },
      {
        value: 'calendar',
        icon: (
          <Tooltip title={<F id={`pages.lead.toolbar.view.calendar`} defaultMessage={'Calendar'}/>}>
            <CalendarOutlined/>
          </Tooltip>
        ),
      },
    ]}
  />
}

export default {
  // region At A Glance - Dashboard Page
  'component.atAGlance.totalCustomers': 'Total des clients',
  'component.atAGlance.totalCustomers.tooltip': 'Tous les clients ayant abouti au résultat.',
  'component.atAGlance.totalRequests': 'Total des RDV',
  'component.atAGlance.totalRequests.tooltip': 'Tous les clients ayant demandé un rendez-vous.',
  'component.atAGlance.totalSubscribers': 'Total des abonnés',
  'component.atAGlance.totalSubscribers.tooltip':
    'Tous les clients sans rendez-vous ayant confirmé leur adresse e-mail pour le retargeting.',
    'component.atAGlance.sentMails': 'Emails envoyés',
  // endregion

  // region Registrations - Dashboard Page
  'component.registrationsChart.day': 'Jour',
  'component.registrationsChart.week': 'Semaine',
  'component.registrationsChart.month': 'Mois',
  'component.registrationsChart.year': 'Année',
  'component.registrationsChart.subscribers': 'Abonnés',
  // endregion

  // region ServiceRequest - Dashboard Page
  'component.serviceRequest.cardTitle': 'Derniers rendez-vous',
  'component.serviceRequest.cardTooltip':
    'Affiche les demandes actuelles des clients qui ont demandé un rendez-vous.',
  'component.serviceRequest.showAll': 'Afficher tout',
  'component.serviceRequest.scrollMore': 'Défiler vers le bas pour charger plus',
  'component.serviceRequest.title': 'Rendez-vous',
  'component.serviceRequest.drawerDefaultLabel': 'Rendez-vous',
  'component.serviceRequest.noMoreLoad': 'Plus rien à charger...',
  // endregion

  // region CustomerDrawer
  'component.CustomerDrawer.qualification': 'Qualifications',
  'component.CustomerDrawer.status': 'Statut',
  'component.CustomerDrawer.notes': 'Notes',
  'component.CustomerDrawer.profile': 'Profile',
  'component.CustomerDrawer.styles': 'Styles',
  'component.CustomerDrawer.results': 'Résultats',
  'component.CustomerDrawer.unsubscribe': 'Désabonner',
  // endregion

  // region LocationDrawer - Locations Page
  'component.locationDrawer.availability': 'Disponibilité',
  'component.locationDrawer.addOpeningHours': "Ajouter des heures d'ouverture récurrentes",
  'component.locationDrawer.currentOpeningHours': "Heures d'ouverture récurrentes actuelles",
  'component.locationDrawer.saturday': 'Samedi',
  'component.locationDrawer.sunday': 'Dimanche',
  'component.locationDrawer.monday': 'Lundi',
  'component.locationDrawer.tuesday': 'Mardi',
  'component.locationDrawer.wednesday': 'Mercredi',
  'component.locationDrawer.thursday': 'Jeudi',
  'component.locationDrawer.friday': 'Vendredi',
  'component.locationDrawer.from': 'De',
  'component.locationDrawer.to': 'À',
  'component.locationDrawer.blockHours': 'Bloquer des heures spéciales',
  'component.locationDrawer.add': 'Ajouter',
  // endregion

  'component.tagSelect.expand': 'Agrandir',
  'component.tagSelect.collapse': 'Réduire',
  'component.tagSelect.all': 'Tout',

  // region PeriodSelector
  'component.periodSelector.day': 'Jour',
  'component.periodSelector.week': 'Semaine',
  'component.periodSelector.month': 'Mois',
  // endregion

  // region Table
  'component.table.downloadCSV': 'Télécharger CSV',
  'component.table.showing': 'Affichant',
  'component.table.of': ' sur ',
  'component.table.items': 'enregistrements',
  'component.table.page': 'Page',

  // endregion

  // region Account component
  'component.account.profile': 'Profile',
  'component.account.billings': 'Factures',
  'component.account.language': 'Langue',
  'component.account.language.select': 'Veuillez choisir votre langue.',
  // endregion

  // region Registration Growth
  'component.registrationGrowth.title': 'Inscriptions',
  'component.registrationGrowth.hint':
    'Nombre de nouveaux utilisateurs inscrits avec et sans rendez-vous',


  'component.requestGrowth.title': 'Rendez-vous',
  'component.requestGrowth.hint': 'Nombre de clients avec au moins un rendez-vous.',

  'component.subscriberGrowth.title': 'Clients retargeting',
  'component.subscriberGrowth.hint': "Nombre actuel d'abonnés aux e-mails de retargeting. ",

  'component.registrationGrowth.change': 'Variation',
  'component.registrationGrowth.cumulative': 'Total',
  // endregion

  // region User Menu Dropdown
  'component.userMenu.settings': 'Paramètres',
  'component.userMenu.billing': 'Facturation',
  'component.userMenu.support': 'Assistance',
  'component.userMenu.signOut': 'Se déconnecter',
  // endregion

  // region MApWithAutoComplete
  'component.mapWithAutoComplete.addressPlaceHolder':'Veuillez entrer l\'adresse',
  'component.mapWithAutoComplete.addressError': "Adresse non localisée. Si vous souhaitez utiliser la recherche de localisation, veuillez utiliser une adresse valide.",
    // endregion

  // region Account
  'component.settings.visibility': 'Visibilité',
   // endregion


  'component.saveChangeNotification.unsavedViewTitle': 'Vue non enregistrée',
  'component.saveChangeNotification.saveConfirmMessage': 'Vous venez de changer cette vue. Désirez-vous sauvegarder vos changements pour votre prochaine visite?',
  'component.saveChangeNotification.save': 'Speichern',
  'component.saveChangeNotification.reset': 'Zurücksetzen'
};

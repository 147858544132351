import { Button, Table, Divider, Form, Row } from 'antd';
import { FormattedMessage as F, useIntl } from 'umi';
import ImportErrorAlert from './ImportErrorAlert';

const PreviewParsedDataTableSegment = ({
  mappedData,
  previewColumns,
  sendDataToServer,
  stepLoading,
  onClose,
  prevStep,
  errorObject,
}) => {
  const intl = useIntl();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 25,
        }}
      >
        <p>
          <F
            id={'pages.leadsources.reviewMappingAlert'}
            defaultMessage={'Please review your mappings before proceeding'}
          />
        </p>

        <Button type="link" style={{ color: '#27AE9D' }} onClick={prevStep}>
          <F
            id={'pages.leadsources.sepratorProblem'}
            defaultMessage={'Is there a problem with csv seperator?'}
          />
        </Button>
      </div>

      <Table
        dataSource={mappedData?.slice(0, 100)} // Show first 100 rows for preview
        columns={previewColumns}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 400 }}
        rowKey={(record, index) => index}
        bordered
      />
      {(mappedData || [])?.length > 100 && (
        <p style={{ marginTop: '10px' }}>Showing first 100 of {mappedData?.length} records.</p>
      )}
      <Divider />
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
          <F id="pages.common.cancel" />
        </Button>

        <Button style={{ margin: '0 8px' }} onClick={prevStep}>
          <F id="pages.common.previous" />
        </Button>

        <Button
          type="primary"
          style={{ margin: '0 8px' }}
          onClick={sendDataToServer}
          loading={stepLoading}
        >
          <F id="pages.leadsources.sendDataToServer" defaultMessage="Send Data to Server" />
        </Button>
      </Row>
    </div>
  );
};

export default PreviewParsedDataTableSegment;

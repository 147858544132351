import * as React from 'react';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Input, Popconfirm } from 'antd';
import { useDispatch, useSelector, useIntl, FormattedMessage as F } from 'umi';

import NewLeadSource from './NewLeadSource';
import { LEAD_SOURCE_TYPE_CSV } from '../constants';
const { Search } = Input;

const CSVLeadSourceImport: React.FC = ({  }) => {
  const [isNewSourceModalVisible, setIsNewSourceModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLeadSourceList, setFilteredLeadSourceList] = useState([]);

  const intl = useIntl();
  const dispatch = useDispatch();

  const { leadSourceList, leadSourceDetail } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
    loading: state.loading.effects['journey/fetchConfig'],
  }));

  React.useEffect(() => {
    dispatch({
      type: 'leadSources/saveLeadSourceDetail',
      payload: {},
    });
  }, []);

  React.useEffect(() => {
    if (searchQuery) {
      const filteredList = leadSourceList.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredLeadSourceList(filteredList);
    } else {
      setFilteredLeadSourceList(leadSourceList);
    }
  }, [searchQuery, leadSourceList]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leadSources/fetchLeadSource',
      payload: { id },
    });
  };
  const deleteLeadSource = (id: string, resolve: Function) => {
    dispatch({
      type: 'leadSources/deleteLeadSource',
      payload: {
        id,
        cb: resolve,
        intl,
      },
    });
  };

  const leadSourceListColumns = [
    {
      title: intl.formatMessage({ id: 'pages.leadsources.name', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'pages.leadsources.actions', defaultMessage: 'Actions' }),
      key: 'action',
      render: (record) => (
        <div>
          <Button
            type="link"
            style={{ color: '#27AE9D' }}
            onClick={() => {
              fetchLeadConfig(record.hashId);
              setIsNew(false);
              setIsNewSourceModalVisible(true);
            }}
          >
            <F id={'pages.leadsources.import'} defaultMessage={'Import'} />
          </Button>
          <Popconfirm
            onConfirm={() =>
              new Promise((resolve, reject) => deleteLeadSource(record.hashId, resolve))
            }
            placement={'topRight'}
            title={
              <F
                id={'pages.leadsources.removeConfirm'}
                defaultMessage={'Are you sure to remove this lead source?'}
              />
            }
          >
            <Button type="link" style={{ color: '#27AE9D' }}>
              <F id={'pages.leadsources.delete'} defaultMessage={'Delete'} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      {isNewSourceModalVisible && (
        <NewLeadSource
          open={isNewSourceModalVisible}
          onClose={() => {
            setIsNewSourceModalVisible(false);
          }}
          config={leadSourceDetail}
          isNew={isNew}
        />
      )}
      {!isNewSourceModalVisible && (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 25,
            }}
          >
            <Search
              placeholder={intl.formatMessage({
                id: 'pages.leadsources.search',
                defaultMessage: 'Search',
              })}
              allowClear
              onSearch={handleSearch}
              style={{ width: 200 }}
            />

            <Button
              type="link"
              style={{ color: '#27AE9D' }}
              icon={<PlusOutlined />}
              onClick={() => {
                setIsNew(true);
                setIsNewSourceModalVisible(true);
              }}
            >
              <F id="pages.leadsources.csv.new" defaultMessage="Add New CSV Source" />
            </Button>
          </div>
          <Table
            columns={leadSourceListColumns}
            pagination={false}
            dataSource={filteredLeadSourceList?.filter(
              (source) => source.type === LEAD_SOURCE_TYPE_CSV,
            )}
          />
        </div>
      )}
    </div>
  );
};

export default CSVLeadSourceImport;

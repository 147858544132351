import React, { useEffect, useState } from 'react';
import { AllowDayAndTime, ServiceAvailability, ServiceModel } from '@/typings/models/Location';
import { Button, Popconfirm, Space } from 'antd';
import { connect, FormattedMessage as F, useDispatch, getLocale } from 'umi';
import styles from './index.less';
import Card from '@/components/Card';
import { CloseCircleOutlined } from '@ant-design/icons';
import WeekDayPicker from '@/components/WeekDayPicker';
import MyTimeRangePicker from '@/components/MyTimePicker';
import { Moment } from 'moment';
import { getWeekDayNameForLocale, transformDayNameToLocale } from '@/utils/date';
import { getLocationAllowDateTimes, getLocationBlockPeriods } from '@/utils/location';

interface LocationAvailabilityFormProps {
  service: ServiceModel;
  isNew?: boolean;
  onChange?: (availability: ServiceAvailability[]) => void;
}

type ILocationAvailabilityList = {
  availability: AllowDayAndTime[];
  onChange: (availability: AllowDayAndTime[]) => void;
};

const LocationAvailabilityList: React.FC<ILocationAvailabilityList> = ({
  availability,
  onChange,
}: ILocationAvailabilityList) => {
  const locale = getLocale();

  function removeRecurringTime(index: number) {
    const newAvailability = [...availability];
    newAvailability.splice(index, 1);
    onChange(newAvailability);
  }

  return (
    <div className={styles.availabilityList}>
      <div className={styles.list}>
        {availability.map((availability, index) => {
          if (!availability) return null;

          return (
            <Card mini className={styles.card} key={availability.day}>
              <div className={styles.inner}>
                <div className={styles.info}>
                  <div className={styles.day}>
                    {transformDayNameToLocale(availability.day, locale)}
                  </div>
                  <div className={styles.hour}>
                    <Space>
                      <F id={'pages.locations.fromHour'} defaultMessage={'From'} />
                      <span>{availability.start}</span>
                      <F id={'pages.locations.toHour'} defaultMessage={'To'} />
                      <span>{availability.end}</span>
                    </Space>
                  </div>
                </div>
                <div className={styles.action}>
                  <Popconfirm
                    onConfirm={() => new Promise((resolve, reject) => removeRecurringTime(index))}
                    placement={'topRight'}
                    title={
                      <F
                        id={'pages.location.removeRecurringTime'}
                        defaultMessage={'Are you sure to remove this recurring time?'}
                      />
                    }
                  >
                    <a>
                      <CloseCircleOutlined />
                    </a>
                  </Popconfirm>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

type IAddLocationAvailability = { onAdd: (availability: AllowDayAndTime) => void };
const AddLocationAvailability: React.FC<IAddLocationAvailability> = ({
  onAdd,
}: IAddLocationAvailability) => {
  const [weekday, setWeekDay] = useState(1);
  const [range, setRange] = useState<Moment[]>([]);

  function onSubmit() {
    const rangeHours = range.map((date) => date.format('HH:mm'));

    // The sent dayName to API should be English so en-US is hardcoded
    const weekDayName = getWeekDayNameForLocale(weekday - 1, 'en-US');
    onAdd({
      day: weekDayName,
      start: rangeHours[0],
      end: rangeHours[1],
    });
    setRange([]);
  }

  function isDisabled() {
    if (!weekday || !range) {
      return true;
    }
    if (!Array.isArray(range)) {
      return true;
    }
    if (!range[0] || !range[1]) {
      return true;
    }

    return false;
  }

  return (
    <div className={styles.addLocationAvailability}>
      <div className={styles.inner} style={{ flexWrap: 'wrap' }}>
        <WeekDayPicker weekDay={weekday} onChange={setWeekDay} />
        <MyTimeRangePicker value={range} onChange={setRange} />
        <Button
          className={'button-secondary'}
          disabled={isDisabled()}
          type={'primary'}
          onClick={onSubmit}
        >
          <F id={'pages.common.add'} defaultMessage={'Add'} />
        </Button>
      </div>
    </div>
  );
};
const LocationAvailabilityForm: React.FC<LocationAvailabilityFormProps> = ({
  service,
  onChange,
}: LocationAvailabilityFormProps) => {
  const [availability, setAvailability] = useState<AllowDayAndTime[]>([]);
  const dispatch = useDispatch();
  function changed(newAvailability: AllowDayAndTime[]) {
    const newAllowDateAndTime: LocationAvailability[] = newAvailability.map((allow) => ({
      allow_day_and_time: allow,
    }));
    const newBlockPeriods: LocationAvailability[] = getLocationBlockPeriods(service).map(
      (blockPeriod) => ({
        block_period: blockPeriod,
      }),
    );

    const Availability: LocationAvailability[] = newAllowDateAndTime.concat(newBlockPeriods);
    // If onChange is provided, there is no need to dispatch through Redux. Currently used for adding a new location
    if (onChange) {
      onChange(Availability);
      setAvailability(getLocationAllowDateTimes({ calendar: { Availability } }));
      return;
    }
    setAvailability(getLocationAllowDateTimes({ calendar: { Availability } }));

    dispatch({
      type: 'location/updateService',
      payload: {
        ...service,
        branch_id:service.branch?.id,
        agents: service?.agents?.map((a) => a.id),

        calendar: {
          ...service.calendar,
          Availability,
        },
      },
    });
  }

  function onAdd(allowDayAndTime: AllowDayAndTime) {
    const newAvailability = [...availability, allowDayAndTime];
    changed(newAvailability);
  }

  useEffect(() => {
    setAvailability(getLocationAllowDateTimes(service));
  }, [JSON.stringify(service)]);

  return (
    <div>
      <AddLocationAvailability onAdd={onAdd} />
      <LocationAvailabilityList onChange={changed} availability={availability} />
    </div>
  );
};

export default LocationAvailabilityForm;

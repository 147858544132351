import React, {useEffect, useState} from 'react';
import {useDispatch, useIntl, useSelector} from 'umi';
import {Button, Divider, Grid, message, Modal, Space} from 'antd';
import isEmpty from "lodash/isEmpty";

import * as ServiceRequestSelectors from "@/selectors/serviceRequestSelectors"
import * as LeadSelectors from "@/selectors/leadSelectors"
import ServiceRequestTimeSelector from "./ServiceRequestTimeSelector";
import dayjs from "dayjs";
import LeadsSelect from "@/components/Filters/LeadsSelect";
import BranchServiceSelect from "@/components/Filters/BranchServiceSelect";
import {bookServiceRequestRequest} from "@/services/api/serviceRequest";
import {setDateAndHour} from "@/utils/date";
import LeadName from "@/components/Lead/LeadName";
import LeadEmail from "@/components/Lead/LeadEmail";
import {F} from "@/utils/intl";

import styles from '../styles.less';

const CreateServiceRequestDrawer: React.FC = () => {

  const [proposedTime, setProposedTime] = useState({})
  const [leadId, setLeadId] = useState("")
  const [branchServiceId, setBranchServiceId] = useState("")
  const [loading, setLoading] = useState(false)

  const selectedLead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))

  const drawer = useSelector(ServiceRequestSelectors.selectServiceRequestDrawer)
  const visible = !isEmpty(drawer)
  const dispatch = useDispatch()

  function toggle() {
    dispatch({
      type: 'serviceRequest/toggleCreateServiceRequestDrawer',
    });
  }


  const handleSubmit = () => {
    setLoading(true)
    bookServiceRequestRequest({
      params: {
        leadId
      },
      data: {
        start: setDateAndHour(proposedTime.date, proposedTime.startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
        end: setDateAndHour(proposedTime.date, proposedTime.endTime).format('YYYY-MM-DDTHH:mm:ssZ'),
        branch_service: branchServiceId
      }
    }).then(({response, data}) => {
      if (data?.error){
        setLoading(false)
        return message.error(data?.error?.message || data?.error?.error)
      }

      if (drawer.then) drawer.then()

      setLoading(false)
      toggle()
      dispatch({
        type: 'serviceRequest/fetchCalendarView'
      })
    })
  }

  useEffect(() => {
    if (visible) {
      if (drawer.start && drawer.end)
        setProposedTime({
          date: dayjs(drawer.start).startOf('day'),
          startTime: dayjs(drawer.start),
          endTime: dayjs(drawer.end),
        })
      if (drawer.lead){
        setLeadId(drawer.lead.id)
      }
    }
  }, [visible]);

  const intl = useIntl();
  const screens = Grid.useBreakpoint()

  const isDisabled = () => !(leadId && !isEmpty(proposedTime) && branchServiceId)

  function getFooter() {
    return (
      <div style={{textAlign: 'right'}}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'pages.leads.close'} defaultMessage={'Close'}/>
          </Button>

          <Button loading={loading} disabled={isDisabled()} size={'large'}
                  onClick={handleSubmit} type={'primary'}>
            <F id={'pages.serviceRequest.create'} defaultMessage={'Create'}/>
          </Button>
        </Space>
      </div>
    );
  }

  const appointmentAssignee = selectedLead?.assignee || drawer?.lead?.assignee

  return (
    <div className={styles.drawerContainer}>
      <Modal
        width={600}
        footer={getFooter()}
        title={intl.formatMessage({
          id: 'pages.serviceRequests.bookAppointment',
          defaultMessage: 'Book appointment',
        })}
        open={visible}
        destroyOnClose
        onCancel={toggle}
      >
        <ServiceRequestTimeSelector value={proposedTime}
                                    onChange={time => setProposedTime(time)}/>
        <Divider/>
        <div className={styles.bookAppointmentFieldContainer}>
          <strong>
            <F id={'pages.leads.branch'} defaultMessage={'Branch'}/> /
            <F id={'pages.leads.Service'} defaultMessage={'Service'}/> :
          </strong>
          <BranchServiceSelect selectProps={{
            width: "100%", style: {
              display: 'block'
            }
            , bordered: false, size: 'large'
          }}
                               filterMode={'select'}
                               onChange={setBranchServiceId}/>
        </div>
        <Divider/>
        <div className={styles.bookAppointmentFieldContainer}>
          <strong><F id={'pages.leads.tasks.taskLead'} defaultMessage={'Lead'}/>:</strong>
          {drawer?.lead ? <Space direction={"vertical"}>
            <LeadName lead={drawer.lead} readonly/>
            <LeadEmail lead={drawer.lead} readonly noLink/>
          </Space> : <LeadsSelect filterMode={'select'}
                                  selectProps={{
                                    width: "100%", style: {
                                      display: 'block'
                                    }
                                    , bordered: false, size: 'large'
                                  }}
                                  onChange={setLeadId}/>}
        </div>

        {!isEmpty(appointmentAssignee) && <>
          <Divider/>
          <div className={styles.bookAppointmentFieldContainer}>
            <strong><F id={'pages.leads.assignee'} defaultMessage={'assignee'}/>:</strong>
            <LeadName lead={{customer: appointmentAssignee}} readonly/>
          </div>
        </>}
      </Modal>
    </div>
  );
};

export default CreateServiceRequestDrawer;

// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/opt/atlassian/pipelines/agent/build/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/.umi-production/plugin-layout/Layout.tsx').default,
    "routes": [
      {
        "path": "/check",
        "hideInMenu": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Root').default,
        "exact": true
      },
      {
        "path": "/mimic",
        "hideInMenu": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Root/Mimic').default,
        "exact": true
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/user/Login').default,
                "exact": true
              },
              {
                "name": "forgotpassword",
                "path": "/user/forgotpassword",
                "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/user/ForgotPassword').default,
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "resetpassword",
        "hideInMenu": true,
        "layout": false,
        "path": "/set-password/:token",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/user/ResetPassword').default,
        "exact": true
      },
      {
        "solution": "leadGeneration",
        "path": "/reports/",
        "name": "reports",
        "journeyDependant": true,
        "icon": "AreaChartOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "routes": [
          {
            "journeyDependant": true,
            "path": "/reports/dashboard",
            "name": "dashboard",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Dashboard').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/reports/market-research",
            "name": "marketing",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Marketing').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/reports/performance",
            "name": "performance",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Performance').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/reports/cta",
            "name": "cta",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/CTA').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "exact": true
          }
        ]
      },
      {
        "solution": "leadGeneration",
        "path": "/:journeyId/reports/",
        "name": "reports",
        "journeyDependant": true,
        "icon": "AreaChartOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "routes": [
          {
            "journeyDependant": true,
            "path": "/:journeyId/reports/dashboard",
            "name": "dashboard",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Dashboard').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "parentKeys": [
              "/reports/dashboard"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/:journeyId/reports/market-research",
            "name": "marketing",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Marketing').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "parentKeys": [
              "/reports/market-research"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/:journeyId/reports/performance",
            "name": "performance",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Performance').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "parentKeys": [
              "/reports/performance"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "journeyDependant": true,
            "path": "/:journeyId/reports/cta",
            "name": "cta",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/CTA').default,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
            "parentKeys": [
              "/reports/cta"
            ],
            "hideInMenu": true,
            "exact": true
          }
        ],
        "parentKeys": [
          "/reports/"
        ],
        "hideInMenu": true
      },
      {
        "name": "Customers",
        "path": "/customers",
        "solution": "leadGeneration",
        "journeyDependant": true,
        "icon": "TeamOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Customers').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "name": "Customers",
        "path": "/:journeyId/customers",
        "solution": "leadGeneration",
        "journeyDependant": true,
        "icon": "TeamOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Customers').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/customers"
        ],
        "hideInMenu": true,
        "exact": true
      },
      {
        "solution": "leadManagement",
        "path": "/lead-management-reports/",
        "name": "reports",
        "icon": "AreaChartOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "routes": [
          {
            "path": "/lead-management-reports/dashboard",
            "name": "dashboard",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/LeadManagementReports/Performance').default,
            "solution": "leadManagement",
            "wrappers": [require('@/wrappers/AccessControlWrapper').default],
            "exact": true
          },
          {
            "path": "/lead-management-reports/funnel",
            "name": "Lead Funnel",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/LeadManagementReports/LeadFunnel').default,
            "solution": "leadManagement",
            "wrappers": [require('@/wrappers/AccessControlWrapper').default],
            "exact": true
          }
        ]
      },
      {
        "name": "Today",
        "solution": "leadManagement",
        "path": "/today",
        "icon": "ClockCircleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/TodaySalesAgentView').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Leads",
        "solution": "leadManagement",
        "path": "/leads",
        "icon": "TeamOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Leads').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Tasks",
        "solution": "leadManagement",
        "path": "/tasks",
        "icon": "ScheduleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Tasks').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Calendar",
        "solution": "leadManagement",
        "path": "/calendar",
        "icon": "CalendarOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Calendar').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Automation",
        "solution": "leadManagement",
        "path": "/email-automation",
        "icon": "RobotOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/EmailAutomation').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Open Proposals",
        "path": "/open-proposals",
        "solution": "leadManagement",
        "icon": "FileTextOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Today').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "disabled": true,
        "exact": true
      },
      {
        "name": "Support",
        "path": "/support",
        "hideInMenu": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Support').default,
        "exact": true
      },
      {
        "name": "Requests",
        "solution": "leadGeneration",
        "icon": "FileDoneOutlined",
        "journeyDependant": true,
        "path": "/service-requests",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/ServiceRequests').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "name": "Requests",
        "solution": "leadGeneration",
        "icon": "FileDoneOutlined",
        "journeyDependant": true,
        "path": "/:journeyId/service-requests",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/ServiceRequests').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/service-requests"
        ],
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "Locations",
        "icon": "EnvironmentOutlined",
        "path": "/locations",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Locations').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "path": "/images",
        "name": "Images",
        "journeyDependant": true,
        "solution": "leadGeneration",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Images').default,
        "icon": "PictureOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "path": "/:journeyId/images",
        "name": "Images",
        "journeyDependant": true,
        "solution": "leadGeneration",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Images').default,
        "icon": "PictureOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/images"
        ],
        "hideInMenu": true,
        "exact": true
      },
      {
        "name": "Tag Statistics",
        "icon": "LineChartOutlined",
        "solution": "leadGeneration",
        "journeyDependant": true,
        "path": "/tag-statistics",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/TagStatistics').default,
        "hideInMenu": true,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "name": "Tag Statistics",
        "icon": "LineChartOutlined",
        "solution": "leadGeneration",
        "journeyDependant": true,
        "path": "/:journeyId/tag-statistics",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/TagStatistics').default,
        "hideInMenu": true,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/tag-statistics"
        ],
        "exact": true
      },
      {
        "path": "/promotions",
        "solution": "leadGeneration",
        "name": "Promotions",
        "journeyDependant": false,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Promotions').default,
        "icon": "NotificationOutlined",
        "exact": true
      },
      {
        "solution": "leadGeneration",
        "path": "/settings",
        "name": "Settings",
        "journeyDependant": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Settings').default,
        "icon": "SettingOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "solution": "leadGeneration",
        "path": "/:journeyId/settings",
        "name": "Settings",
        "journeyDependant": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Settings').default,
        "icon": "SettingOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/settings"
        ],
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/usermanagement",
        "name": "UserManagement",
        "journeyDependant": false,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/UserManagement').default,
        "icon": "UserOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Components",
        "path": "/componnents",
        "journeyDependant": true,
        "hideInMenu": true,
        "icon": "QuestionCircleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Components').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "exact": true
      },
      {
        "name": "Components",
        "path": "/:journeyId/componnents",
        "journeyDependant": true,
        "hideInMenu": true,
        "icon": "QuestionCircleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Components').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default, require('@/wrappers/JourneyWrapper').default],
        "parentKeys": [
          "/componnents"
        ],
        "exact": true
      },
      {
        "name": "Profile",
        "path": "/profile",
        "hideInMenu": true,
        "icon": "QuestionCircleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Profile').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Language",
        "path": "/language",
        "hideInMenu": true,
        "icon": "QuestionCircleOutlined",
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Profile/Language').default,
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "exact": true
      },
      {
        "name": "Billings",
        "path": "/billings/",
        "hideInMenu": true,
        "icon": "CreditCardOutlined",
        "wrappers": [require('@/wrappers/AccessControlWrapper').default],
        "routes": [
          {
            "name": "Billings",
            "path": "/billings/",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Billings/BillingOverview').default,
            "exact": true,
            "hideInMenu": true,
            "icon": "CreditCardOutlined",
            "wrappers": [require('@/wrappers/AccessControlWrapper').default]
          },
          {
            "name": "Billing Details",
            "path": "/billings/:billingId",
            "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Billings/BillingDetails').default,
            "exact": true,
            "hideInMenu": true,
            "wrappers": [require('@/wrappers/AccessControlWrapper').default]
          }
        ]
      },
      {
        "route": "/",
        "hideInMenu": true,
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Root').default,
        "exact": true
      },
      {
        "component": require('/opt/atlassian/pipelines/agent/build/packages/apps/backoffice/src/pages/Root').default,
        "exact": false
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

export default {
  'tour.common.back': 'Back',
  'tour.common.close': 'Close',
  'tour.common.last ': 'Last',
  'tour.common.next': 'Next',
  'tour.common.skip': 'Skip',
  'tour.leads.rangePicker': 'Select the date range to filter the data.',
  'tour.leads.filtersCard':'Here you can filter data by Agent, Branch, Marketing Channel, or Source.',
  'tour.leads.metricSection':'You can select which metrics to show in the chart and group the data by different categories.',
  'tour.leads.groupBySection':'You can select and group the data by different categories.',
  'tour.leads.exportBtn':'Click here to export the chart data to a CSV file.',
    'tour.leads.barChartCard': 'This is the bar chart showing the metrics filtered and grouped by your selections.'

}
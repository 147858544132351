import React from 'react';
import { Table } from 'antd';
import { transformFieldName } from './utils';
import { FormattedMessage as F } from 'umi';

interface ErrorObject {
  [key: string]: {
    [key: string]: string;
  };
}

interface ImportErrorTableProps {
  errorObject: ErrorObject;
  errorCount:number
}

const transformErrorObjectToTableData = (errorObject: ErrorObject) => {
  return Object.keys(errorObject).map((key, index) => ({
    key: index,
    field: transformFieldName(key),
    message: Object.values(errorObject[key]).join(', '),
  }));
};

const ImportErrorTable: React.FC<ImportErrorTableProps> = ({ errorObject }) => {
  const errorData = transformErrorObjectToTableData(errorObject);

  const errorColumns = [
    {
      title:<F id="pages.leadsources.field" defaultMessage='Field' /> ,
      dataIndex: 'field',
      key: 'field',
    },
    {
      title:<F id="pages.leadsources.message" defaultMessage='Message' /> ,
      dataIndex: 'message',
      key: 'message',
    },
  ];

  return (
    <>
      <Table
        columns={errorColumns}
        dataSource={errorData}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 400 }}
      />
    </>
  );
};

export default ImportErrorTable;
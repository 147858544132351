import {Space} from 'antd';
import {MenuUnfoldOutlined} from '@ant-design/icons';
import React, {useEffect} from 'react';
import {useDispatch, useModel} from 'umi';
import styles from './index.less';
import DvaWatcher from '@/components/DvaWatcher';
import {SOLUTION_KEYS} from "@/constants/solutions";
import PropertyVisibilitySettings from "@/components/Kanban/VisibilitySettings/PropertyVisibilitySettings";


const GlobalHeaderRight: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const isLeadManagement = initialState.selectedSolution === SOLUTION_KEYS.LEAD_MANAGEMENT

  const dispatch = useDispatch()

  useEffect(() => {
    if (initialState.collapsed){
      document.getElementById("root")?.classList.add("collapsed")
    }else{
      document.getElementById("root")?.classList.remove("collapsed")
    }
  },[initialState.collapsed])

  if (!initialState || !initialState.settings) {
    return null;
  }
  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }
  return (
    <Space className={className}>
      <DvaWatcher/>
      {isLeadManagement && <PropertyVisibilitySettings hide/>}
      <span onClick={() => setInitialState({
        ...initialState,
        collapsed: !initialState.collapsed
      })}>
        <MenuUnfoldOutlined />
      </span>
    </Space>
  );
};
export default GlobalHeaderRight;

import React from 'react';
import {FormattedMessage as F, useDispatch, useSelector} from 'umi';
import type {ProColumns} from "@ant-design/pro-table";
import {CustomerModel} from "@/typings/models/Customer";
import Table from "@/components/Table";
import createExpandedRowFromColumns from "@/components/Table/createExpandedRowFromColumns";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import * as LeadSelectors from "@/selectors/leadSelectors";
import {LEAD_PROPERTY_COLUMN_SETTINGS, LeadPropertyComponent} from "@/components/Lead/LeadProperties";
import styles from "./styles.less"
import {PAGINATION_MODES} from "@/components/MyPagination";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";

const TRACKING_LABEL = "Customers"
const REDUX_ACTION = 'leads/fetch'

const LeadsTable: React.FC = ({list, request, loading}) => {
  const dispatch = useDispatch()
  const leads = useSelector(LeadSelectors.selectAllLeadsData)
  const visibleProperties = useSelector(PreferencesSelectors.selectShownLeadsProperties) || []
  const allProperties = useSelector(PreferencesSelectors.selectAllLeadsProperties) || []

  const mapPropertyToColumn = property => ({
    title: <F {...LEAD_PROPERTY_COLUMN_SETTINGS[property].title}/>,
    dataIndex: property,
    render: (_, lead) => <LeadPropertyComponent lead={lead} property={property} isTable/>,
    ...(LEAD_PROPERTY_COLUMN_SETTINGS[property]?.extraProps || {}),
  })
  const shownColumns: ProColumns<any>[] = visibleProperties.filter(property => LEAD_PROPERTY_COLUMN_SETTINGS[property]).map(mapPropertyToColumn)
  shownColumns.push({
    title: null,
    fixed: 'right',
    render: (_, lead) => <LeadContextMenu actionsFilter={['archive', 'details']} leadId={lead?.id}/>
  })
  const allColumns: ProColumns<any>[] = allProperties.filter(property => LEAD_PROPERTY_COLUMN_SETTINGS[property]).map(mapPropertyToColumn)
  const getData = () => (list.data || []).map(leadId => leads[leadId])
  return (
          <Table
            columns={shownColumns}
            rowKey={'id'}
            id={"leads-table"}
            label={TRACKING_LABEL}
            total={list.total}
            search={false}
            fullScreen={false}
            isCard={false}
            reload={false}
            dataSource={getData()}
            totalPages={list.total_pages}
            loading={loading}
            request={request}
            xScroll={true}
            rowClassName={styles.leadTableRow}
            scrollHeight={window.innerHeight * 0.7}
            startPageIndex={1}
            expandableRow={createExpandedRowFromColumns<CustomerModel>(allColumns)}
            isRowExpandable={() => true}
            pagination={{
              mode: PAGINATION_MODES.SCROLL,
            }}
          />
  );
};

export default LeadsTable;

import storage from 'conversional-persistent-storage'
import omit from "lodash/omit"
import {getDvaApp} from "umi";

const STATE_KEY = "state"
const BLACKLIST = ["@@dva", "router", "loading", "preferences.initialized", "preferences.persistQueue"]

// Makes sense to persist redux store data in session storage
export function persistGlobalState(state) {
  const copy = Object.create(state)
  for (const [key] of Object.entries(copy)) {
    copy[key]['drawer'] = undefined
  }
  storage.setItem(STATE_KEY, JSON.stringify(omit(copy, BLACKLIST)))
}

export function getGlobalInitialState() {
  const state = storage.getItem(STATE_KEY);
  if (!state) {
    return {}
  }
  const parsedState = JSON.parse(state)
  if (parsedState.serviceRequest) {
    parsedState.serviceRequest.infiniteList = {};
  }
  return parsedState
}

export function resetPersistentReduxStore() {
  storage.removeItem(STATE_KEY);
}

export function resetLocalStore () {
  if (typeof getDvaApp !== 'function') return
  const dva = getDvaApp()
  if (!dva) return
  const resetActions = []
  dva._models.forEach(model => {
    const resetReducers = Object.keys(model.reducers).filter(reducerName => reducerName.split("/")[1] === "reset")
    if (resetReducers.length > 0)
      resetActions.push(...resetReducers)
  })
  resetActions.forEach(action => {
    dva._store.dispatch({type: action})
  })
}

import {useContext, useEffect, useRef, useState} from "react";
import {Form, Input, InputRef, Spin} from "antd";
import {useSelector} from "umi";
import get from "lodash/get";
import isMatch from "lodash/isMatch";

import {EditableContext} from "@/components/Table/EditableContext";
import * as LeadSelectors from "@/selectors/leadSelectors";

import styles from "./index.less"

interface Item {

}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  loading: boolean;
  rowKey: string;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item, values: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                     title,
                                                     editable,
                                                     children,
                                                     dataIndex,
                                                     record,
                                                     rowKey,
                                                     handleSave,
                                                     ...restProps
                                                   }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedValue, setEditedValue] = useState<string>();
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  const updatedLoading = useSelector(state => get(LeadSelectors.selectFieldChangeLoading(state), `${get(record, rowKey)}_${dataIndex}`))

  useEffect(() => {
    // Actual loading comes from Redux store after request is resolved
    setLoading(updatedLoading)
  }, [updatedLoading]);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {
    setEditedValue(undefined)
  }, [JSON.stringify(record)]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // Consider actual value change, so if there was no change, no request is made
      if (!isMatch(record, values)) {
        handleSave(record, values);
        setLoading(true)
      }
      setTimeout(() => {
        toggleEdit();
      })
    } catch (errInfo) {
    }
  };

  let childNode = children;
  if (editable) {
    // Currently only text is supported in this PoC round. We should add dropdown and select support at least for lead status
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input onChange={e => setEditedValue(e.target.value)} size={"small"} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className={styles.editableCellWrapper} style={{ paddingRight: 24 }} onClick={toggleEdit}>
        <Spin spinning={loading || false} size={"small"}>
          {editedValue || children}
        </Spin>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell

import React, {useEffect} from 'react';
import {useDispatch, useIntl, useModel, useSelector} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import {Card} from "antd";
import * as LeadTaskSelectors from "@/selectors/leadTaskSelectors";
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import {SOLUTION_KEYS} from "@/constants/solutions";
import {TasksTable} from "@/pages/Tasks/TasksTable";

const DISPATCH_ACTION = 'leadTasks/fetch'

const Tasks: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const loading = useSelector((state: DefaultRootState) => state.loading.effects[DISPATCH_ACTION])
  const intl = useIntl();

  const dispatch = useDispatch()

  const taskList = useSelector(LeadTaskSelectors.selectLeadTasks)

  useEffect(() => {

    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT
    })

    fetchTableData()
  }, []);
  function fetchTableData() {
      dispatch({
        type: DISPATCH_ACTION,
      });
  }

  return (
    <MyPageContainer extraProps={{topActions: [<LeadTasksOverview/>]}}>
      <Card>
        <TasksTable loading={loading} data={taskList} request={fetchTableData}/>
      </Card>

    </MyPageContainer>
  );
};

export default Tasks

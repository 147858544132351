import { ServiceModel } from '@/typings/models/Location';
import ProForm, { ProFormDigit } from '@ant-design/pro-form';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl, useDispatch } from 'umi';

interface LocationAppointmentFormProps {
  service: ServiceModel;
  isNew?: boolean;
  onChange?: (values) => void;
  newServiceFormData?: ServiceModel;
}

const LocationAppointmentForm: React.FC<LocationAppointmentFormProps> = ({
  isNew,
  onChange,
  service,
  newServiceFormData
}: LocationAppointmentFormProps) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    
    if (service) {
      const serviceAppointmentValues = {
        appointments_duration: service?.calendar?.appointments_duration,
        appointments_until: service?.calendar?.appointments_until,
        appointments_buffer: service?.calendar?.appointments_buffer,
        timeslot_increment: service?.calendar?.timeslot_increment,
      };
      setInitialValues(serviceAppointmentValues);
      form.setFieldsValue(serviceAppointmentValues); // Set form values
    }
  }, [service, form]);

  function onValueChange(name: string, value: any) {
    if (isNew) return;

    dispatch({
      type: 'location/updateService',
      payload: {
        ...service,
        id: service.id,
        branch_id: service?.branch?.id,
        agents: service?.agents?.map((a) => a.id),
        calendar: {
          ...service?.calendar,
          [name]: value,
        },
      },
    });
  }

  if (!initialValues) {
    return null; // Render nothing until initial values are available
  }

  return (
    <ProForm
      form={form}
      submitter={false}
      initialValues={initialValues}
      onValuesChange={(changedValues) => {
        if (isNew) {
          const [name, value] = Object.entries(changedValues)[0];
          // newServiceFormData['calendar'][name] = value;
          const data = {
            ...newServiceFormData,
            calendar: { ...newServiceFormData?.calendar, [name]: value },
          };
          onChange(data);
        } else {
          const [name, value] = Object.entries(changedValues)[0];
          onValueChange(name, value);
        }
      }}
    >
      <ProFormDigit
        name="appointments_duration"
        label={intl.formatMessage({
          id: 'pages.locations.appointment_duration',
          defaultMessage: 'Booking earliest in',
        })}
        tooltip={intl.formatMessage({
          id: 'pages.locations.appointment_duration_tooltip',
          defaultMessage: 'Time in hours until the first appointment can be booked',
        })}
        placeholder={intl.formatMessage({
          id: 'pages.locations.appointment_duration_placeholder',
          defaultMessage: '48 hours',
        })}
        fieldProps={{
          addonAfter: intl.formatMessage({
            id: 'pages.locations.hours',
            defaultMessage: 'hours',
          }),
        }}
        min={1}
      />
      <ProFormDigit
        name="appointments_until"
        label={intl.formatMessage({
          id: 'pages.locations.appointment_available_to',
          defaultMessage: 'Appointments available until',
        })}
        tooltip={intl.formatMessage({
          id: 'pages.locations.appointment_available_to_tooltip',
          defaultMessage: 'Maximum number of days an appointment can be booked in advance',
        })}
        placeholder={intl.formatMessage({
          id: 'pages.locations.appointment_available_to_placeholder',
          defaultMessage: '90 days',
        })}
        min={3}
        fieldProps={{
          
          addonAfter: intl.formatMessage({
            id: 'pages.locations.days',
            defaultMessage: 'days',
          }),
        }}
      />
      <ProFormDigit
        name="timeslot_increment"
        label={intl.formatMessage({
          id: 'pages.locations.timeslot_increment',
          defaultMessage: 'Timeslot increments',
        })}
        tooltip={intl.formatMessage({
          id: 'pages.locations.timeslot_increment_tooltip',
          defaultMessage: 'Frequency at which free timeslots are suggested in minutes',
        })}
        placeholder={intl.formatMessage({
          id: 'pages.locations.timeslot_increment_placeholder',
          defaultMessage: '60 minutes',
        })}
        fieldProps={{
          addonAfter: intl.formatMessage({
            id: 'pages.locations.minutes',
            defaultMessage: 'minutes',
          }),
        }}
        min={0}
      />
      <ProFormDigit
        name="appointments_buffer"
        label={intl.formatMessage({
          id: 'pages.locations.appointment_buffer',
          defaultMessage: 'Buffer between appointments in minutes',
        })}
        tooltip={intl.formatMessage({
          id: 'pages.locations.appointment_buffer_tooltip',
          defaultMessage: 'Time kept free between appointments in minutes',
        })}
        placeholder={intl.formatMessage({
          id: 'pages.locations.appointment_buffer_placeholder',
          defaultMessage: '0 minutes',
        })}
        fieldProps={{
          addonAfter: intl.formatMessage({
            id: 'pages.locations.minutes',
            defaultMessage: 'minutes',
          }),
        }}
      />
    </ProForm>
  );
};

export default LocationAppointmentForm;

import {processAPIResponse} from "@/utils/request";
import {createLeadTaskRequest, fetchLeadTasksList} from "@/services/api/leadTask";

const initialState = {
  list: []
}
export default {
  namespace: 'leadTasks',

  state: initialState,

  effects: {
    * fetch({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchLeadTasksList, {});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'save',
            payload: data
          })
        }
      }, 'data')
    },
    * create({payload = {}}: { payload: { data?: any, then?: () => void } }, {call, put, select}) {
      const {response, data} = yield call(createLeadTaskRequest, {data: payload.data});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          payload.then && payload.then()
        }
      }, 'data')
    },

  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    reset(){
      return initialState
    }
  }
};

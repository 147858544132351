import React, {useEffect, useState} from 'react';
import {Card, Segmented, Spin, Tooltip} from 'antd';
import {FormattedMessage as F, selectDateFilter, useDispatch, useIntl, useSelector} from 'umi';
import {DownloadOutlined} from '@ant-design/icons';
import FunnelGraph from 'funnel-graph-js';
import 'funnel-graph-js/dist/css/main.min.css';
import 'funnel-graph-js/dist/css/theme.min.css';

import MyPageContainer from "@/components/MyPageContainer";
import MyRadioGroup from '@/components/MyRadioGroup';
import {convertBreakdownApiResponseToFunnelData, exportToCSV} from '../utils';
import {
  BREAK_DOWN_BY_LOCATION,
  BREAK_DOWN_BY_MARKETING_CHANNEL,
  DISPLAY_NUMBERS_WITH_COUNT,
  DISPLAY_NUMBERS_WITH_PERCENT
} from './constants';
import styles from "./index.less"
import FunnelDataTable from './FunnelDataTable';
import  isEmpty from 'lodash/isEmpty';
import * as LeadAnalyticsSelectors from "@/selectors/leadAnalyticsSelectors";


const TRACKING_LABEL = "Dashboard";
const REDUX_ACTION = 'leadsAnalytics/fetchFunnelChartData'

const LeadFunnel: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedBreakdownData, setSelectedBreakdownData] = useState("");
  const [displayType, setDisplayType] = useState('count');
  const {funnelReportData, loading, date} = useSelector((state: DefaultRootState) => ({
    funnelReportData: LeadAnalyticsSelectors.selectFunnelChartData(state),
    loading: state.loading.effects[REDUX_ACTION],
    date: selectDateFilter(state),
  })); 

  const CHART_DISPLAY_UNIT_OPTIONS = [
    {
      title: intl.formatMessage({ id: 'pages.leads.count', defaultMessage: 'Count' }),
      value: DISPLAY_NUMBERS_WITH_COUNT,
    },
    {
      title: intl.formatMessage({ id: 'pages.leads.percentage', defaultMessage: 'Percentage' }),
      value: DISPLAY_NUMBERS_WITH_PERCENT,
    },
  ];


  const getData = () => {
  if (typeof funnelReportData !== 'object' || funnelReportData === null || isEmpty(funnelReportData)) return;

    switch (selectedBreakdownData) {
      case BREAK_DOWN_BY_LOCATION:
      case BREAK_DOWN_BY_MARKETING_CHANNEL:
        return convertBreakdownApiResponseToFunnelData(funnelReportData,true, displayType === DISPLAY_NUMBERS_WITH_PERCENT);
      default:
        return convertBreakdownApiResponseToFunnelData(funnelReportData, false, displayType === DISPLAY_NUMBERS_WITH_PERCENT);
    }
  };

  const getClassName = () => {
    if (selectedBreakdownData && displayType === DISPLAY_NUMBERS_WITH_PERCENT)
      return styles.percentageModeChartStyle;
    if (!selectedBreakdownData && displayType !== DISPLAY_NUMBERS_WITH_PERCENT) {
      return styles.countModeNoBreakDownChartStyle;
    }
    if (!selectedBreakdownData && displayType === DISPLAY_NUMBERS_WITH_PERCENT) {
      return styles.percentageModeNoBreakDownChartStyle;
    }
    return styles.chartStyle;
  };
  const fetchData = () => {
    dispatch({
      type: REDUX_ACTION,
      payload: { breakdownBy: selectedBreakdownData },
    });
  };
  useEffect(() => {
    fetchData();
  }, [selectedBreakdownData]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(date)]);

  useEffect(() => {
    if (
      typeof funnelReportData !== 'object' ||
      funnelReportData === null ||
      isEmpty(funnelReportData)
    )
      return;


    const graphData = getData();

    // Clear previous graph if exists
    const container = document.querySelector('.funnel');
    if (container) {
      container.innerHTML = '';
    }

    const graph = new FunnelGraph({
      container: '.funnel',
      gradientDirection: 'horizontal',
      data: graphData,
      displayPercent: displayType === DISPLAY_NUMBERS_WITH_PERCENT,
      subLabelValue: displayType === 'percentage' ? 'percent' : 'values',
    });

    graph.draw();
  }, [JSON.stringify(funnelReportData), displayType]);
  const BREAK_DOWN_TYPES = [
    { label:intl.formatMessage({ id: 'pages.leads.no_breakdown', defaultMessage:  'No Breakdown' }), value: "" },
    { label: intl.formatMessage({ id: 'pages.leads.location_breakdown', defaultMessage: 'Location Breakdown' }), value: BREAK_DOWN_BY_LOCATION },
    { label:intl.formatMessage({ id: 'pages.leads.marketing_channel_breakdown', defaultMessage:  'Marketing Channel Breakdown' }), value: BREAK_DOWN_BY_MARKETING_CHANNEL },
  ];


  const buildExportData = () => {
  if (typeof funnelReportData !== 'object' || funnelReportData === null || isEmpty(funnelReportData)) return;

    if (!selectedBreakdownData) {
      const headers = Object.keys(funnelReportData);
      const rows = [Object.values(funnelReportData)?.map((item: any) => item)];
      return { headers, rows };
    } else {
      const subLabels = Object.keys(funnelReportData[Object.keys(funnelReportData)[0]]);
      const headers = ['Step', ...subLabels];
      const rows = [];
      Object.keys(funnelReportData)?.forEach((step: any) => {
        const row = [step];
        Object.values(funnelReportData[step]).forEach((subValue: any) => {
          row.push(subValue);
        });
        rows.push(row);
      });
      return {headers, rows};
    }
  };
  const handleExport = () => {
    const exportData = buildExportData();
    exportToCSV(
      exportData.headers,
      funnelReportData,
      `funnel-data-${date.start_date}_${date.end_date}.csv`,
      exportData.rows,
    );
  };
  return (
    <MyPageContainer
      label={TRACKING_LABEL}
      extraProps={{
        hasRangePicker: true,
      }}
    >
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Tooltip title={<F id={'pages.leads.CSVExport'} defaultMessage="Export to CSV"/>}>
          <DownloadOutlined
            onClick={handleExport}
            style={{fontSize: '24px', cursor: 'pointer', margin: '15px', alignSelf: 'end'}}
          />
        </Tooltip>
        <Card style={{marginBottom: 15}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Segmented
              size={'small'}
              value={selectedBreakdownData}
              onChange={setSelectedBreakdownData}
              options={BREAK_DOWN_TYPES}
            />
            <div style={{marginTop: -25}}>
              <MyRadioGroup
                onChange={(e) => setDisplayType(e.target.value)}
                value={displayType}
                values={CHART_DISPLAY_UNIT_OPTIONS}
              />
            </div>
          </div>
          <div
            className={getClassName() }
            style={{ position: 'relative', width: '100%', height: '400px', marginTop: 25 }}
          >
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 1,
                }}
              >
                <Spin size="large"/>
              </div>
            )}
            <div className="funnel" style={{width: '100%', height: '100%'}}></div>
          </div>
        </Card>
        <FunnelDataTable selectedBreakdownData={selectedBreakdownData} data={funnelReportData} loading={loading} />
      </div>
    </MyPageContainer>
  );
};

export default LeadFunnel;

import React, {useCallback, useEffect, useState} from 'react';
import {Button, Collapse, Grid, Input, Space, Tooltip} from 'antd';
import {FilterOutlined, FireOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "umi";
import debounce from "lodash/debounce";

import LeadSourceDropdownFilters from "@/components/Filters/LeadSourceDropdownFilters";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import LeadAssigneeDropdownFilters from "@/components/Filters/LeadAssigneeDropdownFilters";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import LeadToolbarItem from "@/pages/Leads/LeadToolbarItem";
import {F} from "@/utils/intl";

import LeadViewSettings from "@/components/Kanban/Settings";
import LeadToolbarSorter from "@/components/Kanban/LeadToolbarSorter";
import LeadStateFilters from "@/components/Filters/LeadStateFilters";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";

import styles from "./index.less"


type ILeadToolbarProps = {
  request: () => void,
  loading: boolean
}
const LeadToolbar = ({request, loading}: ILeadToolbarProps) => {
  const screens = Grid.useBreakpoint()
  const leadView = useSelector(PreferencesSelector.selectLeadView)
  const dispatch = useDispatch()
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [searchQuery, setSearchQuery] = useState()

  const leadListFilters = useSelector((state: DefaultRootState) => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_LIST))
  const isHeatmap = useSelector(PreferencesSelector.selectIsHeatMap)
  const onHeatMapViewChange = () => {
    dispatch({
      type: 'preferences/update',
      payload: {
        key: 'isHeatmapList',
        value: {
          isHeatmapList: !isHeatmap,
        },
      },
    });
    dispatch({
      type: 'preferences/changeFilters',
      payload: {
        sort: '+modified',
        place: FILTER_PLACES.LEAD_LIST
      }
    })
    // Additional logic to apply or remove heatmap styles from the Kanban board
  };
  // Create a debounced function for the search
  const handleSearch = useCallback(
    debounce((search) => {
      dispatch({
        type: 'preferences/changeFilters',
        payload: {
          search,
          place: FILTER_PLACES.LEAD_LIST
        }
      })
    }, 500),
    []
  );

  const onSearchChanged = (e) => {
    const {value} = e.target;
    setSearchQuery(value);
    handleSearch(value); // This will only call the debounced version of handleSearch
  };

  useEffect(() => {
      if (!searchQuery) setSearchQuery(leadListFilters?.search)
    },
    [leadListFilters?.search])
  const toggleFilterView = () => {
    setShowMobileFilters(prev => !prev)
  }
  const reload = () => {
    request()
  }
  const search = <Input
    placeholder="Search"
    prefix={<SearchOutlined/>}
    onChange={onSearchChanged}
    value={searchQuery}
    className={styles.searchInput}
  />

  const kanbanView = leadView === 'kanban'
  const filters = <Space className={styles.leftFilters} wrap>
    {search}
    <LeadAssigneeDropdownFilters showUnassigned/>
    <BranchDropdownFilters showUnlinked/>
    {/* TODO: Out of scope of 6 weeks due to backend implementation*/}
    {/*<LeadMarketingChannelDropdownFilters/>*/}
    {/*<LeadPriorityDropdownFilters/>*/}
    <LeadSourceDropdownFilters/>
    {!kanbanView && <LeadStateFilters/>}
  </Space>


  return (
    <div className={styles.leadToolbar}>
      {screens.md && filters}
      <Space className={styles.rightOptions} wrap>
        {!screens.md && (
          <LeadToolbarItem
            buttonType={showMobileFilters ? 'primary' : ''}
            icon={<FilterOutlined/>}
            tooltip={<F id={'pages.lead.toolbar.filters'}/>}
            onClick={toggleFilterView}
          />
        )}
        {kanbanView && (
          <Tooltip title={isHeatmap ? <F id={'pages.lead.toolbar.notHeatMapView'} defaultMessage={'Regular view'}/> : <F id={'pages.lead.toolbar.heatMapView'} defaultMessage={'Heatmap view'}/>}>
            <Button
              icon={<FireOutlined style={{color: isHeatmap ? 'black' : 'black'}}/>}
              onClick={onHeatMapViewChange}
              style={{
                backgroundColor: isHeatmap ? 'orange' : 'transparent',
              }}
            />
          </Tooltip>
        )}
        <LeadToolbarItem
          icon={<ReloadOutlined/>}
          tooltip={<F id={'pages.lead.toolbar.reload'}/>}
          onClick={reload}
          buttonProps={{loading}}
        />
        <LeadToolbarSorter/>
        <LeadViewSettings/>
      </Space>
      {!screens.md && (
        <Collapse className={styles.mobileLeadFilters} activeKey={showMobileFilters ? '1' : '0'}>
          <Collapse.Panel key={'1'} header={null}>
            {filters}
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};

export default LeadToolbar;

import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Modal, Row, Select, Switch, Tag} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useDispatch, useIntl, useSelector} from 'umi';
import * as AutomationSelectors from "@/selectors/automationSelector";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import isEmpty from 'lodash/isEmpty';
import LeadStateFilters from "@/components/Filters/LeadStateFilters";

const { Option, OptGroup } = Select;
const REDUX_ACTION = 'automation/fetchEmailAutomationVariableList'

const NewAutomation = ({
  isModalVisible,
  handleOk,
  handleCancel,
  form,
  editorContent,
  setEditorContent,
  automation,
}) => {
  const intl = useIntl();
  const quillRef = useRef(null);
  const dispatch = useDispatch();
  const [autoType, setAutoType] = useState('CHANGE_LEAD_STATE');
  const { automationVariables, loading, users, emailAutomationTypes } = useSelector(
    (state: DefaultRootState) => ({
      automationVariables: AutomationSelectors.selectEmailAutomationVariableList(state),
      loading: state.loading.effects[REDUX_ACTION],
      users: state.user?.list?.data,
      emailAutomationTypes: PreferencesSelectors.selectEmailAutomationTypesList(state),
    }),
  );
  useEffect(() => {
    dispatch({
      type: 'user/getAllUsers',
      payload: {},
    });
    dispatch({
      type: REDUX_ACTION,
    });
  }, [dispatch]);
  useEffect(() => {
    if (!isEmpty(automation)) {
      form.setFieldsValue({
        name: automation.name,
        automation_type: automation.automation_type,
        // receivers: automation.receivers,
        from_state_id: automation.from_state?.id,
        to_state_id: automation.to_state?.id,
        subject: automation.subject,
        extra_message: automation.extra_message,
        is_active: automation.is_active,
        id: automation.id,
      });
      let receivers = automation.receivers;
      if(automation?.send_to_lead_managers){
        receivers = [...receivers, 'send_to_lead_managers'];
      }
      if(automation?.send_to_assignee){
        receivers = [...receivers, 'send_to_assignee'];
      }
      form.setFieldsValue({
         receivers: receivers,
      });
      setEditorContent(automation.extra_message || '');
    } else {
      form.setFieldsValue({
        automation_type: 'CHANGE_LEAD_STATE',
        active: true,
      });
      form.resetFields();
      setEditorContent('');
    }
  }, [JSON.stringify(automation), form, setEditorContent]);

  const handleTagClick = (key, variable) => {
    const quillInstance = quillRef.current.getEditor();
    const range = quillInstance.getSelection(true);
    const cursorPosition = range ? range.index : 0;
    const variableText = `{{${key}.${variable}}}`;

    quillInstance.insertText(cursorPosition, variableText);
    quillInstance.formatText(cursorPosition, variableText.length, {
      color: '#008080',
      background: '#f0f0f0',
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'pages.emailAutomation.newEmailAutomation',
        defaultMessage: 'Automation',
      })}
      open={isModalVisible}
      onOk={() => {
        handleOk(automation);
      }}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        initialValues={{
          automation_type: 'CHANGE_LEAD_STATE',
          is_active: true,
        }}
        layout="vertical"
        name="email_automation_form"
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({ id: 'pages.emailAutomation.title', defaultMessage: 'Name' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'pages.emailAutomation.titleRequired',
                defaultMessage: 'Please enter a title!',
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="receivers"
          label={intl.formatMessage({ id: 'pages.emailAutomation.to', defaultMessage: 'To' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'pages.emailAutomation.toRequired',
                defaultMessage: 'Please enter the recipient email!',
              }),
            },
          ]}
        >
          <Select
            onChange={(value) => {
              if (value.length > 0) {
                form.setFieldsValue({ to: [value[value.length - 1]] });
              }
            }}
            mode="tags"
            showSearch
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({
              id: 'pages.emailAutomation.selectPerson',
              defaultMessage: 'Select a person',
            })}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children
                ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
          >
            <OptGroup
              label={intl.formatMessage({
                id: 'pages.emailAutomation.lead',
                defaultMessage: 'Lead',
              })}
            >
              <Option value={'send_to_lead_managers'}>
                {intl.formatMessage({
                  id: 'pages.emailAutomation.locationManager',
                  defaultMessage: 'Location Manager',
                })}
              </Option>
              <Option value={'send_to_assignee'}>
                {intl.formatMessage({
                  id: 'pages.emailAutomation.assignee',
                  defaultMessage: 'Assignee',
                })}
              </Option>
            </OptGroup>
            <OptGroup
              label={intl.formatMessage({
                id: 'pages.emailAutomation.otherUsers',
                defaultMessage: 'Other users',
              })}
            >
              {Array.isArray(users) &&
                users?.map((user) => (
                  <Option value={user.email}>
                    {user.firstname} {user.lastname}
                  </Option>
                ))}
            </OptGroup>
          </Select>
        </Form.Item>

        <Form.Item
          name="automation_type"
          label={intl.formatMessage({ id: 'pages.emailAutomation.type', defaultMessage: 'Type' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'pages.emailAutomation.typeRequired',
                defaultMessage: 'Please enter the type!',
              }),
            },
          ]}
        >
          <Select
            defaultValue={'CHANGE_LEAD_STATE'}
            onChange={(value) => {
              setAutoType(value);
            }}
            options={emailAutomationTypes?.map((type) => ({
              value: type.key,
              label: type.label,
            }))}
          />
        </Form.Item>
        {autoType === 'CHANGE_LEAD_STATE' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="from_state_id"
                label={intl.formatMessage({
                  id: 'pages.emailAutomation.fromState',
                  defaultMessage: 'From State',
                })}
                rules={[
                  {
                    message: intl.formatMessage({
                      id: 'pages.emailAutomation.fromStateRequired',
                      defaultMessage: 'Please input the from state!',
                    }),
                  },
                ]}
              >
                <LeadStateFilters filterMode={'select'} selectProps={{ allowClear: true }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="to_state_id"
                label={intl.formatMessage({
                  id: 'pages.emailAutomation.toState',
                  defaultMessage: 'To State',
                })}
              >
                <LeadStateFilters filterMode={'select'} selectProps={{ allowClear: true }} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          name="subject"
          label={intl.formatMessage({
            id: 'pages.emailAutomation.emailSubject',
            defaultMessage: 'Email Subject',
          })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'pages.emailAutomation.variables',
            defaultMessage: 'Variables',
          })}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {Object.keys(automationVariables)?.map(
              (key, index) =>
                !isEmpty(automationVariables[key]) && (
                  <div key={key}>
                    {automationVariables[key]?.map((item) => (
                      <Tag
                        key={item.variable}
                        color={['red', 'green', 'blue', 'orange', 'amber', 'teal'][index % 3]}
                        onClick={() => handleTagClick(key, item.variable)}
                      >
                        {item.label ?? item.variable}
                      </Tag>
                    ))}
                  </div>
                ),
            )}
          </div>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'pages.emailAutomation.emailContent',
            defaultMessage: 'Email Content',
          })}
          name="extra_message"
        >
          <ReactQuill ref={quillRef} value={editorContent} onChange={setEditorContent} />
        </Form.Item>

        <Form.Item
          initialValue={automation?.is_active ?? true}
          name="is_active"
          label={intl.formatMessage({
            id: 'pages.emailAutomation.active',
            defaultMessage: 'Active',
          })}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewAutomation;

import { useEffect, useRef, useState } from 'react';
import { Typography, Form, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch, useIntl } from 'umi';

import * as LeadSelectors from '@/selectors/leadSelectors';
import { buildMappings, transformObjectToPayloadFields } from './utils';
import WebhookAlert from './WebhookAlert';
import PollingSpinner from './PollingSpinner';
import MappingForm from './MappingForm';

const { Text } = Typography;

const MappingConfigurationStep = ({ isNew, config, handleMappingSubmit, onClose, prevStep }) => {
  const [payloadFields, setPayloadFields] = useState([]);
  const [isPolling, setIsPolling] = useState(false);

  useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { systemFields, leadSourceDetail, updating } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
    updating: state.loading.effects['leadSources/updateWebhookLeadSource'],
  }));
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const intervalIdRef = useRef([]);

  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leadSources/fetchLeadSource',
      payload: { id },
    });
  };

  useEffect(() => {
    return () => {
      intervalIdRef.current.forEach(clearInterval);
    };
  }, []);

  useEffect(() => {
    if (
      leadSourceDetail?.id &&
      isEmpty(leadSourceDetail?.configuration?.last_payload && !updating) &&
      isEmpty(leadSourceDetail?.configuration?.mappings)
    ) {
      startPolling(leadSourceDetail?.id);
    }
  }, [JSON.stringify(leadSourceDetail)]);

  const startPolling = (id) => {
    setIsPolling(true);
    const intervalId = setInterval(() => {
      fetchLeadConfig(id);
    }, 10000);
    intervalIdRef.current.push(intervalId);
  };

  useEffect(() => {
    if (!isEmpty(leadSourceDetail?.configuration?.last_payload)) {
      intervalIdRef.current.forEach(clearInterval);
      intervalIdRef.current = [];
      setIsPolling(false);
      message.success(intl.formatMessage({ id: 'pages.leadsources.webhook.successfulMapping' }));
    }
  }, [leadSourceDetail?.configuration?.last_payload]);

  useEffect(() => {
    if (!isEmpty(leadSourceDetail?.configuration)) {
      !isEmpty(leadSourceDetail?.configuration?.last_payload) &&
        setPayloadFields(
          transformObjectToPayloadFields(leadSourceDetail?.configuration?.last_payload),
        );
      const mappings = buildMappings(config);
      form.setFieldsValue({ ...mappings });
    }
  }, [JSON.stringify(leadSourceDetail?.configuration), form]);

  return (
    <>
      {isEmpty(leadSourceDetail?.configuration?.last_payload) &&
        leadSourceDetail?.configuration?.webhook_url && (
          <WebhookAlert
            isNew={isNew}
            webhook_url={leadSourceDetail?.configuration?.webhook_url}
            onClose={onClose}
            leadSourceDetail={leadSourceDetail}
          />
        )}
      {isPolling && isEmpty(leadSourceDetail?.configuration?.last_payload) && <PollingSpinner />}
      {!isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <MappingForm
          form={form}
          handleMappingSubmit={handleMappingSubmit}
          systemFields={systemFields}
          payloadFields={payloadFields}
          onClose={onClose}
          prevStep={prevStep}
        />
      )}
    </>
  );
};

export default MappingConfigurationStep;

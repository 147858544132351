import component from './nl-NL/component';
import globalHeader from './nl-NL/globalHeader';
import menu from './nl-NL/menu';
import pages from './nl-NL/pages';
import pwa from './nl-NL/pwa';
import request from './nl-NL/request';
import settingDrawer from './nl-NL/settingDrawer';
import settings from './nl-NL/settings';
import tour from './nl-NL/tour';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...request,
  ...tour,
};

import * as React from "react"
import {useEffect} from "react"
import {useDispatch, useIntl, useSelector} from "umi";
import isEmpty from "lodash/isEmpty";

import {F} from "@/utils/intl";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import * as LocationSelectors from "@/selectors/locationSelectors";

const BranchDropdownFilters = ({ onSelectionChange, ...props }: any) => {
  const branches = useSelector(LocationSelectors.selectBranches);
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    if (isEmpty(branches)){
      dispatch({
        type: 'user/getUserBranches'
      })
    }
  }, []);

  const handleSelectionChange = (branchId) => {
    const branch = branches.find(branch => branch.id === branchId)
    onSelectionChange && onSelectionChange(branchId);
    props.onChange && props.onChange(branchId, branch)
  };

  const getOptions = () => {
    if (!props.showUnlinked) return branches

    return (branches || []).concat({
      name: intl.formatMessage({id: 'pages.lead.branch.unlinked', defaultMessage: 'No branch'}),
      id: 'null'
    })
  }
  return (
    <LeadDropdownFilter
      placeholder={<F id={"pages.leads.filters.location.placeholder"} defaultMessage={'Branch'}/>}
      optionLabelKey={'location'}
      filterKey={'branch'}
      options={getOptions()}
      {...props}
      onChange={handleSelectionChange}
    />
  );
};

export default BranchDropdownFilters;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'umi';
import {Badge, Button, Divider, Form, Space, Tabs, Tooltip} from 'antd';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Drawer from '@/components/Drawer';
import * as LeadSelectors from "@/selectors/leadSelectors";
import LeadName from "@/components/Lead/LeadName";
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";
import {InboxOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";
import LeadActivity from "@/components/Lead/LeadActivity";
import {TasksTable} from "@/pages/Tasks/TasksTable";
import {F} from "@/utils/intl";
import LeadComments from "@/components/Lead/LeadComments";
import LeadDetailsServiceRequests from "@/components/Lead/LeadDetailsServiceRequests";

import styles from './index.less';
import LeadState from "@/components/Lead/LeadState";

const LeadDetailsDrawer = () => {
  const [form] = Form.useForm()
  const detailsDrawer = useSelector(LeadSelectors.selectLeadDetailsDrawer)
  const leadId = detailsDrawer?.leadId
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, leadId))
  const lead = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadData(state, leadId))

  const commentsCount = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type === 'comment').length
  const fetchLeadData = () => dispatch({
    type: 'leads/fetchSingleLead',
    payload: {
      leadId
    }
  })

  const tabs = [
    {
      key: 'details',
      label: <F id={"pages.lead.drawer.details"} defaultMessage={'Details'}/>,
      children: <div>
        <LeadState lead={lead}/>
        <LeadCardOrderedProperties lead={lead} showAllProperties spaceSize={24}/>
      </div>,
    },
    {
      key: 'activities',
      label: <F id={"pages.lead.drawer.activities"} defaultMessage={'Activities'}/>,
      children: <div>
        <LeadActivity leadDetails={leadDetails}/>
      </div>,
    },
    {
      key: 'serviceRequests',
      label: <F id={"pages.requests.tableTitle"} defaultMessage={'Requests'}/>,
      children: <div>
        <LeadDetailsServiceRequests leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'tasks',
      label: <Space>
        <F id={"pages.lead.drawer.tasks"} defaultMessage={'Tasks'}/>
        <Badge showZero={false} count={lead?.tasks_count}/>
      </Space>,
      children: <div>
        <TasksTable leadId={leadId} filterColumns={column => column.dataIndex !== 'lead'} data={leadDetails?.tasks || []} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'comments',
      label: <Space>
        <F id={"pages.lead.drawer.comments"} defaultMessage={'Comments'}/>
        {commentsCount > 0 && <Badge color={"volcano"} showZero={false} count={commentsCount}/>}
      </Space>,
      children: <div>
        <LeadComments leadId={leadId} leadDetails={leadDetails} request={fetchLeadData}/>
      </div>,
    },
    {
      key: 'proposals',
      label: (
        <Tooltip title="Not activated">
          <div>Proposals</div>
        </Tooltip>
      ),
      children: <div>Proposals content</div>,
      disabled: true,
    },
    {
      key: 'documents',
      label: (
        <Tooltip title="Not activated">
          <div>Documents</div>
        </Tooltip>
      ),
      children: <div>Documents content</div>,
      disabled: true,
    },
  ];

  const dispatch = useDispatch();

  function toggle() {
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
    });
  }

  useEffect(() => {
    if (isEmpty(detailsDrawer))
      return

    form.resetFields()
    fetchLeadData()
  }, [detailsDrawer]);


  const getTitle = () => lead && <LeadName readonly hideAvatar lead={lead}/>


  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={600}
        title={getTitle()}
        open={!isEmpty(detailsDrawer) && !isEmpty(lead)}
        destroyOnClose
        className={styles.drawer}
        onVisibleChange={toggle}
        drawerHeaderClassName={styles.drawerHeader}
      >
        {!isEmpty(lead) && <>
          <div className={styles.actions}>
            <Space>
              {get(lead, 'customer.email') &&
                <a href={`mailto:${get(lead, 'customer.email')}`}><Button size={"large"}
                                                                          icon={<MailOutlined/>}/></a>}
              {get(lead, 'customer.phone') &&
                <a href={`tel:${get(lead, 'customer.phone')}`}><Button size={"large"}
                                                                       icon={<PhoneOutlined/>}/></a>}
              <LeadContextMenu leadId={lead?.id} actionsFilter={['archive']}
                               archiveContent={<Button size={"large"} icon={<InboxOutlined/>}/>}/>
            </Space>
          </div>
          <Divider/>
          <Tabs defaultActiveKey="1" items={tabs}/>
        </>}
      </Drawer>
    </div>
  );
};

export default React.memo(LeadDetailsDrawer, () => true);

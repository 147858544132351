import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import {
  CreateBranchRequest,
  DeleteBranchRequest,
  DeleteServiceRequest,
  GetAgentsRequest,
  GetServiceRequest,
  GetBranchServicesRequest,
  UpdateBranchRequest,
  UpdateServiceRequest,
  GetServicesOfBranchRequest,
  GetBranchRequest
} from "@/typings/api/getLocations";
import {CreateResourceRequest, DeleteResourceRequest} from "@/typings/api/resources";
import {DeleteUserRequest} from "@/typings/api/user";

// region Fetch Branch Services
export function fetchBranchesRequest({query}) {
  const url = URLParamBuilder(PATHS.GET_BRANCHES, {
    queryString: query
  })

  return request<GetBranchServicesRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch Branch Services
type FetchBranchServicesOptions = {
  params: GetBranchServicesRequest["parameters"]
}
export function fetchBranchServicesRequest({params}: FetchBranchServicesOptions) {
  const url = URLParamBuilder(PATHS.GET_BRANCH_SERVICES, {
    params
  })

  return request<GetBranchServicesRequest["response"]>(url, {
    method: 'GET',
    params: params
  });
}
// endregion
type FetchServicesOfBranchOptions = {
  params: GetServicesOfBranchRequest["parameters"]
}
export function fetchServicesOfBranchRequest({params}: FetchServicesOfBranchOptions) {
  const url = URLParamBuilder(PATHS.GET_SERVICES_OF_BRANCH, {
    params
  })
  return request<GetAgentsRequest["response"]>(url, {});
}

// region Fetch Agents
type FetchAgentsOptions = {
  params: GetAgentsRequest["parameters"]
}
export function fetchAgentsRequest({params}: FetchAgentsOptions) {
  const url = URLParamBuilder(PATHS.FETCH_BRANCH_AGENTS, {
    params
  })
  return request<GetAgentsRequest["response"]>(url, {});
}
// endregion

// region Change branch
type UpdateBranchRequestOptions = {
  params: UpdateBranchRequest["parameters"]
  payload: UpdateBranchRequest["payload"]
}
export function updateBranchRequest({params, payload}: UpdateBranchRequestOptions) {
  const url = URLParamBuilder(PATHS.UPDATE_BRANCH_API_PATH, {
    params
  })

  return request<UpdateBranchRequest["response"]>(url, {
    method: 'PUT',
    data: payload,
  });
}
// endregion

// region Create Branch
type CreateBranchRequestOptions = {
  payload: CreateBranchRequest["payload"]
}
export function createBranchRequest({ payload}: CreateBranchRequestOptions) {
  const url = URLParamBuilder(PATHS.CREATE_BRANCH_API_PATH)

  return request<CreateBranchRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
// endregion


// region Delete Location
type DeleteBranchRequestOptions = {
  params: DeleteBranchRequest["parameters"]
}
export function deleteBranchRequest({params}: DeleteBranchRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_BRANCH_API_PATH, {
    params
  })

  return request<DeleteUserRequest["response"]>(url, {
    method: 'DELETE'
  });
}
// endregion



// region Change service
type UpdateServiceRequestOptions = {
  params: UpdateServiceRequest["parameters"]
  payload: UpdateBranchRequest["payload"]
}
export function updateServiceRequest({params, payload}: UpdateServiceRequestOptions) {
  const url = URLParamBuilder(PATHS.UPDATE_SERVICE_API_PATH, {
    params
  })

  return request<UpdateServiceRequest["response"]>(url, {
    method: 'PUT',
    data: payload,
  });
}
// endregion

// region Create service
type CreateServiceRequestOptions = {
  payload: CreateServiceRequest["payload"]
}
export function createServiceRequest({ payload}: CreateServiceRequestOptions) {
  const url = URLParamBuilder(PATHS.CREATE_SERVICE_API_PATH)

  return request<CreateServiceRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
// endregion


// region Delete service
type DeleteServiceRequestOptions = {
  params: DeleteServiceRequest["parameters"]
}
export function deleteServiceRequest({params}: DeleteServiceRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_SERVICE_API_PATH, {
    params
  })

  return request<DeleteUserRequest["response"]>(url, {
    method: 'DELETE'
  });
}
// endregion


// region CreateResource
type CreateResourceRequestOptions = {
  params: CreateResourceRequest["parameters"]
  payload: CreateResourceRequest["payload"]
}
export function createResourceRequest({params, payload}: CreateResourceRequestOptions) {
  const url = URLParamBuilder(PATHS.CREATE_RESOURCE_FOR_PROJECT, {
    params
  })

  return request<CreateResourceRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
// endregion

// region Delete Resource
type DeleteResourceRequestOptions = {
  params: DeleteResourceRequest["parameters"]
}
export function deleteResourceRequest({params}: DeleteResourceRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_RESOURCE_FOR_PROJECT, {
    params
  })

  return request<DeleteResourceRequest["response"]>(url, {
    method: 'DELETE'
  });
}
// endregion
// region Fetch Agents
type GetServiceOptions = {
  params: GetServiceRequest["parameters"]
}
export function getServiceRequest({params}: GetServiceOptions) {
  const url = URLParamBuilder(PATHS.GET_SERVICE, {
    params
  })
  return request<GetServiceRequest["response"]>(url, {
    method: 'GET',

  });
}
// endregion

type GetBranchOptions = {
  params: GetBranchRequest["parameters"]
}
export function getBranchRequest({params}: GetBranchOptions) {
  const url = URLParamBuilder(PATHS.GET_BRANCH, {
    params
  })
  return request<GetBranchRequest["response"]>(url, {
    method: 'GET',

  });
}
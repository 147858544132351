import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";

export function setUiPreferencesRequest({
                                   params,
                                   data = {}
                                 }) {
  const url = URLParamBuilder(PATHS.UI_PREFERENCES, {params})
  return request(url, {
    method: 'PUT',
    data
  });
}

export function fetchUiPreferencesRequest() {
  return request(PATHS.FETCH_UI_PREFERENCES, {
    method: 'GET'
  });
}

import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";


export function fetchLeadTasksList({query}) {
  const url = URLParamBuilder(PATHS.GET_LEAD_TASKS, {
    queryString: query
  })
  return request(url, {
    method: 'GET',
  });
}

export function createLeadTaskRequest({
  data
                                      }) {
  const url = !data.leadId ? URLParamBuilder(PATHS.CREATE_LEAD_TASK)
    : URLParamBuilder(PATHS.CREATE_TASK_FOR_LEAD, {
      params: {
        leadId: data.leadId
      }
    })
  return request(url, {
    method: 'POST',
    data
  });
}

export function editLeadTaskRequest({
  data,
  params
                                      }) {
  const url = URLParamBuilder(PATHS.EDIT_LEAD_TASK, {params})
  return request(url, {
    method: 'PUT',
    data
  });
}

export function deleteLeadTaskRequest({
  params
                                      }) {
  const url = URLParamBuilder(PATHS.EDIT_LEAD_TASK, {params})
  return request(url, {
    method: 'DELETE',
  });
}

import {RESTRTICTED_COMPONENTS} from '../../config/RestrictedComponents';
import {ROUTE_PATHS} from '../../config/RoutePaths';
import {SOLUTION_KEYS} from "@/constants/solutions";

export const SALE_AGENT = 'SalesAgent';
export const LOCATION_MANAGER = 'LocationManager';
export const PARTNER = 'Businesspartner';
export const SUPER_ADMIN = 'Superadmin';
export const ROOMTAILOR = 'Roomtailor';
export const CONVERSIONAL_ROLES = [SALE_AGENT, LOCATION_MANAGER, PARTNER];
export const roleToRouteMapping = {
  '/reports/*': [PARTNER],
  [ROUTE_PATHS.CHECK]: ['*'],
  [ROUTE_PATHS.MIMIC]: ['*'],
  [ROUTE_PATHS.USER_FORGOT_PASSWORD]: ['*'],
  [ROUTE_PATHS.RESET_PASSWORD]: ['*'],
  [ROUTE_PATHS.CUSTOMERS]: [PARTNER],
  [ROUTE_PATHS.SUPPORT]: ['*'],
  [ROUTE_PATHS.SERVICE_REQUESTS]: [PARTNER],
  [ROUTE_PATHS.LOCATIONS]: [PARTNER, LOCATION_MANAGER],
  [ROUTE_PATHS.STUDIO]: [PARTNER],
  [ROUTE_PATHS.IMAGES]: [PARTNER],
  [ROUTE_PATHS.TAG_STATISTICS]: [PARTNER],
  [ROUTE_PATHS.PROMOTIONS]: [PARTNER],
  [ROUTE_PATHS.SETTINGS]: [PARTNER],
  [ROUTE_PATHS.COMPONENTS]: ['*'],
  [ROUTE_PATHS.PROFILE]: ['*'],
  [ROUTE_PATHS.LANGUAGE]: ['*'],
  [ROUTE_PATHS.BILLINGS]: [PARTNER],
  [ROUTE_PATHS.BILLINGS_DETAILS]: [PARTNER],
  [ROUTE_PATHS.ROOT]: ['*'],
  [ROUTE_PATHS.LOGIN]: ['*'],
  [ROUTE_PATHS.USER_MANAGEMENT]: [PARTNER, LOCATION_MANAGER],
  [ROUTE_PATHS.REPORTS_LEAD_MANAGEMENT]:[PARTNER],
  [ROUTE_PATHS.REPORTS_DASHBOARD_LEAD_MANAGEMENT]:[PARTNER],
  [ROUTE_PATHS.REPORTS_FUNNEL_LEAD_MANAGEMENT]:[PARTNER],
  [ROUTE_PATHS.CALENDAR]:[SALE_AGENT],
  [ROUTE_PATHS.TODAY]:[SALE_AGENT],
  [ROUTE_PATHS.LEADS]:["*"],
  [ROUTE_PATHS.TASKS]:["*"],
  "/roi":[PARTNER],
  [ROUTE_PATHS.EMAIL_AUTOMATION]:[PARTNER],
  [ROUTE_PATHS.TODAY]:[SALE_AGENT],
  [ROUTE_PATHS.OPEN_PROPOSALS]: [LOCATION_MANAGER, SALE_AGENT],

};
const roleToComponentMapping = {
  [RESTRTICTED_COMPONENTS.JOURNEY_SWITCHER]: [PARTNER],
};
const roleToDefaultRouteMapping = {
  [PARTNER]: ROUTE_PATHS.REPORTS_DASHBOARD,
  [LOCATION_MANAGER]: ROUTE_PATHS.LEAADS,
  [SALE_AGENT]: ROUTE_PATHS.LEADS,
  [SUPER_ADMIN]: ROUTE_PATHS.REPORTS_DASHBOARD,
  [ROOMTAILOR]: ROUTE_PATHS.REPORTS_DASHBOARD,
};
const roleToSidebarMenuCustomDisplayNameMapping = {
  [ROUTE_PATHS.USER_MANAGEMENT]: {
    [LOCATION_MANAGER]: 'menu.LocationManager.UserManagement',
  },
};

export function getRolesForPath(path: string, roles?: { [key: string]: string[] }): string[] {
  const mapping = roles ?? roleToRouteMapping;
  for (const pattern in mapping) {
    if (pathMatchesPattern(path, pattern)) {
      return mapping[pattern];
    }
  }
  return [];
}
export function getDisplayNamesForPath(path: string, role: string): string {
  return roleToSidebarMenuCustomDisplayNameMapping?.[path]?.[role];
}
export function pathMatchesPattern(path, pattern) {
  const regexPattern = new RegExp('^' + pattern.replace('*', '.*') + '$');
  return regexPattern.test(path);
}

export function getRolesForComponent(component) {
  return roleToComponentMapping[component];
}
export function getRoleDefaultRoute(role: string, journeyId: string, selectedSolution) {
  if (role === SALE_AGENT || role === LOCATION_MANAGER) return roleToDefaultRouteMapping[role];

  if (selectedSolution === SOLUTION_KEYS.LEAD_MANAGEMENT) return ROUTE_PATHS.REPORTS_DASHBOARD_LEAD_MANAGEMENT

  return `/${journeyId}${roleToDefaultRouteMapping[role]}`;
}

import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {ProForm, ProFormDatePicker, ProFormText, ProFormTextArea} from '@ant-design/pro-form';
import {PlusOutlined} from "@ant-design/icons";
import {F} from "@/utils/intl";
import {useDispatch} from "umi";
import {createLeadTaskRequest} from "@/services/api/leadTask";

type ICreateTaskModalProps = {
  updateRequest?: () => void,
  leadId?: string
}
const CreateTaskModal = ({updateRequest, leadId}: ICreateTaskModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = async (data) => {
    setLoading(true)
    data.due_date = `${data.due_date}T00:00:00Z`
    if (leadId)
      data.leadId = leadId

    createLeadTaskRequest({data}).then(() => {
      setLoading(false)
      setIsModalVisible(false);
      updateRequest ? updateRequest() : dispatch({
        type: 'leads/fetchLeadsTasksList',
      });
    })
  };

  return (
    <>
      <Button type="ghost" size={"small"} icon={<PlusOutlined/>} onClick={showModal}>
        <F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'}/>
      </Button>
      <Modal
        title={<F id={'pages.lead.tasks.create'} defaultMessage={'Create Task'}/>}
        visible={isModalVisible}
        footer={null}
        destroyOnClose
        onCancel={handleCancel}
      >
        <ProForm onFinish={handleFinish} submitter={{
          resetButtonProps: {
            style: {
              display: 'none'
            }
          },
          submitButtonProps: {
            loading
          }
        }}>
          <ProFormText
            name="title"
            label={<F id={'pages.lead.tasks.taskTitle'} defaultMessage={'Title'}/>}
            rules={[{required: true}]}
          />
          <ProFormTextArea
            name="description"
            label={<F id={'pages.lead.tasks.taskDescription'} defaultMessage={'Description'}/>}
            rules={[{required: false}]}
          />
          <ProFormDatePicker
            name="due_date"
            label={<F id={'pages.lead.tasks.taskDue'} defaultMessage={'Due Date'}/>}
            rules={[{required: true}]}
          />
        </ProForm>
      </Modal>
    </>
  );
};

export default CreateTaskModal;

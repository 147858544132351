import * as React from "react"

import {LeadModel} from "@/typings/models/Lead";
import {useDispatch} from "umi";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import styles from "@/components/Lead/index.less";

const getLeadBranchId = (lead: LeadModel) => lead?.branch?.id

const LeadBranch = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  const dispatch = useDispatch()
  const leadBranchId = getLeadBranchId(lead)
  const onChangeBranch = (branchId, branch) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        branch_id: branchId,
        leadId: lead.id,
        leadOverride: {
          branch
        }
      }
    })
  }

  return <BranchDropdownFilters className={styles.branchContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeBranch} value={leadBranchId}/>
}
const propsAreEqual = (prevProps, nextProps) => {
  const prevBranch = getLeadBranchId(prevProps.lead)
  const newBranch = getLeadBranchId(nextProps.lead)
  return prevBranch === newBranch
}

export default React.memo(LeadBranch, propsAreEqual)

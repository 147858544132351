import {LeadModel} from "@/typings/models/Lead";
import {useDispatch} from "umi";


const LeadMarketingChannel = ({lead}: {lead: LeadModel}) => {
  const dispatch = useDispatch()
  const {customer} = lead

  // TODO: Next iteration
  // const onChangeMarketingChannel = (newMarketingChannel) => {
  //   dispatch({
  //     type: 'leads/edit',
  //     payload: {
  //       marketing_channel: newMarketingChannel,
  //       leadId: lead.id
  //     }
  //   })
  // }
  // return <LeadMarketingChannelDropdownFilters selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeMarketingChannel} value={lead.marketing_channel}/>

  return customer.utm_medium
}

export default LeadMarketingChannel

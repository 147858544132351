import get from "lodash/get";
import {createSelector} from "reselect";
import isEmpty from "lodash/isEmpty";
import {filterNullUndefined} from "@/utils/functions";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import * as PerformanceReportConstants from "@/pages/LeadManagementReports/constants";

const DEFAULT_VISIBILITY_SETTINGS = {}
export const selectLeadsVisibilitySettings = (state: DefaultRootState) => state?.preferences?.list?.ui?.leadsVisibilitySettings || {}
export const selectShownLeadsProperties = createSelector(selectLeadsVisibilitySettings, (settings) => settings?.shown || [])
export const selectHiddenLeadsProperties = createSelector(selectLeadsVisibilitySettings, (settings) => settings?.hidden || [])

export const selectAllLeadsProperties = (state: DefaultRootState) => selectShownLeadsProperties(state).concat(selectHiddenLeadsProperties(state))
export const selectLeadView = (state: DefaultRootState) => state?.preferences?.list?.ui?.leadListView?.mode || 'kanban'
export const selectFilters = (state: DefaultRootState, place: string) => {
  const filters = get(state, `preferences.list.ui.${place}__filters`, {})
  const processed = filterNullUndefined(filters)

  if (place === FILTER_PLACES.LEAD_PERFORMANCE_REPORT) {
    processed.metrics = processed.metrics || PerformanceReportConstants.DEFAULT_METRICS
    processed.group_by = processed.group_by || PerformanceReportConstants.DEFAULT_GROUP
  }

  return processed
}
export const isPreferencesListInitialized = (state: DefaultRootState) => !isEmpty(state?.preferences?.list) && state?.preferences?.initialized
export const selectSelectedSolution = (state: DefaultRootState) => state?.preferences?.list?.ui?.selectedSolution?.value
export const selectIsHeatMap = (state: DefaultRootState) => state?.preferences?.list?.ui?.isHeatmapList?.isHeatmapList

export const selectPriorityList = (state: DefaultRootState) => get(state, 'preferences.list.lead.priorities', [])
export const selectEmailAutomationTypesList = (state: DefaultRootState) => get(state, 'preferences.list.lead.email_automation_types', [])
export const selectStateTypesList = (state: DefaultRootState) => {
  const stateTypes = get(state, 'preferences.list.lead.states_types', {})
  return Object.keys(stateTypes).map(stateType => ({
    id: stateTypes[stateType].value,
    title: stateTypes[stateType].label,
  }))
}

export const selectPersistQueue = (state: DefaultRootState) => state.preferences.persistQueue || {}


import * as React from 'react'
import {useDispatch, useIntl} from "umi";
import dayjs from "dayjs";
import {Button, Divider, List, Space, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import {ServiceRequestModel, ServiceRequestStatus} from "@/typings/models/ServiceRequest";
import {LeadDetailsModel} from "@/typings/models/Lead";

import Card from "@/components/Card";
import {FormatDate} from "@/components/PrettyDate";
import {capitalizeAllLetters} from "@/utils/functions";
import {F} from "@/utils/intl";
import classNames from "classnames";
import styles from "./index.less"

function ServiceRequestList({serviceRequests}: { serviceRequests: ServiceRequestModel[] }) {
  const isGrayedOut = (serviceRequest: ServiceRequestModel) => ![ServiceRequestStatus.confirmed, ServiceRequestStatus.tentative].includes(serviceRequest.status)
  return <List dataSource={serviceRequests} renderItem={serviceRequest => <Card style={{marginBottom: 5}} className={classNames({
    [styles.grayedOutServiceRequest]: isGrayedOut(serviceRequest)
  })}>
    <Space style={{width: "100%", justifyContent: "space-between"}}>
      <Tag>{serviceRequest?.service?.label}</Tag>
      <Space direction={'vertical'} style={{textAlign: 'center'}}>
        <strong><FormatDate format={'dddd, DD MMMM YYYY'} date={serviceRequest.utc_start_time}/></strong>
        <Space>
          {serviceRequest.utc_start_time && <>
            <FormatDate date={serviceRequest.utc_start_time} onlyTime/>
            <span> - </span>
            <FormatDate date={serviceRequest.utc_end_time} onlyTime/>
          </>
          }
        </Space>
      </Space>
      {serviceRequest.status ? <Tag>{capitalizeAllLetters(serviceRequest.status)}</Tag> : <span/>}
    </Space>
  </Card>}/>

}

export default function LeadDetailsServiceRequests({leadDetails, request}: {
  leadDetails: LeadDetailsModel,
  request: () => void
}) {
  const dispatch = useDispatch()
  const allServiceRequests = (leadDetails?.service_requests || []).sort((a, b) => {
    const dateA = a.utc_start_time ? new Date(a.utc_start_time) : new Date(a.created);
    const dateB = b.utc_start_time ? new Date(b.utc_start_time) : new Date(b.created);
    return dateB.getTime() - dateA.getTime();
  })
  const upcomingServiceRequests = allServiceRequests.filter(serviceRequest => dayjs(serviceRequest.utc_start_time).isAfter(dayjs()))
  const pastServiceRequests = allServiceRequests.filter(serviceRequest => dayjs(serviceRequest.utc_start_time).isBefore(dayjs()))

  const openAddServiceRequestDrawer = () => {
    dispatch({
      type: 'serviceRequest/toggleCreateServiceRequestDrawer',
      payload: {
        lead: leadDetails,
        then: request
      },
    })
  }
  const intl = useIntl()
  return <div>
    <div style={{textAlign: "right"}}>
      <Button type={"primary"} icon={<PlusOutlined/>} onClick={openAddServiceRequestDrawer}><F id={'pages.common.add'}
                                                                                               defaultMessage={'Add'}/></Button>
    </div>
    <Divider><F id={"pages.leads.serviceRequests.upcomingServiceRequests"}
                defaultMessage={'Upcoming requests'}/></Divider>
    <ServiceRequestList serviceRequests={upcomingServiceRequests}/>
    <Divider><F id={"pages.leads.serviceRequests.pastServiceRequests"} defaultMessage={'Past requests'}/></Divider>
    <ServiceRequestList serviceRequests={pastServiceRequests}/>
  </div>
}

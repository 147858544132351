import * as React from 'react'
import {useState} from 'react'
import {Space} from "antd";
import Table from "@/components/Table";
import type {ProColumns} from "@ant-design/pro-table";
import {FormattedMessage as F, useDispatch, useIntl} from "umi";
import {PrettyDateWithTime} from "@/components/PrettyDate";
import LeadCard from "@/components/Kanban/LeadCard";

export function LeadsServiceRequestsTable({data, request, loading, leadId, filterColumns = (column) => true}) {
  const [showDone, setShowDone] = useState(false)
  const dispatch = useDispatch()
  const [localLoading, setLocalLoading] = useState(false)
  const toggleShowDone = () => setShowDone(prev => !prev)

  const intl = useIntl()
  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.lead.tasks.lead" defaultMessage="Lead" />,
      dataIndex: 'lead',
      render: (lead) => lead && lead?.customer && <LeadCard leadContextMenuProps={{
        hideTasksBadge: true
      }} overrideVisibleProperties={['name', 'email']} disableDrag hideAssignee id={lead.id}/>
    },
    {
      title: <F id="pages.lead.serviceRequest.time" defaultMessage="Time" />,
      dataIndex: '_',
      render: (_, record) => <Space direction={"vertical"}>
        <PrettyDateWithTime date={record.utc_start_time}/>
        <PrettyDateWithTime date={record.utc_end_time}/>
      </Space>
    }
  ].filter(filterColumns);

  return <Table headerTitle={intl.formatMessage({
    id: 'pages.lead.tasks',
    defaultMessage: 'TASKS',
  })} columns={columns}
                size={'small'}
                pagination={false}
                isCard={false}
                search={false}
                request={request}
                fullScreen={false}
                loading={loading || localLoading}
                dataSource={data}
  />
}

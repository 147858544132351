import React, {useEffect} from 'react';
import {FormattedMessage as F, useDispatch, useIntl, useSelector} from 'umi';
import ProForm, {ProFormSelect, ProFormText} from '@ant-design/pro-form';
import {Button, Form, Space} from 'antd';

import Drawer from '@/components/Drawer';
import styles from './index.less';
import * as LocationSelectors from "@/selectors/locationSelectors";

const UserDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {visible, conversionalRoles, createUserLoading, branch_id} = useSelector(
    ({user: {drawer, conversionalRoles}, loading}: DefaultRootState) => ({
      ...drawer,
      branch_Id: drawer?.branch_id,
      conversionalRoles,
      createUserLoading: loading.effects['user/create'],
    }),
  );
  const branches = useSelector(LocationSelectors.selectBranches);

  function toggle() {
    dispatch({
      type: 'user/toggleDrawer',
    });
  }

  useEffect(() => {
    form.resetFields();
    if (visible) {
      dispatch({
        type: 'user/getUserBranches',
      });
      dispatch({
        type: 'user/fetchConversionalRoles',
      });
    }
  }, [visible]);

  function onCreateUser() {
    form.validateFields().then((values) => {
      dispatch({
        type: 'user/create',
        payload: {
          values,
          intl,
        },
      });
    });
  }

  const intl = useIntl();

  function getFooter() {
    return (
      <div style={{textAlign: 'right'}}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'pages.users.close'} defaultMessage={'Close'}/>
          </Button>

          <Button
            size={'large'}
            loading={createUserLoading}
            onClick={onCreateUser}
            type={'primary'}
          >
            <F id={'pages.users.addUser'} defaultMessage={'Add User'}/>
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={520}
        footer={getFooter()}
        title={intl.formatMessage({
          id: 'pages.users.newUser',
          defaultMessage: 'New User',
        })}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <div>
          <ProForm form={form} submitter={false}>
            <ProFormText
              label={intl.formatMessage({
                id: 'pages.customers.firstName',
                defaultMessage: 'First Name',
              })}
              required
              name={'firstname'}
              rules={[{required: true}]}
            />
            <ProFormText
              label={intl.formatMessage({
                id: 'pages.customers.lastName',
                defaultMessage: 'Last Name',
              })}
              required
              name={'lastname'}
              rules={[{required: true}]}
            />

            <ProFormText
              name="email"
              required
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: intl.formatMessage({
                    id: 'pages.settings.email.errorMessage',
                    defaultMessage: 'Please enter a valid email.',
                  }),
                },
              ]}
              label={intl.formatMessage({
                id: 'pages.resource.email',
                defaultMessage: 'Email',
              })}
            />
            <ProFormText
              name="phone"
              required
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: intl.formatMessage({
                    id: 'pages.users.phone.errorMessage',
                    defaultMessage: 'Please enter a valid phone.',
                  }),
                },
                {
                  /*
                  ^[+]?[0-9]{1,4}?: Matches an optional + followed by 1 to 4 digits (country code).
[-.\s]?: Matches an optional separator (dash, dot, or space).
[(]?[0-9]{1,3}?[)]?: Matches an optional area code enclosed in parentheses.
[-.\s]?[0-9]{1,4}: Matches 1 to 4 digits with an optional separator.
[-.\s]?[0-9]{1,4}: Matches another set of 1 to 4 digits with an optional separator.
[-.\s]?[0-9]{1,9}$: Matches the final set of 1 to 9 digits with an optional separator.
                  */
                  pattern:
                    /^[+]?[0-9]{1,4}?[-.\s]?[(]?[0-9]{1,3}?[)]?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{1,9}$/,
                  message: intl.formatMessage({
                    id: 'pages.users.phone.invalidFormat',
                    defaultMessage: 'Please enter a valid phone number.',
                  }),
                },
              ]}
              label={intl.formatMessage({
                id: 'pages.users.phone',
                defaultMessage: 'Phone',
              })}
            />
            <ProFormSelect
              label={intl.formatMessage({
                id: 'pages.users.role',
                defaultMessage: 'Role',
              })}
              name={'role'}
              required
              mode="single"
              style={{width: '100%'}}
              placeholder={intl.formatMessage({
                id: 'pages.users.selectPlaceholder',
                defaultMessage: 'Please select role',
              })}
              options={(conversionalRoles || []).map((role) => ({
                value: role.role,
                label: role.label,
              }))}
              rules={[{required: true}]}
            />
            <ProFormSelect
              initialValue={branch_id ? [branch_id] : []}
              mode="multiple"
              required
              rules={[{required: true}]}
              options={(branches || []).map((branch) => ({value: branch.id, label: branch.name}))}
              name="branches"
              label={intl.formatMessage({id: 'pages.users.branches', defaultMessage: 'Branches'})}
            />
          </ProForm>
        </div>
      </Drawer>
    </div>
  );
};

export default React.memo(UserDrawer, () => true);

export default {
    'tour.common.back': 'Atrás',
    'tour.common.close': 'Cerrar',
    'tour.common.last': 'Último',
    'tour.common.next': 'Siguiente',
    'tour.common.skip': 'Omitir',
    'tour.leads.rangePicker': 'Seleccione el rango de fechas para filtrar los datos.',
    'tour.leads.filtersCard': 'Aquí puede filtrar datos por agente, sucursal, canal de marketing o fuente.',
    'tour.leads.metricSection': 'Puede seleccionar qué métricas se mostrarán en el gráfico y agrupar los datos por diferentes categorías.',
    'tour.leads.groupBySection': 'Puede seleccionar y agrupar los datos por diferentes categorías.',
    'tour.leads.exportBtn': 'Haga clic aquí para exportar los datos del gráfico a un archivo CSV.',
    'tour.leads.barChartCard': 'Este es el gráfico de barras que muestra las métricas filtradas y agrupadas según sus criterios de selección.',
  }
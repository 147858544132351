export default {
    'tour.common.back': 'Retour',
    'tour.common.close': 'Fermer',
    'tour.common.last': 'Dernier',
    'tour.common.next': 'Suivant',
    'tour.common.skip': 'Passer',
    'tour.leads.rangePicker': 'Sélectionner la période pour filtrer les données',
    'tour.leads.filtersCard': 'Vous pouvez filtrer ici les données par Agent, Magasin, Canal de distribution, ou Source',
    'tour.leads.metricSection': 'Vous pouvez sélectionner les données à afficher dans le graphique et les regrouper par catégories',
    'tour.leads.groupBySection': 'Vous pouvez sélectionner et regrouper les données selon différentes catégories',
    'tour.leads.exportBtn': 'Cliquer ici pour exporter le graphique vers un fichier CSV',
    'tour.leads.barChartCard': 'Voici le diagramme montrant les données filtrées et regroupées en fonction de vos sélections',
  
  }
import * as React from 'react'
import {useEffect} from 'react'
import {connect, useModel, useSelector} from 'umi'
import {Spin} from 'antd';

import {JourneyModel} from "@/typings/models/Journey";
import {DispatchFunction} from '@/typings/models/ReduxState';
import {getRoleDefaultRoute, SALE_AGENT} from '@/typings/roleMapping';
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import {getUserRole} from "@/utils/access";

interface RootProps {
  selectedJourney: JourneyModel;
  journeyList: JourneyModel[];
  history: History;
  config: any;
  dispatch: DispatchFunction;

}

const Root: React.FC<RootProps> = ({
                                     selectedJourney,
                                     history,
                                     journeyList,
                                     config,
                                     dispatch,
                                   }: RootProps) => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const isPreferencesInitialized = useSelector(PreferencesSelector.isPreferencesListInitialized)
  const selectedSolution = useSelector(PreferencesSelector.selectSelectedSolution)

  const user = initialState?.currentUser?.User
  const isLoggedIn = Boolean(user)
  const userRole = getUserRole(user);

  useEffect(() => {
    if (userRole === SALE_AGENT){
      dispatch({
        type: 'preferences/fetch'
      })
    }
    if (isPreferencesInitialized) return

    dispatch({
      type: 'preferences/fetch'
    })
  }, []);

  useEffect(() => {
    if (!isLoggedIn || !userRole) return history.push('/user/login');
    if (!isPreferencesInitialized) return
    if (selectedSolution) setInitialState({
      ...initialState,
      selectedSolution
    })
    const journeyId = selectedJourney?.hashId ?? journeyList?.[0]?.hashId;
    if (journeyId) {
      if (!config?.[journeyId])
        dispatch({
          type: 'journey/select',
          payload: {
            journeyId: journeyId,
          },
        });

      history.push(getRoleDefaultRoute(userRole, journeyId, selectedSolution));
    }
  }, [selectedJourney, JSON.stringify(journeyList), isPreferencesInitialized]);
  useEffect(() => {
    setInitialState({
      ...initialState,
      config: {...initialState?.config, ...config},
    });
  }, [JSON.stringify(config)]);

  return <Spin/>;
};
export default connect(({journey, user}) => ({
  selectedJourney: journey.selectedJourney,
  journeyList: journey.list,
  config: journey.config,

}))(Root);

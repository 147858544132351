import React from 'react';
import {FormattedMessage as F, useIntl} from 'umi';
import {Col, Row, Select, Tooltip} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

const {Option} = Select;

const PerformanceChartToolbar = ({
                                   metrics,
                                   onMetricsChange,
                                   tagRender,
                                   xAxis,
                                   onXAxisChange,
                                   handleExport,
                                   columnOptions,
                                   xAxisOptions
                                 }) => {
  return (
    <Row
      align="middle"
      justify="space-between"
      gutter={[16, 16]}
      style={{marginBottom: '16px', marginRight: 10, marginLeft: 10}}
    >
      <Col>
        <Row align="middle" gutter={[16, 16]}>
          <Col>
            <span><F id={'pages.leads.metrics'} defaultMessage='Merics' /></span>
          </Col>
          <Col>
            <Select
              id="#group-by-section"
              mode="multiple"
              placeholder={useIntl().formatMessage({id: 'pages.leads.groupBy.label', defaultMessage: 'Select columns'})}
              value={metrics}
              onChange={(newMetrics) => {
                if (newMetrics.length > 0) {
                  onMetricsChange(newMetrics);
                } else {
                  // Keep at least one value; prevent clearing all
                  onMetricsChange([metrics[0]]);
                }
              }}
              tagRender={tagRender}
              style={{minWidth: '300px', transition: 'width 0.3s ease'}}
            >
              {columnOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <span><F id={'pages.leads.by'} defaultMessage="by"/></span>
          </Col>
          <Col>
            <Select
              id="group-by-section"
              onChange={(value) => onXAxisChange(value)}
              value={xAxis}
              style={{width: 200}}
            >
              {xAxisOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col>
        <Tooltip title={<F id={'pages.leads.CSVExport'} defaultMessage="Export to CSV"/>}>
          <DownloadOutlined
            id="export-btn"
            onClick={handleExport}
            style={{fontSize: '24px', cursor: 'pointer'}}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default PerformanceChartToolbar;

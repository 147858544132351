import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage as F, useDispatch, useIntl, useModel, useSelector} from 'umi';
import {Button, Grid, Space} from 'antd';

import * as LeadSelectors from "@/selectors/leadSelectors"

import Drawer from '@/components/Drawer';
import styles from './index.less';
import LeadName from "@/components/Lead/LeadName";
import LeadAssigneeDropdownFilters from "@/components/Filters/LeadAssigneeDropdownFilters";
import debounce from "lodash/debounce";
import Table from "@/components/Table";
import LeadEmail from "@/components/Lead/LeadEmail";
import {batchAssignRequest} from "@/services/api/lead";

const LeadAssignmentDrawer: React.FC = () => {

  const {initialState, setInitialState} = useModel('@@initialState');
  const [assignee, setAssignee] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const tableLoading = useSelector((state: DefaultRootState) => state.loading?.effects['leads/fetchUnassignedLeads'])
  const unassignedLeads = useSelector(LeadSelectors.selectUnassignedLeads)
  const visible = useSelector(LeadSelectors.selectAssignmentDrawerVisible)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedRowKeys([])
    setAssignee(undefined)
  }, [visible]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function toggle() {
    dispatch({
      type: 'leads/toggleAssigneeDrawer',
    });
  }

  const columns = [
    {title: 'Name', dataIndex: 'name', render: (_, lead) => <LeadName readonly lead={lead}/>},
    {title: 'Email', dataIndex: 'email', render: (_, lead) => <LeadEmail readonly lead={lead}/>},
  ];

  const [tablePayload, setTablePayload] = useState({})

  const fetchTableData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: 'leads/fetchUnassignedLeads',
      payload
    })
  }, 200), [])

  useEffect(() => {
    fetchTableData()
  }, []);

  function onAssignLeads() {
    setLoading(true)
    batchAssignRequest({
      params: {
        assigneeId: assignee
      },
      data: {
        ids: selectedRowKeys
      }
    }).then(() => {
      setLoading(false)
      toggle()
      dispatch({
        type: 'leads/fetch'
      })
    })
  }

  const intl = useIntl();
  const screens = Grid.useBreakpoint()

  function getFooter() {
    return (
      <div style={{textAlign: 'right'}}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'pages.leads.close'} defaultMessage={'Close'}/>
          </Button>

          <Button loading={loading} disabled={selectedRowKeys?.length === 0 || !assignee} size={'large'}
                  onClick={onAssignLeads} type={'primary'}>
            <F id={'pages.leads.assignLead'} defaultMessage={'Assign Lead'}/>
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={600}
        footer={getFooter()}
        title={intl.formatMessage({
          id: 'pages.leads.assignLead',
          defaultMessage: 'Assign Lead',
        })}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <Table headerTitle={null} columns={columns}
               rowKey={"id"}
               size={"small"}
               fullScreen={false}
               reload={false}

               total={unassignedLeads.total}
               links={unassignedLeads.links}
               dataSource={unassignedLeads?.data || []} loading={loading || tableLoading}
               request={fetchTableData}
               isCard={false}
               pagination={{
                 mode: 'page'
               }}
               rowSelection={rowSelection}
        />
        {selectedRowKeys.length > 0 && <Space>
          <div>Assign {selectedRowKeys.length} leads to</div>
          <LeadAssigneeDropdownFilters selectProps={{allowClear: false}} filterMode={"select"} value={assignee}
                                       onChange={setAssignee}/>
        </Space>}
      </Drawer>
    </div>
  );
};

export default React.memo(LeadAssignmentDrawer, () => true);

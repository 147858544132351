import {Tabs} from "antd";
import * as React from "react";
import {
  FormattedMessage as
    F, useModel
} from "umi";

import storage, {ENGINES} from "conversional-persistent-storage";
import JourneyAccordion from "@/components/JourneyAccordion";
import {SOLUTION_KEYS} from "@/constants/solutions";
import LeadManagementQuickActions from "@/components/menu/LeadManagementQuickActions";
import styles from "@/components/menu/MenuHeader/index.less";


const SolutionSwitch: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const solutions = initialState.solutions || []
  const onChange = (key: string) => {
    setInitialState({
      ...initialState,
      selectedSolution: key
    })
  };

  const solutionsContent = [{
    key: SOLUTION_KEYS.LEAD_GENERATION,
    label: <F id={"pages.leads.leadGeneration"} defaultMessage={"Lead Generation"}/>,
    children: <div className={styles.journeySelector}>
      <JourneyAccordion miniClassName={styles.miniJourneySelector} mini={initialState?.collapsed}/>
    </div>,
  },
    {
      key: SOLUTION_KEYS.LEAD_MANAGEMENT,
      label: <F id={"pages.leads.leadManagement"} defaultMessage={"Lead Management"}/>,
    }
  ]
  const rootUrl = storage.getItem("rootApiUrl", {}, ENGINES.LOCAL_STORAGE) || ''

  // Temporarily hide everything if not in local mode
  // if (!rootUrl.includes('conversional.local')) return <div className={styles.journeySelector} style={{marginTop: 20, paddingBottom: 15}}>
  //   <JourneyAccordion miniClassName={styles.miniJourneySelector} mini={initialState?.collapsed}/>
  // </div>

  if (solutions.length === 0) return null
  if (solutions.length === 1 && solutions[0] === SOLUTION_KEYS.LEAD_GENERATION)
    return solutionsContent[0].children
  if (solutions.length === 1 && solutions[0] === SOLUTION_KEYS.LEAD_MANAGEMENT)
    return <div style={{paddingTop: 15}}>{solutionsContent[1].children}</div>
  return <div className={styles.solutionSwitchContainer}>
    <Tabs animated size={"small"} defaultActiveKey={SOLUTION_KEYS.LEAD_GENERATION}
          activeKey={initialState.selectedSolution} items={solutionsContent} onChange={onChange}/>
  </div>

};

export default SolutionSwitch

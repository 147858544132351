import React, {useState} from "react";
import {Avatar, Form, Popover, Space} from "antd";

import {LeadModel} from "@/typings/models/Lead";

import styles from "./index.less"
import {EditOutlined} from "@ant-design/icons";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import {useDispatch, useIntl} from "umi";


const LeadNameEditForm = ({lead, hidePopover}: { lead: LeadModel, hidePopover: () => void }) => {
  const [form] = Form.useForm();
  const intl = useIntl()
  const dispatch = useDispatch()
  const handleSubmit = values => {
    dispatch({
      type: 'leads/edit',
      payload: {
        leadId: lead.id,
        'customer.firstname': values.firstname,
        'customer.lastname': values.lastname,
      }
    })
    hidePopover()
    return new Promise<boolean>(resolve => resolve(true))
  }
  return <div>
    <ProForm
      form={form}
      // @ts-ignore
      onFinish={handleSubmit}
      initialValues={{
        ...lead?.customer
      }}
      submitter={{
        resetButtonProps: {
          style: {
            display: 'none'
          }
        },
        submitButtonProps: {
          block: true
        }
      }}
    >
      <ProFormText
        label={intl.formatMessage({
          id: 'pages.customers.firstName',
          defaultMessage: 'First Name',
        })}
        required
        fieldProps={{
          autoFocus: true,
        }}
        name={"firstname"}
        rules={[{
          required: true,
          type: 'string'
        }]}
      />
      <ProFormText
        name={"lastname"}
        label={intl.formatMessage({
          id: 'pages.customers.lastName',
          defaultMessage: 'Last Name',
        })}
        required
        rules={[{
          required: true,
          type: 'string'
        }]}
      />
    </ProForm>
  </div>
}
const getLeadName = (lead: LeadModel) => {
  const {customer} = lead;
  if (!customer || (!customer.firstname && !customer.lastname)) return null;
  return `${customer.firstname} ${customer.lastname}`;
}

const LeadName = ({
                    lead,
                    readonly,
                    hideAvatar,
                    justAvatar
                  }: {
  lead: LeadModel | {customer: {firstname: string, lastname:string}},
  readonly?: boolean,
  hideAvatar?: boolean,
  justAvatar?: boolean
}) => {
  const [editPopoverOpen, setEditPopoverOpen] = useState(false);
  const hidePopover = React.useCallback(() => setEditPopoverOpen(false), []);

  let name = getLeadName(lead)
  if (!name) name = " - "
  const avatar = (
    <Avatar size={"small"} className={styles.leadNameAvatar}>
      {name.slice(0,2)}
    </Avatar>
  );

  // Return avatar only if justAvatar prop is true
  if (justAvatar) return avatar;

  // Edit content for Popover
  const editContent = !readonly && (
    <LeadNameEditForm
      // @ts-ignore
      lead={lead}
      hidePopover={hidePopover}
    />
  );

  // Main component rendering
  return (
    <div className={styles.editableTextWrapper}>
      <Space>
        {!hideAvatar && avatar}
        {name}
        {!readonly && (
          <Popover
            destroyTooltipOnHide
            trigger={['click']}
            content={editContent}
            open={editPopoverOpen}
            onOpenChange={setEditPopoverOpen}
          >
            <span className={styles.editIcon}>
              <EditOutlined/>
            </span>
          </Popover>
        )}
      </Space>
    </div>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  const previousName = getLeadName(prevProps.lead)
  const nextName = getLeadName(nextProps.lead)
  return previousName === nextName
}
export default React.memo(LeadName, propsAreEqual)

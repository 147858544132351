import {Avatar, Space, Tooltip} from "antd";
import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";
import classNames from "classnames";
import LeadName from "@/components/Lead/LeadName";

const LeadAssigneeOverview = ({lead, isTable, avatarClassName, leadContextMenuProps = {}, showAssigneeName}: {
  lead: LeadModel,
  isTable?: boolean,
  showAssigneeName?: boolean,
  avatarClassName?: string,
  leadContextMenuProps?: {}
}) => {
  const assignee = lead.assignee
  const assigneeName = assignee ? `${assignee.firstname} ${assignee.lastname}` : ''
  const content = assignee ?
    <Tooltip placement={"topLeft"} title={assigneeName}>
      <Space>
        <Avatar style={{cursor: 'pointer'}} className={classNames(avatarClassName)} src={assignee.avatar_link}
                size={"small"}>{assigneeName.slice(0, 2)}</Avatar>
        {showAssigneeName && <LeadName hideAvatar readonly lead={{customer: assignee}}/>}
      </Space>
    </Tooltip>
    : null

  return !isTable ?
    <LeadContextMenu leadId={lead?.id} actionsFilter={['assign']} assignContent={content} {...leadContextMenuProps}/> :
    <div style={{minWidth: 100}}>
      <LeadContextMenu leadId={lead?.id} actionsFilter={['assign']} assignContent={content} {...leadContextMenuProps}/>
    </div>
}

export default LeadAssigneeOverview

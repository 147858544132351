import React, {useEffect} from 'react';
import {Button, Col, Form, Input, Row,} from "antd";
import {ProFormSelect} from "@ant-design/pro-form";
import {FormattedMessage as F, useSelector} from 'umi';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import * as LeadSelectors from "@/selectors/leadSelectors";
import LeadPriorityDropdownFilters from '@/components/Filters/LeadPriorityDropdownFilters';
import LeadStateDropdownFilter from "@/components/Filters/LeadStateFilters";

const WebhookLeadSourceFormStep = ({
  isNew,
  onValuesChange,
  config,
  nextStep,
  onClose
}) => {
  const [form] = Form.useForm();
  const { userBranches } = useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { leadSourceDetail, detailLoading } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadName: state.journey?.selectedJourney?.name,
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
    detailLoading: state.loading.effects['leadSources/fetchLeadSource'],
  }));

  useEffect(() => {
    if (!isNew && leadSourceDetail) {
      form.setFieldsValue(leadSourceDetail);
    }
  }, [isNew, config, form]);
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      nextStep();
    });
  };
  return (
    <>
      <Form
        disabled={detailLoading}
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
      >
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="name"
              label={<F id={'pages.leadsources.name'} defaultMessage={'Name'} />}
              required
            >
              <Input placeholder="Enter the name of the source" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="incoming_lead_state_id"
              label={<F id={'pages.leadsources.entryState'} defaultMessage={'Entry State'} />}
              required
            >
              <LeadStateDropdownFilter filterMode={'select'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item
              name="branch_id"
              label={<F id={'pages.leadsources.branch'} defaultMessage={'Branch'} />}
            >
              <ProFormSelect
                options={userBranches?.map((branch) => ({
                  value: branch.id,
                  label: branch.name,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="assignee_id"
              label={<F id={'pages.leadsources.assignedTo'} defaultMessage={'Assign To'} />}
            >
              <LeadAssigneeDropdownFilters filterMode={'select'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item
              name="priority"
              label={<F id={'pages.leadsources.priority'} defaultMessage={'Priority'} />}
            >
              <LeadPriorityDropdownFilters filterMode={'select'} />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
            <F id={'pages.common.cancel'} defaultMessage={'Cancel'} />
          </Button>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
        { isNew  ?  <F id={'pages.leadsources.createWebhook'} defaultMessage={'Create Webhook'} /> : <F id={'pages.common.update'} defaultMessage={'Update'} /> }
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default WebhookLeadSourceFormStep;

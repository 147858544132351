import React from 'react';
import {Empty, Space, Timeline} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons';
import get from "lodash/get";
import LeadName from "@/components/Lead/LeadName";
import PrettyDate, {PrettyDateWithTime} from "@/components/PrettyDate";
import {useIntl} from "umi";
import {LeadStatePresentation} from "@/components/Filters/LeadStateFilters";
import {LeadActivityEvents} from "@/typings/models/LeadActivity";
import {LeadDetailsModel} from "@/typings/models/Lead";

const LeadActivity = ({leadDetails}: {leadDetails: LeadDetailsModel}) => {
  const activities = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type !== 'comment')
  const intl = useIntl()
  const EVENT_TYPE_MAP = {
    [LeadActivityEvents.lead_state_changed]: intl.formatMessage({
      id: 'pages.lead.activities.changedStatus'
    }),
    [LeadActivityEvents.assignee_changed]: intl.formatMessage({
      id: 'pages.lead.activities.assigned'
    }),
    [LeadActivityEvents.created]: intl.formatMessage({
      id: 'pages.lead.activities.created'
    }),
    [LeadActivityEvents.branch_changed]: intl.formatMessage({
      id: 'pages.lead.activities.changedBranch'
    }),
    [LeadActivityEvents.customer_data_changed]: intl.formatMessage({
      id: 'pages.lead.activities.customerChanged'
    }),
    [LeadActivityEvents.archived]: intl.formatMessage({
      id: 'pages.lead.activities.archived'
    }),
    [LeadActivityEvents.unarchived]: intl.formatMessage({
      id: 'pages.lead.activities.unarchived'
    }),
    [LeadActivityEvents.appointment_rescheduled]: intl.formatMessage({
      id: 'pages.lead.activities.rescheduledAppointment'
    }),
  }

  const getValueComponent = (event, key) => {

    switch (event.activity_type){

      case LeadActivityEvents.lead_state_changed:
        return <LeadStatePresentation state={event[key]}/>
      case LeadActivityEvents.assignee_changed:
        return <LeadName lead={{customer: event[key]}} readonly/>
      case LeadActivityEvents.branch_changed:
        return event[key]?.name
      case LeadActivityEvents.appointment_rescheduled:
        return <Space direction={"vertical"}>
          <PrettyDateWithTime date={event[key]?.utc_start_time}/>
          <PrettyDateWithTime date={event[key]?.utc_end_time}/>
        </Space>
    }

    return get(event[key], 'label', get(event[key], 'name', get(event[key], 'title', event[key])))
  }



  if (activities.length === 0) return <Empty/>
  return (
    <Timeline>
      {activities.map((event, index) => (
        <Timeline.Item
          key={index}
          dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
        >
          <Space direction={"vertical"}>
            <Space>
              <LeadName lead={{customer: event.editor}} readonly/>
              <span>{EVENT_TYPE_MAP[event.activity_type]}</span>
            </Space>
            <Space direction={"vertical"} style={{marginBottom: 5}}>
              {event.prev_value && (
                <Space>
                  <strong>Previous:</strong> {getValueComponent(event, 'prev_value')}
                </Space>
              )}
              {event.new_value &&
                <Space><strong>Next:</strong> {getValueComponent(event, 'new_value')}</Space>}
              <small><PrettyDate date={event.created}/></small>
            </Space>
          </Space>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default LeadActivity;

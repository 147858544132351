import * as React from 'react'
import {useEffect} from 'react'
import {connect, FormattedMessage as F, useSelector} from 'umi'
import {PageContainer, PageContainerProps} from '@ant-design/pro-layout';
import MyRangePicker from "@/components/MyRangePicker";
import {DownloadOutlined} from '@ant-design/icons';
import {Button, Grid} from 'antd';
import ClickTracker from "@/components/ClickTracker";
import {exportEventBuilder} from "@/services/tracker/events";
import {DispatchFunction} from "@/typings/models/ReduxState";
import UserMenuDropdown from "@/components/UserMenuDropdown";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";

import styles from "./index.less"

interface MyPageContainerExportTableProps {
  url?: string | boolean,
  downloadAction?: string | Function
}
interface MyPageContainerExtraProps {
  hasRangePicker?: boolean,
  exportTable?: MyPageContainerExportTableProps
  actions?: React.ReactNode[]
  topActions?: React.ReactNode[]
}

interface MyPageContainerProps extends PageContainerProps {
  children: any,
  label: any,
  extraProps?: MyPageContainerExtraProps,
  dispatch: DispatchFunction
}

const MyPageContainer: React.FC<MyPageContainerProps> = ({children, label, dispatch, extraProps = {}, ...props}: MyPageContainerProps) => {
  const {useBreakpoint} = Grid;
  const screens = useBreakpoint()
  const isPreferencesInitialized = useSelector(PreferencesSelector.isPreferencesListInitialized)

  const [pageTitle, setPageTitle] = React.useState('')

  useEffect(() => {
    if (isPreferencesInitialized) return

    dispatch({
      type: 'preferences/fetch'
    })
  }, []);

  const downloadExport = () => {
    if(extraProps && extraProps.exportTable && extraProps.exportTable.downloadAction){
      if (typeof extraProps.exportTable.downloadAction === "string"){
        dispatch({
          type: extraProps.exportTable.downloadAction
        })
      } else if (typeof extraProps.exportTable.downloadAction === "function"){
        extraProps.exportTable.downloadAction()
      }
    }
  }

  function getButton(){
    return <ClickTracker event={exportEventBuilder({
      label
    })}>
      <Button onClick={downloadExport} size={"large"} type={"primary"} key="out" icon={<DownloadOutlined/>}>
        {screens.md && <F defaultMessage={"Download CSV"} id={"component.table.downloadCSV"}/>}
      </Button>
    </ClickTracker>
  }


  function getExtra(){
    const extra = extraProps.actions || []

    if (extraProps?.hasRangePicker){
      extra.push(<MyRangePicker bordered={true}/>)
    }
    if (extraProps?.exportTable !== undefined) {
      if (extraProps?.exportTable.url) {
        extra.push(getButton())
      }
    }
    return extra
  }
  return <PageContainer fixedHeader breadcrumbRender={breadcrumbs => {
    setPageTitle(breadcrumbs.title)
    return null
  }} {...props} extra={[
    ...(extraProps.topActions || []),
    <UserMenuDropdown key={'userMenu'}/>
  ]}>
    <div className={styles.pageDataContainer}>
      <div className={styles.pageDataTitle}>{pageTitle}</div>
      <div className={styles.pageDataExtra}>
        {getExtra()}
      </div>
    </div>
    {children}
  </PageContainer>
}

export default connect()(MyPageContainer)

import React, {useEffect, useRef, useState} from "react";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {Badge, Button, Spin, Tag} from "antd";
import {useDispatch, useSelector} from "umi";
import {PlusOutlined} from "@ant-design/icons";
import classNames from "classnames";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import LeadCard from "@/components/Kanban/LeadCard";
import {F} from "@/utils/intl";
import styles from "./styles.less"

type ILeadColumnProps = {
  id: string,
  leadIds: string[],
  name: string,
  color: string,
  isSortingContainer: boolean
  dragOverlay?: any
}
// Columns are not draggable and order of status is manageable in Status Settings according to this PR: https://bitbucket.org/ConversionalDev/conversional-frontend/pull-requests/1725
const LeadColumn = (props: ILeadColumnProps) => {
  const {id, leadIds, name, color, isSortingContainer, dragOverlay, ...restProps} = props;
  const dispatch = useDispatch();
  const lastScrollTop = useRef(0);
  const [loading, setLoading] = useState(false)
  const shownLeadIds = leadIds
  const { isHeatMap } =
  useSelector((state: DefaultRootState) => ({
    isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
  }));

  useEffect(() => {

    getScrollableContainer()?.addEventListener('scroll', onScroll)

    return () => {
      getScrollableContainer()?.removeEventListener('scroll', onScroll)

    }
  }, [props?.links])

  const triggerInfiniteScrollPagination = debounce(() => {
    if (restProps?.links?.next){
      setLoading(true)
      dispatch({
        type: 'leads/fetchLeadsByUrl',
        payload: {
          url: restProps?.links?.next,
          groupId: id,
          then: () => {
            setLoading(false)
          }
        }
      })
    }
  }, 500, {
    leading: true,
    trailing: false
  })

  const onScroll = () => {
    const scrollableContainer = getScrollableContainer()
    const scrollTop = scrollableContainer.scrollTop;
    const scrollHeight = scrollableContainer.scrollHeight;
    const clientHeight = scrollableContainer.clientHeight;
    // Check if the user is near the end (within 100px of the bottom)
    if (scrollTop > lastScrollTop.current) {
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        triggerInfiniteScrollPagination()
      }
    }

    lastScrollTop.current = scrollTop;
  }


  const getScrollableContainerHtmlId = () => `kanban-column-${id}`
  const getScrollableContainer = () => document.getElementById(getScrollableContainerHtmlId())
  return (
    <div
      className={classNames(styles.kanbanColumn, 'kanban-column')}
    >
      <div
        className={classNames(styles.kanbanColumnHeader, {
          [styles.draggingColumn]: dragOverlay
        })}
        style={{'--border-color': color}}
      >
        <Tag color={color} className={styles.kanbanColumnTitle}>{name}</Tag>
        <Tag className={styles.columnBadgeTag}>
          <Badge
            count={restProps.total}
            showZero={true}
            overflowCount={Infinity}
            className={styles.columnBadge}
          />
        </Tag>
      </div>
      <SortableContext
        items={shownLeadIds}
        strategy={verticalListSortingStrategy} // verticalListSortingStrategy rectSortingStrategy
      >
      <Spin spinning={loading} style={{height: "100%"}}>
        <div className={styles.kanbanColumnList} id={getScrollableContainerHtmlId()}>
          {shownLeadIds.map((item, _index) => {
            return (
              <LeadCard
                id={item}
                key={item}
                disabled={isSortingContainer}
              />
            );
          })}
        </div>
      </Spin>
      </SortableContext>
      <div className={styles.kanbanColumnFooter}>
        <Button
          type="text"
          icon={<PlusOutlined/>}
          size="small"
          style={{width: "100%", textAlign: "left"}}
          onClick={() => {
            dispatch({
              type: 'leads/toggleAddLeadsDrawer',
              payload: {
                stateId: id.split("column-")[1],
              },
            });

          }
          }
        >
          <F id={"pages.leads.addLead"} defaultMessage={"Add Lead"}/>
        </Button>
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps, nextProps) => isEqual(prevProps.leadIds, nextProps.leadIds)

export default React.memo(LeadColumn, propsAreEqual)

import { useEffect, useState } from 'react';
import { Form, Row, Steps, Typography, Divider } from 'antd';
import { useSelector, useDispatch, FormattedMessage as F } from 'umi';
import isEmpty from 'lodash/isEmpty';


import * as LeadSelectors from '@/selectors/leadSelectors';
import MappingConfigurationStep from './MappingConfigurationStep';
import { LEAD_SOURCE_TYPE_WEBHOOK } from '../constants';
import WebhookLeadSourceFormStep from './WebhookLeadSourceFormStep';
import { transformObject } from './utils';

const { Step } = Steps;

const NewWebhookSource = ({ loadingState, isNew, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepLoading, setStepLoading] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState(''); // State for webhook URL
  const [allData, setAllData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { leadSourceDetail } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
  }));

  const emptyLeadSourceDetailState = () => {
    dispatch({
      type: 'leadSources/saveLeadSourceDetail',
      payload: {},
    });
  };

  useEffect(() => {
    dispatch({
      type: 'leadSources/fetchLeadFields',
    });
    if (isNew) {
      emptyLeadSourceDetailState();
      setAllData({});
      setWebhookUrl('');
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(leadSourceDetail)) {
      form.setFieldsValue(leadSourceDetail);
      setWebhookUrl(leadSourceDetail?.configuration?.webhook_url);
    }
    setAllData(leadSourceDetail);
  }, [isNew, leadSourceDetail, form]);
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);
  const onLeadSourceCreate = (error, data) => {
    if (error) {
      console.error('Error creating lead source:', error);
      return;
    }
    setWebhookUrl(data?.lead_source?.configuration?.webhook_url);
    nextStep();
  };
  const handleMappingSubmit = async (values) => {
    setStepLoading(true);
    sendDataToServer(values);
  };
  const onLeadSourceUpdate = (error) => {
    if (error) {
      console.error('Error creating lead source:', error);
      return;
    }
    onClose();
  };

  const sendDataToServer = async (mappings: [] = []) => {
    if (isNew && isEmpty(leadSourceDetail)) {
      setStepLoading(true);
      allData['type'] = LEAD_SOURCE_TYPE_WEBHOOK;
      dispatch({
        type: 'leadSources/createWebhookLeadSource',
        payload: {
          allData,
          cb: onLeadSourceCreate,
        },
      });
      setStepLoading(false);
    } else {
      setStepLoading(true);
      allData['type'] = LEAD_SOURCE_TYPE_WEBHOOK;
      allData['configuration'] = {};
      allData['configuration']['mappings'] = transformObject(mappings);
      dispatch({
        type: 'leadSources/updateWebhookLeadSource',
        payload: {
          allData,
          cb: onLeadSourceUpdate,
        },
      });
      setStepLoading(false);
    }
  };
  const steps = [
    {
      title: (
        <F id={'pages.leadsources.configureWebhookStep'} defaultMessage={'Configure Webhook'} />
      ),
      content: (
        <WebhookLeadSourceFormStep
          config={leadSourceDetail}
          isNew={isNew}
          nextStep={isNew ? sendDataToServer : nextStep}
          onValuesChange={(changedValues, allValues) => {
            setAllData({ ...allData, ...allValues });
          }}
          onClose={onClose}
        />
      ),
    },
    {
      title: <F id={'pages.leadsources.testAndMapStep'} defaultMessage={'Test And Map Webhook'} />,
      content: (
        <MappingConfigurationStep
          handleMappingSubmit={handleMappingSubmit}
          config={leadSourceDetail}
          isNew={isNew}
          loadingState={loadingState}
          onValuesChange={(changedValues, allValues) => {
            setAllData({ ...allData, ...allValues });
          }}
          nextStep={sendDataToServer}
          webhookUrl={webhookUrl}
          onClose={onClose}
          prevStep={prevStep}
        />
      ),
    },
  ];
  return (
    <>
      <div>
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Steps current={currentStep} style={{ width: '80%' }}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <Divider />
        <div>{steps[currentStep].content}</div>
        <div style={{ marginTop: 24 }}></div>
      </div>
    </>
  );
};

export default NewWebhookSource;

import React, {useEffect} from 'react';
import {Alert, Badge, Button, Popover, Space} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useDispatch, useModel} from "umi";
import {fetchLeads} from "@/services/api/lead";
import styles from "../index.less";
import {hasAccess} from "@/utils/access";
import {SALE_AGENT} from "@/typings/roleMapping";
import {F} from "@/utils/intl";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";

const LeadRibbonActions = () => {
  const dispatch = useDispatch()
  const { initialState, setInitialState } = useModel('@@initialState');
  const [loading, setLoading] = React.useState(false)
  const [popoverVisible, setPopoverVisible] = React.useState(false)
  const [padding, setPadding] = React.useState("")
  const [unassignedLeadCount, setUnassignedLeadCount] = React.useState(0)
  const [unlinkedBranchLeadCount, setUnlinkedBranchLeadCount] = React.useState(0)
  const isSalesAgent = hasAccess(initialState.currentUser, SALE_AGENT)
  const fetchUnassignedLeads = () => fetchLeads({
    query: {
      assignee: null
    }
  })
  const fetchUnlinkedBranchLeads = () => fetchLeads({
    query: {
      branch: null
    }
  })

  useEffect(() => {
    if (isSalesAgent) return

    setLoading(true)
    fetchUnassignedLeads().then(response => {
      const _unassignedLeadCount = response.data.total
      setUnassignedLeadCount(_unassignedLeadCount)
      fetchUnlinkedBranchLeads().then(response => {
        const _unlinkedBranchLeadCount = response.data.total
        setUnlinkedBranchLeadCount(_unlinkedBranchLeadCount)
        setLoading(false)
        const totalCount = _unassignedLeadCount + _unlinkedBranchLeadCount
        if (totalCount > 1000)
          setPadding("0 20px")
        else if (totalCount > 100)
          setPadding("0 10px")
      })
    })
  }, [])

  const popoverContent = (
    <div>
      <Space direction={"vertical"}>
        {unassignedLeadCount > 0 && <a className={styles.leadRibbonAlertContainer} onClick={() => {
          dispatch({
            type: 'preferences/changeFilters',
            payload: {
              assignee: "null",
              place: FILTER_PLACES.LEAD_LIST
            }
          })
          setPopoverVisible(false)
        }}>
          <Alert message={
            <Space>
              <span>{unassignedLeadCount.toLocaleString()}</span>
              <span><F id={"pages.lead.alert.unassignedLeads"} defaultMessage={'Unassigned Leads'}/></span>
            </Space>
          } type="warning" showIcon/>
        </a>}
        {unlinkedBranchLeadCount > 0 && <a className={styles.leadRibbonAlertContainer} onClick={() => {
          dispatch({
            type: 'preferences/changeFilters',
            payload: {
              branch: "null",
              place: FILTER_PLACES.LEAD_LIST
            }
          })
          setPopoverVisible(false)
        }}><Alert message={
          <Space>
            <span>{unlinkedBranchLeadCount.toLocaleString()}</span>
            <span><F id={"pages.lead.alert.noBranchLeads"} defaultMessage={'Leads without branch'}/></span>
          </Space>
        } type="warning" showIcon/></a>}
      </Space>

    </div>
  );

  const total = unassignedLeadCount + unlinkedBranchLeadCount
  if (isSalesAgent) return null
  if (!loading && total === 0) return null
  return (
    <div style={{padding}}>
      <Popover open={popoverVisible} onOpenChange={setPopoverVisible} content={popoverContent} title={null}
               trigger="click" placement={"bottomRight"}>
        <Badge overflowCount={3000} count={unassignedLeadCount + unlinkedBranchLeadCount} size="small">
          <Button loading={loading} icon={<UserOutlined/>}/>
        </Badge>
      </Popover>
    </div>

  );
};

export default LeadRibbonActions;

import storage from 'conversional-persistent-storage';

import { UserRole } from "@/typings/models/User";
import { PARTNER } from "@/typings/roleMapping";

export const DEFAULT_USER_ROLE: UserRole = { role: PARTNER, label: PARTNER }

export const getPersistedUserRoleOnLocalStorage: () => UserRole | null = () => {
    let devModeRole: UserRole | null = null;
    try {
      const devModeRoleString = storage.getItem('devModeRole',undefined,"localStorage");
      if (devModeRoleString) {
        devModeRole = JSON.parse(devModeRoleString);
      }
    } catch (error) {
      console.error('Error parsing devModeRole from localStorage:', error);
    }
    return devModeRole;
  };
  export const setPersistedUserRoleOnLocalStorage: (selectedRole: string) => void = (selectedRole: string) => {
    const role = { role: selectedRole, label: selectedRole };
    try {
      const roleString = JSON.stringify(role);
      storage.setItem('devModeRole', roleString,undefined,"localStorage");
    } catch (error) {
      console.error('Error setting devModeRole in localStorage:', error);
    }
  };
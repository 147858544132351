export const LeadActivityEvents = {
  lead_state_changed: 'lead_state_changed',
  assignee_changed: 'assignee_changed',
  created: 'created',
  branch_changed: 'branch_changed',
  customer_data_changed: 'customer_data_changed',
  archived: 'archived',
  unarchived: 'unarchived',
  appointment_rescheduled: 'appointment_rescheduled',
}
type LeadActivityType = keyof typeof LeadActivityEvents;
export declare interface LeadActivityModel {
  editor: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string | null;
  };
  activity_type: LeadActivityType;
  value_types: any | null;
  prev_value: any | null;
  new_value: any | null;
  created: string;
}

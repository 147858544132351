import { Row, Alert, Typography, Button, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { useIntl, FormattedMessage as F } from 'umi';

const { Text } = Typography;

const WebhookAlert = ({ isNew, webhook_url, onClose, leadSourceDetail }) => {
    const intl = useIntl();
    return (
    <>
      <>
        <Row justify="center" style={{ marginTop: 24 }}>
          <Alert
            style={{ backgroundColor: '#eaffea' }}
            icon={<CheckCircleOutlined />}
            message={
                !isNew && isEmpty(leadSourceDetail?.configuration?.last_payload)
                  ? intl.formatMessage({id: 'pages.leadsources.webhook.noSampleData'})
                  : intl.formatMessage({id: 'pages.leadsources.webhook.created'})  
              }
            description={
              <>
                <Text>Webhook URL: </Text>
                <Text copyable style={{ marginLeft: 10 }}>
                  {webhook_url}
                </Text>{' '}
              </>
            }
            type="info"
            showIcon
          />
        </Row>
        <Row justify="center" style={{ marginTop: 24 }}>
          <Form.Item>
            <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
            <F id='pages.leadsources.webhook.later' />
            </Button>
          </Form.Item>
        </Row>
      </>
    </>
  );
};

export default WebhookAlert;
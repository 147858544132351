import React from "react";
import {Divider, Modal, Segmented, Select, Space} from "antd";
import get from "lodash/get";
import {useDispatch, useIntl, useSelector} from "umi";


import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";

import {LeadStateModel} from "@/typings/models/LeadState";
import {LeadStatePresentation} from "@/components/Filters/LeadStateFilters";
import {AUTOMATION_EVENTS, MAGIC_FIELD_TYPES} from "@/components/Kanban/StateManager/constants";
import {STATUS_MAGIC_PROPERTIES} from "@/components/Kanban/VisibilitySettings/constants";
import styles from "@/components/Kanban/StateManager/index.less";
import CardHelpTooltip from "@/components/Help/CardHelpTooltip";
import {F} from "@/utils/intl";

type IStatusConfigurationModal = {
  visible: boolean,
  state?: LeadStateModel,
  onClose: () => void,
  onMagicFieldChange?: (id: string, magicProperty: string, value: string) => void
  onLeadStateTypeChange: (stateId: string, stateType: string) => void
}
export default function StateConfigurationModal({
                                                  visible,
                                                  onClose,
                                                  state,
                                                  onLeadStateTypeChange,
                                                  onMagicFieldChange
                                                }: IStatusConfigurationModal) {
  const stateTypes = useSelector(PreferencesSelector.selectStateTypesList)
  const automationEventSettings = useSelector(LeadStateSelectors.selectAppointmentEventsMap)
  const currentStatusAutomationEvent = Object.keys(automationEventSettings).find(key => {
    const event = automationEventSettings[key]
    const eventStateId = typeof event === "string" ? event : event?.id
    return eventStateId === state?.id
  })

  const dispatch = useDispatch()
  const intl = useIntl()
  const handleAppointmentAutomationChange = (newAutomationEvent) => {
    const newAutomationEvents = {}
    Object.keys(automationEventSettings).forEach(key => {
      const event = automationEventSettings[key]
      const eventStateId = typeof event === "string" ? event : event?.id
      newAutomationEvents[key] = eventStateId === state?.id ? null : eventStateId
    })

    // -1 stands for manual
    const payload = newAutomationEvent === '-1' && currentStatusAutomationEvent
      ? {[currentStatusAutomationEvent]: null}
      : {[newAutomationEvent]: state?.id};

    dispatch({
      type: 'leadStates/updateAppointmentEvents',
      payload: {
        ...newAutomationEvents,
        ...payload,
      },
    });
  };


  const getModalTitle = () => state && <Space>
    <F id={"pages.lead.status.settings"} defaultMessage={'State settings: '}/>
    <LeadStatePresentation state={state}/>
  </Space>
  return <Modal open={visible} title={getModalTitle()} footer={null} onCancel={() => onClose()}>
    <div>
      <Space direction={'vertical'}>
        <Space>
          <strong><F id={'pages.lead.status.selectDealState'} defaultMessage={'Deal'}/></strong>
          <Select value={state?.state_type || '-1'}
                        onSelect={(newLeadStateType) => onLeadStateTypeChange(state?.id, newLeadStateType)}
                        bordered={false} size={"small"}>
                  {stateTypes.map(stateType => <Select.Option value={stateType.id}
                                                              key={stateType.id}>{stateType.title}</Select.Option>)}
                </Select>
        </Space>
        <Space>
          <Space>
            <strong><F id={'pages.lead.status.selectAppointmentAutomation'}
                       defaultMessage={'Appointment Automation'}/></strong>
            <CardHelpTooltip title={<F id={'pages.lead.status.appointmentAutomationDescription'}
                                       defaultMessage={'Update the lead’s status based on the automated appointment life cycle. This allows you to map each stage of the appointment process to the corresponding lead state, ensuring smooth tracking and automation of their journey. Select a state to link it with the appointment flow, or remove the mapping if no longer needed by choosing Manual.'}/>}/>
          </Space>
          <span>
            <Select value={currentStatusAutomationEvent || '-1'}
                    style={{width: 150}}
                    onSelect={newAutomationSetting => handleAppointmentAutomationChange(newAutomationSetting)}
                    bordered={false} size={"small"}>
                  {AUTOMATION_EVENTS.map(automationEvent => <Select.Option value={automationEvent.id}
                                                                          key={automationEvent.id}>{automationEvent.title}</Select.Option>)}
                </Select>
          </span>
        </Space>
      </Space>
      <Divider>
        <Space>
          <F id={'pages.leads.status.magicFields.settingsTitle'}
             defaultMessage={'Edit Magic Fields'}/>
          <CardHelpTooltip title={<F id={'pages.leads.status.magicFields.settingsDescription'}
                                     defaultMessage={'You can specify fields to pop up on lead status change so the agents do the data entry faster and more efficiently.'}/>}/>
        </Space>

      </Divider>
      <div className={styles.magicPropertiesContainer}>
        {STATUS_MAGIC_PROPERTIES.map(magicProperty => <Space key={magicProperty}
                                                             className={styles.eachMagicProperty}>
          <strong><F id={`pages.lead.fields.${magicProperty}`}/></strong>
          <div><Segmented value={get(state?.meta, `magicFields.${magicProperty}`, 'none')}
                          onChange={(value: string) => onMagicFieldChange(state?.id, magicProperty, value)}
                          options={MAGIC_FIELD_TYPES}/></div>
        </Space>)}
      </div>
    </div>
  </Modal>
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useIntl, useSelector } from 'umi';
import { Button, Divider, Form, Input, message, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import ProForm, { ProFormSelect, ProFormSwitch } from '@ant-design/pro-form';

import Drawer from '@/components/Drawer';
import DrawerSection from '@/components/Drawer/DrawerSection';
import MapWithAutocomplete from '../../AutoComplete/MapWithAutocomplete';
import { BranchModel } from '@/typings/models/Location';
import ServiceForm from './Service';
import styles from './index.less';
import * as JourneySelectors from "@/selectors/journeySelectors";

const BranchDrawer: React.FC = () => {
  const [newBranchFormData, setNewLocationFormData] = useState<BranchModel>({ is_active: true });
  const dispatch = useDispatch();
  const intl = useIntl();
  const [branchForm] = Form.useForm();
  const journeys = useSelector(JourneySelectors.selectConversionalJourneys);

  useSelector(
    ({ location: { changeLoadings, ServicesOfBranch } }: DefaultRootState) => ({
      loadings: changeLoadings,
      services: ServicesOfBranch,
    })
  );
  const { visible, branch, createLocationLoading ,selectedBranch} = useSelector(
    ({ location: { branchDrawer,selectedBranch }, loading, }: DefaultRootState) => ({
      visible: branchDrawer.visible,
      branch: branchDrawer.branch,
      createLocationLoading: loading.effects['location/createBranch'],
      selectedBranch
    }),
  );
  function toggle() {
    dispatch({
      type: 'location/toggleBranchDrawer',
    });
  }
  useEffect(() => {
    if (!visible) {
      branchForm.resetFields();
      dispatch({
        type: 'location/setBranch',
        payload: {},
      });
      dispatch({
        type: 'location/setService',
        payload: {},
      });
    }else {
      if (isEmpty(branch)) {
        branchForm.setFieldsValue({
          name: '',
          is_active: true,
        });
      }
    }
  }, [visible]);

  useEffect(() => {
    if (!isEmpty(selectedBranch)) {
      branchForm.setFieldsValue({
        name: selectedBranch.name || '',
        is_active: selectedBranch.is_active,
        lead_source_id: selectedBranch?.lead_source?.id,
      });
      if(selectedBranch?.lead_source?.id)
      dispatch({
        type: 'journey/fetchConfig',
        payload: { journeyId: selectedBranch?.lead_source?.id },
      });
    }
  }, [selectedBranch, branchForm]);

  const deleteBranch = () => {
    dispatch({
      type: 'location/deleteBranch',
      payload: {
        branchId: branch.id,
        cb: () => {
          toggle();
        },
        intl,
      },
    });
  };

  function validateNewLocationForm() {
    return newBranchFormData.name && newBranchFormData.address;
  }

  // Handle branch creation
  function onCreateBranch() {
    if (!validateNewLocationForm()) {
      message.error(
        intl.formatMessage({
          id: 'pages.common.form.validationError',
        }),
      );
      return;
    }
    dispatch({
      type: 'location/createBranch',
      payload: {
        ...newBranchFormData,
      },
      cb: () => {
        branchForm.resetFields();
        toggle();
      },
    });
  }

  function isNewBranch() {
    return branch.id === 'NEW';
  }

  function onAddressChange(_, values) {
    if (!isNewBranch()) return;
    setNewLocationFormData({ ...newBranchFormData, ...values });
  }

  function handleFormChange(changedValues, values) {
    if (isNewBranch()) {
      setNewLocationFormData({
        ...newBranchFormData,
        ...values,
      });
    } else {
      const [field] = Object.keys(changedValues);
      onValueChange(field, changedValues[field]);
    }
  }

  function onValueChange(name: string, value: any) {
    if (isNewBranch()) return;
    const payload =
      name === 'address'
        ? { branchId: branch.id, ...value }
        : { branchId: branch.id, [name]: value };

    dispatch({
      type: 'location/updateBranch',
      payload: {
        ...branch,
        ...payload,
      },
    });
  }

  function getFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            {intl.formatMessage({ id: 'app.settings.close', defaultMessage: 'Close' })}
          </Button>
          {isNewBranch() ? (
            <Button
              size={'large'}
              loading={createLocationLoading}
              onClick={onCreateBranch}
              type={'primary'}
            >
              {intl.formatMessage({ id: 'pages.location.add', defaultMessage: 'Add' })}
            </Button>
          ) : (
            <Button color="red" size={'large'} type={'primary'} onClick={deleteBranch}>
              {intl.formatMessage({ id: 'app.settings.delete', defaultMessage: 'Delete Branch' })}
            </Button>
          )}
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={520}
        footer={getFooter()}
        title={''}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <ProForm form={branchForm} submitter={false} onValuesChange={handleFormChange}>
          <DrawerSection title={''}>
            <Form.Item
              label={intl.formatMessage({
                id: 'pages.location.branchName',
                defaultMessage: 'Branch Name',
              })}
              required
              name={'name'}
            >
              <Input
                onChange={(e) => {
                  const nameValue = e.target.value;
                  branchForm.setFieldsValue({ name: nameValue });
                  if (isNewBranch()) {
                    setNewLocationFormData({ ...newBranchFormData, name: nameValue });
                  } else {
                    onValueChange('name', nameValue);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'pages.location.branchName',
                defaultMessage: 'Lead Source',
              })}
              name={'lead_source_id'}
              tooltip={intl.formatMessage({
                id: 'pages.locations.optionalLeadSourceTooltip',
                defaultMessage: 'Optional. Pick only if the location should only show in a specific Journey UI.',
              })}
            >
              <ProFormSelect
                mode="single"
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: 'pages.locations.selectPlaceholder',
                  defaultMessage: 'Please select lead source',
                })}
                options={(journeys || []).map((j) => ({
                  value: j.hashId,
                  label: j.name,
                }))}
              />
            </Form.Item>
            <MapWithAutocomplete
              addressModel={{
                address: branch.address,
                latitude: branch.latitude,
                longitude: branch.longitude,
              }}
              onChange={
                isNewBranch()
                  ? (addressValues) => onAddressChange(undefined, addressValues)
                  : (addressValues) => onValueChange('branchAddress', addressValues)
              }
            />

            <Form.Item
              label={intl.formatMessage({
                id: 'pages.location.active',
                defaultMessage: 'Active',
              })}
              required={isNewBranch()}
              hasFeedback
            >
              <ProFormSwitch
                name={'is_active'}
                initialValue={isNewBranch() ? true : branch.is_active}
                checkedChildren={intl.formatMessage({
                  id: 'pages.location.isBranchActive',
                  defaultMessage: 'Active',
                })}
                unCheckedChildren={intl.formatMessage({
                  id: 'pages.location.isBranchActiveDisabled',
                  defaultMessage: 'Inactive',
                })}
                fieldProps={{
                  onChange: (value) => {
                    onValueChange('is_active', value);
                  },
                }}
              />
            </Form.Item>
          </DrawerSection>
        </ProForm>
        <Divider />
        {!isNewBranch() && (
          <DrawerSection title={'Services'}>
            <ServiceForm services={branch?.services} branch={branch} />
          </DrawerSection>
        )}
      </Drawer>
    </div>
  );
};

export default BranchDrawer;

import React, { useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import ImportErrorTable from './ImportErrorTable';
import { FormattedMessage as F, useIntl } from 'umi';

interface ErrorObject {
  [key: string]: {
    [key: string]: string;
  };
}

interface ImportErrorAlertProps {
  errorObject: ErrorObject;
}

const ImportErrorAlert: React.FC<ImportErrorAlertProps> = ({ errorObject }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const intl = useIntl()

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const errorCount = Object.keys(errorObject).length;

  return (
    <>
      <Alert
        message={`${intl.formatMessage({
          id: 'pages.leadsources.import_error_alert',
          defaultMessage: 'The CSV you uploaded has',
        })} ${errorCount} ${intl.formatMessage({
          id: 'pages.leadsources.errors',
          defaultMessage: 'error(s).',
        })}`}
        type="error"
        showIconπp
        action={
          <Button type="link" onClick={showModal}>
            More
          </Button>
        }
      />
      <Modal
        width={'40%'}
        title={intl.formatMessage({
          id: 'pages.leadsources.errorDetails',
          defaultMessage: 'Error Details',
        })}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            <F id="pages.common.cancel" />
          </Button>,
        ]}
      >
        <ImportErrorTable errorCount={errorCount} errorObject={errorObject} />
      </Modal>
    </>
  );
};

export default ImportErrorAlert;
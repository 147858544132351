import { useEffect, useState } from 'react';
import { Form, message, Steps, Row, Divider } from 'antd';
import { useDispatch, useSelector, useIntl } from 'umi';
import { parse } from 'csv-parse/browser/esm';
import isEmpty from 'lodash/isEmpty';

import * as LeadSelectors from '@/selectors/leadSelectors';
import {
  buildMappedData,
  detectDelimiter,
  getDefaultFields,
  getMetaFields,
  transformObject,
} from './utils';
import PreviewParsedDataTableSegment from './PreviewParsedDataTableSegment';
import MapColumnsSegment from './MapColumnsSegment';
import CSVLeadSourceImportSegment from './CSVLeadSourceImportSegment';
import { LEAD_SOURCE_TYPE_CSV } from '../constants';
import {
  CSV_LEAD_SOURCE_MAPPING_FIELDS_STEP,
  CSV_LEAD_SOURCE_PREVIEW_TABLE_STEP,
} from './constants';
import ImportErrorAlert from './ImportErrorAlert';
const { Step } = Steps;

const NewLeadSource = ({ onClose, config, isNew }) => {
  const dispatch = useDispatch();
  const [fieldMappingForm] = Form.useForm();


  const [currentStep, setCurrentStep] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingState, setLoadingState] = useState('');
  const [parsedCSVData, setParsedCSVData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [mapping, setMapping] = useState(undefined);
  const [mappedFieldsData, setMappedFieldsData] = useState([]);
  const [mappedNestedData, setMappedNestedData] = useState([]);
  const { systemFields } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
  }));
  const [metaFields, setMetaFields] = useState([]);
  const [stepLoading, setStepLoading] = useState(false);
  const [allLeadSourceData, setAllLeadSourceData] = useState(isNew ? {} : config);
  const [autoDetectedDelimiter, setAutoDetectedDelimiter] = useState('');
  const [userSelectedDelimiter, setUserSelectedDelimiter] = useState('');
  const [file, setFile] = useState(null);
  const intl = useIntl();
  const [importErrors, setImportErrors] = useState(undefined);

  useEffect(() => {
    dispatch({
      type: 'leadSources/fetchLeadFields',
    });
  }, []);
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  useEffect(() => {
    if (!isEmpty(file)) handleFileUpload(file);
  }, [autoDetectedDelimiter]);

  useEffect(() => {
    if (isEmpty(config?.configuration?.parsing)) {
      const separator = config?.configuration?.parsing?.separator?.replace(/['"]/g, '');
      setAutoDetectedDelimiter(separator);
      setUserSelectedDelimiter(separator);
    }
    if (!isEmpty(config)) {
      setAllLeadSourceData(config);
    }
  }, [config]);
  useEffect(() => {
    if(userSelectedDelimiter)
    handleFileUpload(file);
  }, [userSelectedDelimiter]);
  const handleFileUpload = (file) => {
    setParsedCSVData([]);
    setCsvHeaders([]);

    setFile(file);
    setIsUploading(true);

    setLoadingState(' Parsing CSV...');

    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;
      const detectedDelimiter = detectDelimiter(csvData);
      setAutoDetectedDelimiter(detectedDelimiter);
      !userSelectedDelimiter && setUserSelectedDelimiter(detectedDelimiter);
      
      parse(
        csvData,
        {
          columns: true,
          skip_empty_lines: true,
          trim: true,
          delimiter: userSelectedDelimiter ?? detectedDelimiter,
        },
        (err, records) => {
          if (err) {
            message.error(
              intl.formatMessage({
                defaultMessage: 'Error parsing CSV file',
                id: 'pages.leadsource.csv.parseError',
              }),
            );
            console.error(err);
            setLoadingState('');
            setIsUploading(false);
          } else {
            setParsedCSVData(records);
            if (records.length > 0) {
              setCsvHeaders(Object.keys(records[0]));
              setLoadingState(
                intl.formatMessage({
                  id: 'pages.leadsources.csv.parseSuccess',
                  defaultMessage: 'CSV Parsed Successfully!',
                }),
              );
              setTimeout(() => {
                setLoadingState('');
                setIsUploading(false);
                // setCurrentStep(CSV_LEAD_SOURCE_MAPPING_FIELDS_STEP);
              }, 1000);
            }
          }
        },
      );
    };
    reader.onerror = () => {
      message.error(intl.formatMessage({ id: 'pages.leadsources.csv.errorReadingFile' }));
      setLoadingState('');
      setIsUploading(false);
    };
    reader.readAsText(file);

    return false;
  };

  const handleMappingSubmit = async (values) => {
    setStepLoading(true);
    setMapping(values);

    const mappedData = await buildMappedData(values, parsedCSVData);
    const newFlatMappedData = mappedData.flat;
    const newMappedNestedData = mappedData.nested;

    setMappedFieldsData(newFlatMappedData);
    setMappedNestedData(newMappedNestedData);
    setLoadingState(
      intl.formatMessage({
        id: 'pages.leadsources.csv.mappingSuccessful',
        defaultMessage: 'Data Mapped Successfully!',
      }),
    );
    setTimeout(() => {
      setLoadingState('');
      setCurrentStep(CSV_LEAD_SOURCE_PREVIEW_TABLE_STEP);
      setStepLoading(false);
    }, 1000);
  };
const closeForm = ()=>{
  setFile(null);
onClose();
}
  const sendDataToServer = async () => {
    setImportErrors(undefined);
    allLeadSourceData['type'] = LEAD_SOURCE_TYPE_CSV;
    setStepLoading(true);
    allLeadSourceData['configuration'] = {
      parsing: {
        separator: userSelectedDelimiter,
      },
      mappings: transformObject(mapping),
    };
    if (isNew && !allLeadSourceData['id']) {
      dispatch({
        type: 'leadSources/createCSVLeadSource',
        payload: {
          allData: allLeadSourceData,
          mappedNestedData,
          cb: (data, succeeded) => {
            setStepLoading(false);
            if (succeeded) {
              setImportErrors(undefined);

              closeForm();
            } else {
              !isEmpty(data?.error?.error_user_message) &&
                setImportErrors(data?.error?.error_user_message);
            }
          },
        },
      });
    } else {
      dispatch({
        type: 'leadSources/updateCSVLeadSource',
        cb: (succeeded) => {
          setStepLoading(false);
          if (succeeded) {
            onClose();
          }
        },
        payload: {
          allData: allLeadSourceData,
          mappedNestedData,
          cb: (data, succeeded) => {
            setStepLoading(false);
            if (succeeded) {
              setImportErrors(undefined);
              closeForm();
            } else {
              !isEmpty(data?.error?.error_user_message) &&
                setImportErrors(data?.error?.error_user_message);
            }
          },
        },
      });
    }
  };

  const addCustomField = () => {
    const newField = { key: Date.now(), name: '', csvHeaders: [] };
    setMetaFields([...metaFields, newField]);
  };

  const handleCustomFieldNameChange = (key, value) => {
    setMetaFields(
      metaFields.map((field) => (field.key === key ? { ...field, name: value } : field)),
    );
  };

  const handleCustomFieldCsvHeadersChange = (key, value) => {
    setMetaFields(
      metaFields.map((field) => (field.key === key ? { ...field, csvHeaders: value } : field)),
    );
  };

  const previewColumns = [
    ...getDefaultFields(systemFields?.default || []),
    ...getMetaFields(systemFields?.meta || []),
  ];

  const steps = [
    {
      title: intl.formatMessage({ id: 'pages.leadsources.csv.upload' }),
      content: (
        <CSVLeadSourceImportSegment
          allLeadSourceData={allLeadSourceData}
          isNew={isNew}
          handleFileUpload={handleFileUpload}
          nextStep={nextStep}
          loadingState={loadingState}
          isUploading={isUploading}
          onClose={closeForm}
          file={file}
          onValuesChange={(changedValues, allValues) => {
            setAllLeadSourceData({ ...allLeadSourceData, ...allValues });
          }}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.leadsources.csv.map' }),
      content: (
        <MapColumnsSegment
          mappingForm={fieldMappingForm}
          handleMappingSubmit={handleMappingSubmit}
          systemFields={systemFields}
          config={config}
          isNew={isNew}
          csvHeaders={csvHeaders}
          customFields={metaFields}
          handleCustomFieldNameChange={handleCustomFieldNameChange}
          handleCustomFieldCsvHeadersChange={handleCustomFieldCsvHeadersChange}
          addCustomField={addCustomField}
          nextStep={nextStep}
          prevStep={prevStep}
          onClose={closeForm}
          setDelimiter={setUserSelectedDelimiter}
          delimiter={userSelectedDelimiter?? autoDetectedDelimiter}
          file={file}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.leadsources.csv.preview' }),
      content: (
        <PreviewParsedDataTableSegment
          mappedData={mappedFieldsData}
          previewColumns={previewColumns}
          sendDataToServer={sendDataToServer}
          stepLoading={stepLoading}
          prevStep={prevStep}
          onClose={onClose}
          setDelimiter={setAutoDetectedDelimiter}
          file={file}
          errorObject={importErrors}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Steps current={currentStep} style={{ width: '80%' }}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Row>
        <Divider />
        <div>{steps[currentStep].content}</div>
        {!isEmpty(importErrors) && (
          <>
            {' '}
            <Divider />
            <ImportErrorAlert errorObject={importErrors} />
          </>
        )}
      </div>
    </>
  );
};

export default NewLeadSource;

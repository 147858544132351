import {fetchCalendarViewServiceRequests, fetchRecentServiceRequests} from "@/services/api/serviceRequest";
import {selectCurrentJourneyId} from "@/models/journey";
import {trackModalView} from "@/services/tracker";
import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {openCSVLink} from "@/utils/download";
import {parseSort} from "@/utils/functions";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";

export function selectCurrentServiceRequestInfiniteList(state) {
  return state.serviceRequest.infiniteList
}

export function selectServiceRequestJourneyId(state) {
  return state.serviceRequest.currentJourneyForServiceRequest
}

export default {
  namespace: 'serviceRequest',

  state: {
    list: {},
    currentJourneyForServiceRequest: null,
    infiniteList: {},
    calendarView: {},
    createDrawer: {},
  },

  effects: {
    * fetch({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const journeyId = yield select(selectCurrentJourneyId)
      const query = {...date};
      if (payload.search) {
        query.search = payload.search;
      }
      const {response, data} = yield call(fetchRecentServiceRequests, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveRecentServiceRequests',
            payload: data
          })
        }
      }, 'data')
    },
    * fetchCalendarView({payload = {}}: {
      payload: { search?: string, url?: string, order_by?: string }
    }, {call, put, select}) {
      const filters = yield select(state => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_LIST))

      const filteredObj = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      if (filteredObj.sort) {
        const {sort_by, sort_direction} = parseSort(filteredObj.sort)
        if (sort_by && sort_direction) {
          delete filteredObj.sort
          filteredObj.sort_by = sort_by
          filteredObj.sort_direction = sort_direction
        }
      }
      const query = {...filteredObj, ...payload, status: 'confirmed,tentative', all: 1};
      const {response, data} = yield call(fetchCalendarViewServiceRequests, {url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          const serviceRequests = data.data || []

          yield put({
            type: 'leads/saveLeadsData',
            payload: {
              list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
            }
          })
          yield put({
            type: 'saveCalendarViewServiceRequests',
            payload: data,
            options: query
          })
        }
      })
    },
    * downloadExport({payload = {}}: { payload: { url?: string } }, {call, select}) {
      const date = yield select(selectDateFilter)
      const query = {...date, export: true};
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchRecentServiceRequests, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess() {
          if (data.data.links && data.data.links.export) {
            yield openCSVLink(data.data.links.export)
          }
        },
        * onError() {

        }
      }, 'data')

    },
    * fetchInfinite({payload}, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const journeyId = yield select(selectCurrentJourneyId)
      const previousJourneyId = yield select(selectServiceRequestJourneyId)
      if (previousJourneyId !== journeyId) {
        payload.reset = true
        yield put({
          type: 'saveJourney',
          payload: {journeyId}
        })
      }
      const {reset} = payload;
      let url = undefined;
      if (!reset) {
        const current = yield select(selectCurrentServiceRequestInfiniteList)
        url = current?.links?.next
      }
      const {response, data} = yield call(fetchRecentServiceRequests, {
        params: {journeyId},
        url,
        query: {limit: 4, ...date}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveInfiniteServiceRequests',
            payload: {
              data: data,
              ...payload
            }
          })
        }
      }, 'data')
    }
  },
  reducers: {
    saveRecentServiceRequests(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    }, saveJourney(state, action) {
      return {
        ...state,
        currentJourneyForServiceRequest: action.payload.journeyId,
      };
    },
    saveCalendarViewServiceRequests(state, action) {
      return {
        ...state,
        calendarView: action.payload
      };
    },
    saveInfiniteServiceRequests(state, action) {
      const {payload} = action
      const {data, reset} = payload
      const items = reset ? data.items : state.infiniteList.items.concat(data.items)
      const newInfiniteList = {
        ...data,
        items
      }
      return {
        ...state,
        infiniteList: newInfiniteList,
      };
    },
    toggleCreateServiceRequestDrawer(state, action) {
      const {payload} = action;

      trackModalView('service-request-create')
      return {
        ...state,
        createDrawer: payload
      }
    },
    toggleDrawer(state, action) {
      const {payload: {serviceRequest, nested}} = action;
      const drawer = state.drawer || {};
      drawer.nested = nested
      if (serviceRequest) {
        drawer.serviceRequest = serviceRequest
        drawer.visible = true
        trackModalView('service-request-details')
      } else {
        drawer.visible = false
      }

      return {
        ...state,
        drawer
      }
    }
  }
};

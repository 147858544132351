import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MenuOutlined} from "@ant-design/icons";
import classNames from "classnames";

import styles from "./index.less"

export function SortableItem({ children, id, hasSort , className}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1, // Optional: Make the item semi-transparent while dragging
  };


  return (
    <div ref={hasSort ? setNodeRef : null} style={style} className={classNames(styles.sortableItemContainer, className)}>
      {hasSort ? <div className={styles.dragHandle} {...listeners} {...attributes}>
        <MenuOutlined/>
      </div> : null}
      <div className={styles.sortableItemContent}>{children}</div>
    </div>
  );
}

import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import EditableTextField from "@/components/Lead/EditableTextField";

const getLeadPhone = (lead: LeadModel) => lead?.customer?.phone

const LeadPhone = ({lead, readonly}: { lead: LeadModel, readonly: boolean }) => {
  const phone = getLeadPhone(lead)
  return <EditableTextField editable={!readonly} lead={lead} property={'customer.phone'}>
    <a href={phone && `tel:${phone}`}>{phone || " - "}</a>
  </EditableTextField>
}

const propsAreEqual = (prevProps, nextProps) => {
  const prevPhone = getLeadPhone(prevProps.lead)
  const newPhone = getLeadPhone(nextProps.lead)
  return prevPhone === newPhone
}

export default React.memo(LeadPhone, propsAreEqual)

import React, { useCallback, useEffect } from 'react';
import { DispatchFunction } from '@/typings/models/ReduxState';
import ScoreTitle from '@/components/ScoreTitle';
import { Col, Row, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { AuditOutlined, MailOutlined, SendOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { connect, useIntl } from 'umi';
import { DashboardScoreModel } from '@/typings/models/DashboardScore';

interface DashboardScoreProps {
  dispatch: DispatchFunction;
  loading: boolean;
  scores: DashboardScoreModel;
  date: {
    updatedAt: number;
  };
}
const REDUX_ACTION = 'analytics/fetchScores';
const DashboardScore: React.FC<DashboardScoreProps> = ({
  dispatch,
  scores = {},
  loading,
  date,
}) => {
  const intl = useIntl();

  const fetchData = useCallback(
    debounce(() => {
      dispatch({
        type: REDUX_ACTION,
      });
    }, 200),
    [],
  );

  useEffect(() => {
    fetchData();
  }, [date.updatedAt]);

  return (
    // @ts-ignore
    <Spin spinning={loading}>
      <Row gutter={[16, 16]} style={{ marginBottom: '2rem' }} id={'dashboard-score-container'}>
        <Col md={8} xs={24}>
          <ScoreTitle
            title={intl.formatMessage({
              id: 'component.atAGlance.totalCustomers',
              defaultMessage: 'Total Customer',
            })}
            tooltip={intl.formatMessage({
              id: 'component.atAGlance.totalCustomers.tooltip',
              defaultMessage: '',
            })}
            score={scores.customers}
            icon={<UsergroupAddOutlined />}
          />
        </Col>
        <Col md={8} xs={24}>
          <ScoreTitle
            title={intl.formatMessage({
              id: 'component.atAGlance.totalRequests',
              defaultMessage: 'Total Requests',
            })}
            tooltip={intl.formatMessage({
              id: 'component.atAGlance.totalRequests.tooltip',
              defaultMessage: '',
            })}
            score={scores.requests}
            icon={<AuditOutlined />}
          />
        </Col>
        <Col md={8} xs={24}>
          <ScoreTitle
            title={intl.formatMessage({
              id: 'component.atAGlance.totalSubscribers',
              defaultMessage: 'Total Subscribers',
            })}
            tooltip={intl.formatMessage({
              id: 'component.atAGlance.totalSubscribers.tooltip',
              defaultMessage: '',
            })}
            score={scores.subscribers}
            icon={<MailOutlined />}
          />
        </Col>
      </Row>
      {!!(scores.sent_mails) && (
        <Row gutter={[16, 16]} style={{ marginBottom: '2rem' }} id={'dashboard-score-container'}>
          <Col md={8} xs={24}>
            <ScoreTitle
              title={intl.formatMessage({
                id: 'component.atAGlance.sentMails',
                defaultMessage: 'Sent Mails',
              })}
              tooltip=""
              score={scores.sent_mails}
              icon={<SendOutlined />}
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default connect(({ analytics: { scores }, date, loading }) => ({
  scores,
  date,
  loading: loading.effects['analytics/fetchScores'],
}))(DashboardScore);

import React, { useEffect, useState } from 'react';
import { Card, Button, Typography, Space, Badge } from 'antd';
import { UserOutlined, FileOutlined, ApiOutlined } from '@ant-design/icons';
import CSVLeadSourceImport from './CSV/Index';
import WebhookLeadSourceConfig from './Webhook';
import AddManualLead from './ManualLead';
import { useDispatch, useSelector, FormattedMessage as F } from 'umi';
import { LEAD_SOURCE_TYPE_CSV, LEAD_SOURCE_TYPE_MANUAL, LEAD_SOURCE_TYPE_WEBHOOK } from './constants';
import styles from './index.less';

const { Text } = Typography;

const LeadSourceSwitcher = () => {
  const [selectedSource, setSelectedSource] = useState('manual');
  const { visible } = useSelector(({ leads: { addLead } }: DefaultRootState) => ({
    visible: addLead?.drawer?.visible,
  }));
  const dispatch = useDispatch();
  const handleSourceChange = (source) => {
    setSelectedSource(source);
    dispatch({
      type: 'leadSources/saveLeadSourceDetail',
      payload: {},
    });
  };
  const { leadSourceList } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
  }));
  const csvSourceCount = leadSourceList.filter(
    (source) => source.type === LEAD_SOURCE_TYPE_CSV,
  )?.length;
  const webhookSourceCount = leadSourceList.filter(
    (source) => source.type === LEAD_SOURCE_TYPE_WEBHOOK,
  )?.length;
  const buttonStyle = (source) => ({
    backgroundColor: selectedSource === source ? '#F8E9E2' : '#E8E8E8',
    borderColor: selectedSource === source ? '#FA7F46' : '#D9D9D9',
    color: selectedSource === source ? '#FA7F46' : '#595959',
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: '150px',
    height: '50px',
  });

  return (
    <Card
      title={<Text style={{ fontSize: '16px', fontWeight: '500' }}><F id={'pages.leadsources.title'} defaultMessage='Select Lead Source'/> </Text>}
      bordered={false}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <div className={styles.scrollContainer}>
        <Space size="large" style={{ display: 'flex' }}>
          <Button
            type="default"
            style={buttonStyle('manual')}
            onClick={() => handleSourceChange('manual')}
          >
            <UserOutlined />
            <F id='components.leadSourceSwitcher.manual' defaultMessage='Manual' />
          </Button>
          <Badge count={csvSourceCount} style={{ backgroundColor: 'red' }}>
            <Button
              type="default"
              style={buttonStyle(LEAD_SOURCE_TYPE_CSV)}
              onClick={() => handleSourceChange(LEAD_SOURCE_TYPE_CSV)}
            >
              <FileOutlined />
              <F id='components.leadSourceSwitcher.csvFile' defaultMessage='CSV File' />

            </Button>
          </Badge>
          <Badge count={webhookSourceCount} style={{ backgroundColor: 'red' }}>
            <Button
              type="default"
              style={buttonStyle('webhook')}
              onClick={() => handleSourceChange('webhook')}
            >
              <ApiOutlined />
              <F id={'components.leadSourceSwitcher.webhook'} defaultMessage={'Webhook'} />
            </Button>
          </Badge>
          <Button disabled type="default" style={{ ...buttonStyle('Carat'), opacity: 0.5 }}>
            Carat Planer
          </Button>
          <Button disabled type="default" style={{ ...buttonStyle('QR'), opacity: 0.5 }}>
            QR Code
          </Button>
          <Button disabled type="default" style={{ ...buttonStyle('API'), opacity: 0.5 }}>
            API
          </Button>
          <Button disabled type="default" style={{ ...buttonStyle('Outlook'), opacity: 0.5 }}>
            Outlook
          </Button>
          <Button disabled type="default" style={{ ...buttonStyle('Journeys'), opacity: 0.5 }}>
            Journeys
          </Button>
        </Space>
      </div>

      <div style={{ marginTop: '20px' }}>
        {selectedSource === LEAD_SOURCE_TYPE_MANUAL && visible === true && (
          <div>
            <AddManualLead />
          </div>
        )}
        {selectedSource === LEAD_SOURCE_TYPE_CSV && visible && (
          <div>
            {' '}
            <CSVLeadSourceImport />
          </div>
        )}
        {selectedSource === LEAD_SOURCE_TYPE_WEBHOOK && visible && (
          <div>
            <WebhookLeadSourceConfig />
          </div>
        )}
      </div>
    </Card>
  );
};

export default LeadSourceSwitcher;

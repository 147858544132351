import { ServiceModel } from '@/typings/models/Location';

export function getLocationAllowDateTimes(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter(
      (availability) => availability.allow_day_and_time !== undefined,
    ).map((availability) => availability.allow_day_and_time);
  }
  return [];
}

export function getLocationOpenPeriods(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter(
      (availability) => availability.allow_period !== undefined,
    ).map((availability) => availability.allow_period);
  }
  return [];
}

export function getLocationBlockPeriods(service: ServiceModel) {
  if (Array.isArray(service?.calendar?.Availability)) {
    return service?.calendar?.Availability.filter((av) => av.block_period).map(
      (av) => av.block_period,
    );
  }
  return [];
}

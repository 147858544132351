import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {saveChartData} from "@/utils/functions";
import {fetchFunnelChartData, fetchPerformanceChartData, fetchRoiFunnelChartData} from "@/services/api/leadAnalytics";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";

const initialState = {
    performance: {},
    funnel: {},
    roiFunnel: {}
}
export default {
  namespace: 'leadsAnalytics',

  state: initialState,

  effects: {
    * fetchPerformanceChartData({payload = {}}: { payload }, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const filters = yield select(state => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_PERFORMANCE_REPORT))
      const query = {
        ...filters,
        create_at_from: date.start_date,
        create_at_to: date.end_date
      };
      const {response, data} = yield call(fetchPerformanceChartData, {url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setPerformanceChartData',
            payload: data
          })
        },
        * onError(error) {
        }
      })
    },
    * fetchFunnelChartData({payload = {}}: { payload }, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const query = { created_from: date.start_date, created_to: date.end_date};
      if (payload?.breakdownBy)
        query["group_by"] = payload?.breakdownBy
      const {response, data} = yield call(fetchFunnelChartData, {url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setFunnelChartData',
            payload: data
          })
        }
      })
    },
    * fetchRoiFunnelChartData({payload = {}}: { payload }, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const chartFilters = {...yield select(state => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_ROI_FUNNEL_REPORT))}

      const query = {
        breakdown_by: payload?.breakdownBy,
        created_from: date.start_date,
        created_to: date.end_date
      };
      const {response, data} = yield call(fetchRoiFunnelChartData, {url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setRoiFunnelChartData',
            payload: data
          })
        }
      })
    },


  },

  reducers: {
    setPerformanceChartData(state, action) {
      return saveChartData(state, 'performance', action.payload)
    },
    setFunnelChartData(state, action) {
      return saveChartData(state, 'funnel', action.payload)
    },
    setRoiFunnelChartData(state, action) {
      return saveChartData(state, 'roiFunnel', action.payload)
    },
    reset(){
      return initialState
    }
  }
};

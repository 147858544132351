import * as React from 'react'
import {Button, ButtonProps} from "antd";
import styles from "./index.less"
import classNames from "classnames";

interface NestedDrawerButtonProps extends ButtonProps {
  isInput?: boolean;
}

const NestedDrawerButton: React.FC<NestedDrawerButtonProps> = ({
  children,
  isInput,
  ...props
}: NestedDrawerButtonProps) => {
  return (
    <Button
      type={'primary'}
      {...props}
      className={classNames(isInput?styles.nestedDrawerInput:styles.nestedDrawerButton, props.className)}
    >
      {children}
    </Button>
  );
};

export default (NestedDrawerButton)

import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import PrettyDate from "@/components/PrettyDate";

import styles from "./index.less"
import {F} from "@/utils/intl";

const LeadCreatedAt = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  const content = <PrettyDate date={lead.created}/>

  if (isTable) return content

  return <div>
    <div className={styles.tinyDateKey}><F id={"pages.lead.fields.createdAt"} defaultMessage={"Created at"}/></div>
    <div><PrettyDate date={lead.created}/></div>
  </div>

}

export default LeadCreatedAt

import * as React from 'react'
import LogoAndTitle from "@/components/LogoAndTitle";
import styles from "./index.less"
import {useModel} from 'umi';
import {MenuFoldOutlined} from '@ant-design/icons';
import classNames from "classnames";
import SolutionSwitch from "@/components/menu/SolutionSwitch";
import {checkUserAccessToComponent} from '@/utils/access';
import {RESTRTICTED_COMPONENTS} from '../../../../config/RestrictedComponents';

interface MenuHeaderProps {
  logo: string
}

const MenuHeader: React.FC<MenuHeaderProps> = ({logo}: MenuHeaderProps) => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const isJourneySwitcherRestricted = !checkUserAccessToComponent(initialState?.currentUser?.User, RESTRTICTED_COMPONENTS.JOURNEY_SWITCHER);
  const solutions = initialState.solutions || []

  return (
    <div
      className={classNames({
        [styles.menuHeader]: true,
        [styles.noBorder]: solutions.length > 1,
        [styles.collapsed]: initialState?.collapsed,
      })}
    >
      <div
        className={styles.collapseSwitch}
        onClick={() =>
          setInitialState({
            ...initialState,
            collapsed: !initialState?.collapsed,
          })
        }
      >
        <MenuFoldOutlined />
      </div>
      <div
        className={isJourneySwitcherRestricted ? styles.logoWithoutJourneySelector : styles.logo}
      >
        <LogoAndTitle logo={logo} titleClassName={styles.title} />
      </div>
      <SolutionSwitch/>
    </div>
  );
}

export default (MenuHeader)

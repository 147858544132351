import React, {useEffect, useState} from 'react';
import {useDispatch, useModel, useSelector} from 'umi';

import {Collapse, Menu, Popover, Spin} from 'antd';
import styles from './index.less';
import {JourneyModel} from "@/typings/models/Journey";
import SelectedJourneyTitle from "@/components/JourneyAccordion/SelectedJourneyTitle";
import classNames from "classnames";
import JourneyAvatar from '../JourneyAvatar';
import * as JourneySelectors from "@/selectors/journeySelectors";
import { LEAD_SOURCE_TYPE_JOURNEY } from '@/constants/leadsourceTypes';

const {Panel} = Collapse;


interface JourneyAccordionProps {
  menuVisible?: boolean,
  miniClassName?: string,
  defaultOpen?: boolean,
  mini?: boolean,
}

const JourneyAccordion: React.FC<JourneyAccordionProps> = ({
                                                             miniClassName = "",
                                                             defaultOpen = false,
                                                             mini = false,
                                                           }: JourneyAccordionProps) => {


  const [active, setActive] = useState(defaultOpen)
  const { initialState, setInitialState } = useModel('@@initialState');

  const dispatch = useDispatch();
  const { selectedJourney, config } = useSelector(({ journey }: DefaultRootState) => ({
    selectedJourney: journey.selectedJourney,
    config: journey.config,
  }));
  const journeys = useSelector(JourneySelectors.selectConversionalJourneys);
  const selectedJourneyItem =
    selectedJourney.type == LEAD_SOURCE_TYPE_JOURNEY ? selectedJourney : journeys?.[0];
  useEffect(() => {
    setInitialState({
      ...initialState,
      config: { ...initialState?.config, ...config },
    });
  }, [JSON.stringify(config)]);

  const onMenuClick = (journey: JourneyModel) => {
    dispatch({
      type: 'journey/select',
      payload: {
        journey,
        journeyId: journey?.hashId,
      },
    });
    setActive(false)
  }


  const loading = (
    <Spin
      size="small"
      style={{
        marginLeft: 8,
        marginRight: 8,
      }}
    />
  );

  if (!journeys) {
    return loading;
  }
  const menu = <Menu className={styles.journeyMenu}>
    {journeys.map(journey => <Menu.Item className={classNames({
      [styles.journeyMenuItem]: true,
      [styles.activeJourney]: journey?.hashId === selectedJourneyItem?.hashId
    })} key={journey.hashId} onClick={() => onMenuClick(journey)}>
      <JourneyAvatar journey={journey} className={styles.journeyItemAvatar}/>
      <span className={styles.journeyName}>{journey.name}</span>
    </Menu.Item>)}
  </Menu>;

  if (!mini)
    return (
      <Collapse
        defaultActiveKey={['1']}
        activeKey={active ? '1' : '0'}
        expandIconPosition={'right'}
        onChange={() => setActive(!active)}
        className={styles.journeyContainer}
      >
        <Panel
          header={
            <SelectedJourneyTitle
              journey={
                selectedJourneyItem
              }
            />
          }
          key="1"
          className={styles.siteCollapseCustomPanel}
        >
          {menu}
        </Panel>
      </Collapse>
    );

  return <Popover overlayClassName={styles.miniPopover} placement={"rightTop"}
                  content={<div className={styles.mini}>{menu}</div>}>
    <div className={classNames(styles.miniTitle, miniClassName)}>
      <SelectedJourneyTitle mini journey={selectedJourneyItem}/>
    </div>
  </Popover>
}

export default JourneyAccordion

import classNames from "classnames";
import styles from "@/components/Kanban/VisibilitySettings/index.less";
import {F} from "@/utils/intl";
import {Switch} from "antd";
import {SortableItem} from "@/components/Kanban/VisibilitySettings/SortableItem";
import React from "react";

type IPropertyProps ={
  field: string,
  isVisible: boolean,
  className?: string,
  hasSort?: boolean,
  isDragging?: boolean
}
export default function Property({field, isVisible, className, hasSort, isDragging}: IPropertyProps) {
  return <SortableItem id={field} hasSort={isVisible && hasSort}
                       className={classNames(styles.fieldListItem, className)}>
    <div><F id={`pages.lead.fields.${field}`}/></div>
    {!isDragging && <Switch
      size={"small"}
      checked={isVisible}
    />}
  </SortableItem>
}

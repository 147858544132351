import {PaginationLinksModel} from './PaginationLinks'
import {BranchModel} from "@/typings/models/Location";

export const ServiceRequestStatus = {
  confirmed: 'confirmed',
  tentative: 'tentative',
  rescheduled: 'rescheduled',
  declined: 'declined',
  cancelled_by_customer: 'cancelled_by_customer',
  rescheduled_by_customer: 'rescheduled_by_customer',
}
export interface ServiceRequestModel {
  id: string,
  created: string
  when: string
  what: string
  where: string
  status: keyof typeof ServiceRequestStatus,
  service: {
    key: string,
    label: string
  },
  result?: {
    id: string,
    doi_date: string | null
    feedback: string | null
    styles: []
  },
  utc_start_time: string,
  utc_end_time: string,
  videoCall: boolean,
  branch_service: BranchModel,
  payload: {
    start: string,
    end: string,
    what: string
  }
}

export interface ServiceRequestTableData {
  items: ServiceRequestModel[]
}

export declare interface ServiceRequestPayload {
  state?: string
}

export declare interface RecentServiceRequestItem {
  Customer: {
    firstname: string
    links: {
      href: string
    },
    link: string,
    lastname: string | null
    id: string
    engagement_score?: number
    utm_medium?: string
    order_volume?: number
    state?: { label: string; value: string; hash: string }
  }
  Service: {
    type: string
  }
  ServiceRequest: {
    created: string
    id: string
    when?: string
    what?: string
    where?: string
    payload?: ServiceRequestPayload
  }
  State: {
    label: string
  }
}

export declare interface RecentServiceRequestModel {
  links: PaginationLinksModel
  items: RecentServiceRequestItem[]
  total: number
}

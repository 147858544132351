import React from 'react';
import {Col, Row, Skeleton} from 'antd';

const SkeletonKanbanItem = () => {
  const randomHeights = Array.from({ length: 3 }).map(() => Math.floor(Math.random() * 400) + 150); // Random heights between 50px and 150px

  return (
    <Row gutter={[16, 16]}>
      {randomHeights.map((height, index) => (
        <Col key={index}>
          <Skeleton.Input
            active
            style={{ width: 220, height: height, borderRadius: 4 }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default SkeletonKanbanItem;

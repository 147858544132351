import React from "react";
import styles from "./index.less"
import {Button, Grid, Select, Space, Spin} from "antd";
import {DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {FormattedMessage as F, useIntl} from 'umi';
import ClickTracker from "@/components/ClickTracker";
import {paginationEventBuilder} from "@/services/tracker/events";


export const PAGINATION_MODES = {
  CURSOR: "cursor",
  PAGE: "page",
  SCROLL: "scroll"
}
export type MyPaginationProps = {
  total: number,
  page: number,
  size: number,
  count?: number,
  hasPageSize?: boolean,
  tableLabel?: string,
  mode?: string,
  hasMore?: boolean,
  onChange: (type: string, value: any) => void,
  loading: boolean
}
const MyPagination: React.FC<MyPaginationProps> = ({total, count, hasMore, onChange,mode, loading = false, page, tableLabel, size, hasPageSize}) => {
  const { formatMessage } = useIntl();

  const {useBreakpoint} = Grid
  const screens = useBreakpoint()

  function getUpperBand() {
    const upper = size * (page + 1)
    return upper < total ? upper : total;
  }

  function getLowerBand() {
    return (size < total || count === total) ? (size * page) : total
  }


  // TODO: What to Show in These Cases?
  if (isNaN(page) || isNaN(size) || isNaN(total) || (count && isNaN(count))) {
    return "BAD DATA"
  }


  const isPageMode = () => mode === PAGINATION_MODES.PAGE


  const isNextPageDisabled = () => isPageMode() ? !hasMore : (page + 1) * size >= total
  const translatedStringForPage = formatMessage({ id: 'component.table.page', defaultMessage: 'Page' });

  return (
    <Spin spinning={loading}>
      <div className={styles.pagination}>
        <div className={styles.prev}>
          <Space>
            {!isPageMode() && (
              <ClickTracker
                event={paginationEventBuilder({
                  label: tableLabel,
                  action: 'first',
                })}
              >
                <Button
                  onClick={() => onChange('FIRST')}
                  disabled={page === 0}
                  type={'link'}
                  icon={<DoubleLeftOutlined />}
                />
              </ClickTracker>
            )}
            <ClickTracker
              event={paginationEventBuilder({
                label: tableLabel,
                action: 'previous',
              })}
            >
              <Button
                onClick={() => onChange('PREV')}
                disabled={page === 0}
                type={'link'}
                icon={<LeftOutlined />}
              />
            </ClickTracker>
          </Space>
        </div>
        {!isPageMode() && (
          <div className={styles.info}>
            {
              screens.md && <F id="component.table.showing" defaultMessage="Showing" />
              // <FormattedMessage id="pages.common.add" defaultMessage="New" />
            }{' '}
            {getLowerBand()} - {getUpperBand()} <F id="component.table.of" defaultMessage=" of " />
            {' ' + total} {screens.md && <F id="component.table.items" defaultMessage="items" />}
          </div>
        )}
        <div className={styles.next}>
          <Space>
            {hasPageSize && (
              <Select
                defaultValue={30}
                style={{ width: 150 }}
                bordered={false}
                onChange={(e) => onChange('SIZE', e)}
                options={[
                  {
                    value: 30,
                    label: `30 / ${translatedStringForPage}`,
                  },
                  {
                    value: 50,
                    label: `50 / ${translatedStringForPage}`,
                  },
                  {
                    value: 100,
                    label: `100 / ${translatedStringForPage}`,
                  },
                  {
                    value: 200,
                    label: `200 / ${translatedStringForPage}`,
                  },
                ]}
              />
            )}
            <ClickTracker
              event={paginationEventBuilder({
                label: tableLabel,
                action: 'next',
              })}
            >
              <Button
                onClick={() => onChange('NEXT')}
                disabled={isNextPageDisabled()}
                type={'link'}
                icon={<RightOutlined />}
              />
            </ClickTracker>
            {!isPageMode() && (
              <ClickTracker
                event={paginationEventBuilder({
                  label: tableLabel,
                  action: 'last',
                })}
              >
                <Button
                  onClick={() => onChange('LAST')}
                  disabled={(page + 1) * size >= total}
                  type={'link'}
                  icon={<DoubleRightOutlined />}
                />
              </ClickTracker>
            )}
          </Space>
        </div>
      </div>
    </Spin>
  );
}

export default MyPagination

import UserMenuDropdown from '@/components/UserMenuDropdown';
import * as React from 'react'
import {useModel} from 'umi';
import { SOLUTION_KEYS } from '@/constants/solutions';
import LeadManagementQuickActions from '../LeadManagementQuickActions';

const MenuFooter: React.FC = () => {
  const {initialState} = useModel('@@initialState');

  return (
    <div>
      {initialState.selectedSolution === SOLUTION_KEYS.LEAD_MANAGEMENT && (
        <LeadManagementQuickActions />
      )}
    </div>
  );
}

export default (MenuFooter)

import * as React from "react"
import {useCallback, useEffect, useState} from "react"
import {F} from "@/utils/intl";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import {LeadModel} from "@/typings/models/Lead";
import LeadName from "@/components/Lead/LeadName";
import {fetchLeads} from "@/services/api/lead";
import debounce from "lodash/debounce";
import {Space} from "antd";
import LeadEmail from "@/components/Lead/LeadEmail";

const LeadsSelect = ({onChange, initialQuery = {}, ...props}: any) => {

  const [options, setOptions] = useState<LeadModel[]>([])
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    handleSearch()
  }, []);

  const handleSearch = useCallback(debounce((search?) => {
    setLoading(true)
    fetchLeads({
      query: {
        ...initialQuery,
        search,
        sort_by: 'firstname',
        sort_direction: 'desc'
      }
    }).then(({data}) => {
      setOptions(data.data)
      setLoading(false)
    })
  }, 400),[options])

  const optionRender = (lead: LeadModel) => {
    return <Space direction={'vertical'}>
      <LeadName readonly lead={lead}/>
      <LeadEmail lead={lead} readonly/>
    </Space>
  }

  return <LeadDropdownFilter
    placeholder={<F id={"pages.leads.filters.lead.placeholder"} defaultMessage={'Lead'}/>}
    filterKey={'lead'}
    options={options}
    onChange={onChange}
    onSearch={handleSearch}
    loading={loading}
    localSearch={false}
    optionRender={optionRender}
    {...props}

  />
};

export default LeadsSelect;

import { Button, Input, Form, Select, Divider, Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl, FormattedMessage as F } from 'umi';
import isEmpty from 'lodash/isEmpty';

import { DELIMITER_OPTIONS } from './constants';

const { Option } = Select;

const MappingConflictErrorMessage = ()=>{
  return (
    <Typography.Text style={{ marginBottom: 5 }} type="danger">
      <F
        id="pages.leadsources.mappingConflictMessage"
        defaultMessage="This field is mapped with a different CSV header which is not now existing in
  your current CSV"
      />
    </Typography.Text>
  );

}
const MapColumnsSegment = ({
  mappingForm,
  handleMappingSubmit,
  systemFields,
  config,
  isNew,
  csvHeaders,
  customFields,
  handleCustomFieldNameChange,
  handleCustomFieldCsvHeadersChange,
  addCustomField,
  prevStep,
  onClose,
  setDelimiter,
  delimiter,
}) => {
  const intl = useIntl();
  const [invalidHeaders, setInvalidHeaders] = useState<string[]>([]);
  const [selectedDelimiter, setSelectedDelimiter] = useState<string>(delimiter);

  useEffect(() => {
    if (!isNew && !isEmpty(config)) {
      const mappings = config?.configuration?.mappings?.reduce((acc, mapping) => {
        acc[mapping.target_field] = mapping.sources;
        return acc;
      }, {});
      mappingForm.setFieldsValue({ ...mappings });

      const invalidKeys = Object.keys(mappings).filter((key) =>
        mappings[key].some((header) => !csvHeaders.includes(header)),
      );
      setInvalidHeaders(invalidKeys);
    }
  }, [isNew, config, mappingForm]);
  const handleDelimiterChange = (value: string) => {
    setDelimiter(value);
    setSelectedDelimiter(value);
  };
  return (
    <>
      <Row gutter={16} style={{ marginBottom: 24, width: '100%' }}>
        <Col span={4}>
          <Form.Item style={{ width: '100%' }} label="Delimiter" style={{ marginBottom: 10 }}>
            <Select
              value={selectedDelimiter}
              onChange={handleDelimiterChange}
              options={DELIMITER_OPTIONS}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form layout="vertical" form={mappingForm} onFinish={handleMappingSubmit}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          {(systemFields?.default || [])?.map((field) => (
            <Col span={12} key={field} style={{ paddingBottom: 8 }}>
              <Form.Item
                key={field.key}
                label={field.label}
                name={field.key}
                rules={[{ required: field.required, message: `${field.label} is required` }]}
                style={{ marginBottom: 20 }}
                required={field.required}
                tooltip={field.tooltip}
              >
                <Select
                  mode="multiple"
                  placeholder={` ${intl.formatMessage({
                    id: 'pages.leadsources.selecCSVColumnsFor',
                    defaultMessage: 'Select CSV columns for',
                  })} "${field.label}"`}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setInvalidHeaders((prevInvalidHeaders) => {
                      const newInvalidHeaders = prevInvalidHeaders.filter(
                        (header) => header !== field.key,
                      );
                      if (value.some((val) => !csvHeaders.includes(val))) {
                        newInvalidHeaders.push(field.key);
                      }
                      return newInvalidHeaders;
                    });
                  }}
                >
                  {csvHeaders.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {invalidHeaders.includes(field.key) && <MappingConflictErrorMessage />}
            </Col>
          ))}
        </Row>
        <Divider />
        <Button type="dashed" onClick={addCustomField} style={{ width: '100%', marginBottom: 20 }}>
          <F id="pages.leadsources.addCustomField" defaultMessage="Add Custom Field" />
        </Button>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          {[...systemFields?.meta, ...customFields].map((field) => (
            <>
              <Col span={12} key={field.key} style={{ paddingBottom: 8 }}>
                <Form.Item
                  label={intl.formatMessage({
                    id: 'pages.leadsources.customFieldName',
                    defaultMessage: 'Custom Field Name',
                  })}
                  style={{ marginBottom: 10 }}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'pages.leadsources.enterCustomFieldName',
                      defaultMessage: 'Enter custom field name',
                    })}
                    value={field.name ?? field.key}
                    onChange={(e) => handleCustomFieldNameChange(field.key, e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} key={field} style={{ paddingBottom: 8 }}>
                <Form.Item
                  label={intl.formatMessage({
                    id: 'pages.leadsources.mapCSVColumns',
                    defaultMessage: 'Map to CSV Columns',
                  })}
                  name={'meta.' + field.key}
                  style={{ marginBottom: 10 }}
                >
                  <Select
                    mode="multiple"
                    placeholder={intl.formatMessage({
                      id: 'pages.leadsources.selectCSVColumns',
                      defaultMessage: 'Select CSV columns',
                    })}
                    value={field.csvHeaders}
                    onChange={(value) => handleCustomFieldCsvHeadersChange(field.key, value)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setInvalidHeaders((prevInvalidHeaders) => {
                        const newInvalidHeaders = prevInvalidHeaders.filter(
                          (header) => header !== 'meta.' + field.key,
                        );
                        if (value.some((val) => !csvHeaders.includes(val))) {
                          newInvalidHeaders.push('meta.' + field.key);
                        }
                        return newInvalidHeaders;
                      });
                    }}
                  >
                    {csvHeaders.map((header) => (
                      <Option key={header} value={header}>
                        {header}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {invalidHeaders.includes('meta.' + field.key) && <MappingConflictErrorMessage />}
              </Col>
            </>
          ))}
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
        <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
            <F id="pages.common.cancel" defaultMessage='Cancel' />
          </Button>

          <Button style={{ margin: '0 8px' }} onClick={prevStep}>
            <F id="pages.common.previous" defaultMessage='Previous' />
          </Button>

          <Button type="primary" htmlType="submit">
            <F id="pages.common.preview" defaultMessage='Preview' />
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default MapColumnsSegment;

import React, {useState} from 'react';
import {Avatar, Button, Comment, Divider, Form, Input} from 'antd';
import get from "lodash/get";
import PrettyDate from "@/components/PrettyDate";

import styles from "./index.less"
import {postCommentToLeadRequest} from "@/services/api/lead";
import {F} from "@/utils/intl";

const LeadComments = ({leadDetails,leadId, request}) => {
  const commentActivities = get(leadDetails, 'activities', []).filter(activity => activity?.activity_type === 'comment')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [commentValue, setCommentValue] = useState<string>()

  const onSubmit = () => {
    setSubmitLoading(true)
    postCommentToLeadRequest({
      params: {
        leadId: leadDetails?.id || leadId
      },
      data: {
       comment: commentValue
      }
    }).then(() => {
      setSubmitLoading(false)
      setCommentValue('')
      request()
    })
  }
  const onChange = e => setCommentValue(e.target.value)
  return (
    <div className={styles.commentSectionContainer}>
      <div className={styles.commentListContainer}>
        {commentActivities.map((item, index) => (
          <Comment
            key={index}
            author={`${item.new_value.author.firstname} ${item.new_value.author.lastname}`}
            avatar={<Avatar>{item.new_value.author.firstname.charAt(0)}</Avatar>}
            content={<p>{item.new_value.comment}</p>}
            datetime={<PrettyDate date={item.new_value.created}/>}
          />
        ))}
      </div>
      {commentActivities.length > 0 && <Divider/>}
      <div className={styles.postCommentContainer}>
          <Form.Item>
            <Input.TextArea onPressEnter={onSubmit} rows={2} onChange={onChange} value={commentValue}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={submitLoading} onClick={onSubmit} type="primary">
              <F id={"pages.lead.comment.newComment"} defaultMessage={"Add Comment"}/>
            </Button>
          </Form.Item>
      </div>
    </div>
  );
};

export default LeadComments;

import React, {useEffect, useState} from 'react';
import {Column} from '@ant-design/plots';
import {Card, Space, Tag} from 'antd';
import {selectDateFilter, useDispatch, useIntl, useModel, useSelector} from 'umi';

import {exportPerfomanceDataToCSV, transformLeadReportDataToBarChartFormat} from '../utils';
import MyPageContainer from '@/components/MyPageContainer';
import PerformanceChartToolbar from './PerformanceChartToolbar';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import BranchDropdownFilters from '@/components/Filters/BranchDropdownFilters';
import LeadSourceDropdownFilters from '@/components/Filters/LeadSourceDropdownFilters';
import * as LeadAnalyticsSelectors from '@/selectors/leadAnalyticsSelectors';
import PerformanceTable from './PerformanceDataTable';
import {SOLUTION_KEYS} from "@/constants/solutions";
import {FILTER_PLACES} from "@/components/Filters/LeadFilters";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import {GROUP_BY_OPTIONS, METRIC_OPTIONS} from "@/pages/LeadManagementReports/Performance/constants";

const TRACKING_LABEL = 'Performance Bar Chart';
const REDUX_ACTION = 'leadsAnalytics/fetchPerformanceChartData';

const Dashboard = () => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const [chartData, setChartData] = useState([]);
  const filters = useSelector((state: DefaultRootState) => PreferencesSelectors.selectFilters(state, FILTER_PLACES.LEAD_PERFORMANCE_REPORT))
  const isPreferencesInitialized = useSelector(PreferencesSelectors.isPreferencesListInitialized)

  const {metrics, group_by} = filters

  const {leadsReportData, loading, date} = useSelector((state: DefaultRootState) => ({
    leadsReportData: LeadAnalyticsSelectors.selectPerformanceChartData(state),
    loading: state.loading.effects[REDUX_ACTION],
    date: selectDateFilter(state),
  }));
  const dispatch = useDispatch();
  const intl = useIntl();

  const processData = () => {
    if (metrics.length === 0) return;
    const data = transformLeadReportDataToBarChartFormat(
      leadsReportData,
      group_by,
      metrics,
      intl,
    );
    setChartData(data);
  };
  useEffect(() => {
    if (leadsReportData?.length && leadsReportData[0]?.label) {
      processData();
    }
  }, [JSON.stringify(leadsReportData)]);

  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
    });
  }, []);
  useEffect(() => {
    if (!isPreferencesInitialized) return

    fetchData();
  }, [JSON.stringify(date), JSON.stringify(filters), isPreferencesInitialized]);

  const fetchData = () => {
    dispatch({
      type: REDUX_ACTION,
    });
  };

  const config = {
    data: chartData ?? [],
    isGroup: true,
    xField: group_by,
    yField: 'value',
    seriesField: 'category',
    legend: false,
    color: ({category}) => METRIC_OPTIONS.find((m) => m.label === category)?.color,

    label: {
      position: 'middle',
      layout: [
        {type: 'interval-adjust-position'},
        {type: 'interval-hide-overlap'},
        {type: 'adjust-color'},
      ],
    },
    interactions: [{type: 'element-active'}],
  };

  const handleMetricsChange = (value) => {
    dispatch({
      type: 'preferences/changeFilters',
      payload: {
        metrics: value,
        place: FILTER_PLACES.LEAD_PERFORMANCE_REPORT
      }
    })
  }
  const handleXAxisChange = (xAxis) => {
    dispatch({
      type: 'preferences/changeFilters',
      payload: {
        group_by: xAxis,
        place: FILTER_PLACES.LEAD_PERFORMANCE_REPORT
      }
    })
  }
  const handleExport = () => {
    const headers = [group_by, 'category', 'value'];
    exportPerfomanceDataToCSV(
      headers,
      chartData,
      `leads-data-${date.start_date}_${date.end_date}.csv`,
    );
  };

  const tagRender = (props) => {
    const {label, value, closable, onClose} = props;
    const handleClose = () => {
      if (metrics.length > 1) {
        onClose();
      }
    };
    return (
      <Tag
        color={METRIC_OPTIONS.find((m) => m.value === value)?.color}
        closable={closable && metrics.length > 1} // Prevent closing the last tag
        onClose={handleClose}
        style={{marginRight: 3}}
      >
        {label}
      </Tag>
    );
  };

  return (
    <MyPageContainer
      label={TRACKING_LABEL}
      extraProps={{
        hasRangePicker: true,
      }}
    >
      <Card
        title={intl.formatMessage({
          id: 'pages.leads.filters',
          defaultMessage: 'Filters',
        })}
        style={{marginBottom: 16}}
      >
        <Space wrap>
          <LeadAssigneeDropdownFilters filterMode={FILTER_PLACES.LEAD_PERFORMANCE_REPORT}/>
          <BranchDropdownFilters filterMode={FILTER_PLACES.LEAD_PERFORMANCE_REPORT}/>
          {/*<LeadMarketingChannelDropdownFilters filterMode={FILTER_PLACES.LEAD_PERFORMANCE_REPORT}/>*/}
          <LeadSourceDropdownFilters filterMode={FILTER_PLACES.LEAD_PERFORMANCE_REPORT}/>
        </Space>
      </Card>
      <PerformanceChartToolbar
        metrics={metrics}
        onMetricsChange={handleMetricsChange}
        tagRender={tagRender}
        xAxis={group_by}
        onXAxisChange={handleXAxisChange}
        handleExport={handleExport}
        columnOptions={METRIC_OPTIONS}
        xAxisOptions={GROUP_BY_OPTIONS}
      />

      <Card style={{marginBottom: 16}}>
        <Column loading={loading} {...config} />
      </Card>
      <PerformanceTable
        data={Array.isArray(leadsReportData) ? leadsReportData : []}
        loading={loading}
      />
    </MyPageContainer>
  );
};

export default Dashboard;

import React, {useEffect, useState} from 'react';
import {CloudUploadOutlined, LoadingOutlined,} from "@ant-design/icons";
import {Button, Col, Form, Input, Row, Spin, Upload,} from "antd";
import {ProFormSelect} from "@ant-design/pro-form";
import {FormattedMessage as F, useIntl, useSelector} from 'umi';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import LeadPriorityDropdownFilters from '@/components/Filters/LeadPriorityDropdownFilters';
import isEmpty from 'lodash/isEmpty';
import LeadStateDropdownFilter from "@/components/Filters/LeadStateFilters";


const CSVLeadSourceImportSegment = ({
  isNew,
  handleFileUpload,
  loadingState,
  isUploading,
  onValuesChange,
  onClose,
  allLeadSourceData,
  file,
  nextStep
}) => {
  const [form] = Form.useForm();
  const { userBranches } = useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { leadSourceDetail, detailLoading } = useSelector((state: DefaultRootState) => ({
    leadSourceDetail: state.leadSources.leadSourceDetail?.lead_source,
    detailLoading: state.loading.effects['leadSources/fetchLeadSource'],
  }));
  const intl = useIntl();
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const beforeUpload = (file) => {
    setUploadedFile(file);
    handleFileUpload(file);
    return false; // Prevent default upload behavior
  };
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      nextStep();
    });
  };
  useEffect(() => {
    setUploadedFile(file);
    if (!isNew && !isEmpty(leadSourceDetail)) {
      onValuesChange(leadSourceDetail);
      form.setFieldsValue(leadSourceDetail);
    }
    if(isNew){
      !isEmpty(allLeadSourceData) && form.setFieldsValue(allLeadSourceData);
    }
  }, [isNew, leadSourceDetail, form]);

  return (
    <Form disabled={detailLoading} form={form} layout="vertical" onValuesChange={onValuesChange}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item
            name="name"
            label={intl.formatMessage({ id: 'pages.leadsources.name', defaultMessage: 'Name' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'pages.leadsources.namePlaceholder',
                defaultMessage: 'Enter the name of the source',
              })}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="incoming_lead_state_id"
            label={intl.formatMessage({
              id: 'pages.leadsources.csv.entryState',
              defaultMessage: 'Entry State',
            })}
            rules={[{ required: true }]}
          >
            <LeadStateDropdownFilter filterMode={'select'} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item
            name="branch_id"
            label={intl.formatMessage({ id: 'pages.leadsources.branch', defaultMessage: 'Branch' })}
          >
            <ProFormSelect
              options={userBranches?.map((branch) => ({
                value: branch.id,
                label: branch.name,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="assignee_id"
            label={intl.formatMessage({ id: 'pages.leadsources.assignedTo' })}
          >
            <LeadAssigneeDropdownFilters filterMode={'select'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item
            name="priority"
            label={intl.formatMessage({ id: 'pages.leadsources.priority' })}
          >
            <LeadPriorityDropdownFilters filterMode={'select'} />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>

      {/* Upload Box */}
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Form.Item initialValue={[file] ?? []} name="file" rules={[{ required: true }]}>
            <Upload.Dragger
              accept=".csv"
              style={{ padding: '24px', borderRadius: '8px' }}
              beforeUpload={beforeUpload}
              fileList={uploadedFile ? [uploadedFile] : []} // Ensure fileList is an array and not undefined
              showUploadList={{ showRemoveIcon: true }}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined style={{ fontSize: 48, color: '#5CC6BA' }} />
              </p>
              <p className="ant-upload-text">
                <F id="pages.leadsources.csv.dropFile" />
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Col>
      </Row>

      {/* Loading Spinner */}
      {isUploading && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <p style={{ marginTop: 10 }}>{loadingState}</p>
        </Row>
      )}
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
          <F id="pages.users.cancel" defaultMessage="Cancel" />
        </Button>
        <Button type="primary" onClick={handleSubmit} htmlType="submit">
          <F id="pages.common.next" defaultMessage="Next"/>
        </Button>
      </Row>
    </Form>
  );
};

export default CSVLeadSourceImportSegment;

import React from "react";
import {WindowsFilled} from "@ant-design/icons";

const HotkeyHint = ({hotkey}: { hotkey: string }) => {
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  if (isTouchDevice) {
    return null; // No hotkey hint for touch devices (Mobile + tablet)
  }
  hotkey = hotkey.toUpperCase();
  const userAgent = window.navigator.userAgent;

  if (userAgent.includes('Mac')) {
    return (
      <>
        ⌘ + {hotkey}
      </>
    );
  }

  // Default to Windows hotkey if OS cannot be detected or windows
  return (
    <>
      <WindowsFilled/> + {hotkey}
    </>
  );
};
export default HotkeyHint

import {LeadModel} from "@/typings/models/Lead";
import EditableTextField from "@/components/Lead/EditableTextField";

const LeadNote = ({lead}: {lead: LeadModel}) => {
  const {customer} = lead
  return <EditableTextField textarea lead={lead} property={'customer.notes'}>
    {customer.notes || " - "}
  </EditableTextField>
}

export default LeadNote

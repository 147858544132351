import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {GetRecentServiceRequestsRequest} from "@/typings/api/getRecentServiceRequest";
import {GetCustomersRequest} from "@/typings/api/getCustomers";

/** Fetch Recent Service Requests */

type FetchRecentServiceRequestsOptions = {
  params: GetRecentServiceRequestsRequest["parameters"]
  url?: string
  query?: GetRecentServiceRequestsRequest["query"]
}
export function fetchRecentServiceRequests({ params, query, url }: FetchRecentServiceRequestsOptions ) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_RECENT_SERVICE_REQUEST_API_PATH, {
    params,
    queryString: query
  })

  return request<GetRecentServiceRequestsRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}


export function fetchCalendarViewServiceRequests({
                                         query = {}
                                       }) {
  const url = URLParamBuilder(PATHS.GET_SERVICE_REQUESTS, {
    queryString: query,
  })
  return request<GetCustomersRequest["response"]>(url, {
    method: 'GET'
  });
}


export function rescheduleServiceRequestRequest({
                                                  params,
                                                  data = {}
                                                }) {
  const url = URLParamBuilder(PATHS.RESCHEDULE_SERVICE_REQUEST, {
    params
  })
  return request(url, {
    method: 'POST',
    data
  });
}


export function bookServiceRequestRequest({
                                                  params,
                                                  data = {}
                                                }) {
  const url = URLParamBuilder(PATHS.BOOK_APPOINTMENT, {
    params
  })
  return request(url, {
    method: 'POST',
    data
  });
}

import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";


export function fetchLeadStates() {
  const url = URLParamBuilder(PATHS.LEAD_STATES)
  return request(url, {
    method: 'GET'
  });
}


export function fetchAppointmentEvents() {
  const url = URLParamBuilder(PATHS.LEAD_STATE_APPOINTMENT_EVENTS)
  return request(url, {
    method: 'GET'
  });
}



export function updateAppointmentEventsRequest({data}) {
  const url = URLParamBuilder(PATHS.LEAD_STATE_APPOINTMENT_EVENTS)
  return request(url, {
    method: 'PATCH',
    data
  });
}


export function createLeadStateRequest({
                                          data = {}
                                        }) {
  return request(PATHS.LEAD_STATES, {
    method: 'POST',
    data
  });
}

export function editLeadStateRequest({
                                        url,
                                        params,
                                        data = {}
                                      }) {
  const finalUrl = url || URLParamBuilder(PATHS.CHANGE_LEAD_STATE, {
    params
  })
  return request(finalUrl, {
    method: 'PUT',
    absoluteURL: url,
    data
  });
}

export function reorderLeadStateRequest({
                                           data = {}
                                         }) {
  return request(PATHS.REORDER_LEAD_STATE, {
    method: 'POST',
    data
  });
}

export function deleteLeadStateRequest({
                                          params = {}
                                        }) {
  return request(URLParamBuilder(PATHS.CHANGE_LEAD_STATE, {
    params
  }), {
    method: 'DELETE'
  });
}

export function addLeadStateRequest({
                                       url,
                                       data = {}
                                     }) {
  const finalUrl = url || URLParamBuilder(PATHS.LEAD_STATES)
  return request(finalUrl, {
    method: 'PUT',
    absoluteURL: url,
    data
  });
}

import React from 'react';
import MyPageContainer from "@/components/MyPageContainer";
import {Card} from "antd";
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import LeadsCalendar from "@/components/Kanban/ServiceRequests/LeadsCalendar";
import {useDispatch, useSelector} from "umi";
import * as ServiceRequestSelectors from "@/selectors/serviceRequestSelectors";

const Calendar: React.FC = () => {

  const dispatch = useDispatch()
  const calendarViewServiceRequests = useSelector(ServiceRequestSelectors.selectCalendarView)
  const loading = useSelector((state: DefaultRootState) => state.loading.effects['leads/fetchCalendarServiceRequest'])
  const fetchCalendarData = () => {
    dispatch({
      type: 'serviceRequest/fetchCalendarView'
    })
  }
  return (
    <MyPageContainer extraProps={{topActions: [<LeadTasksOverview/>]}}>
      <Card>
        <LeadsCalendar loading={loading} list={calendarViewServiceRequests} request={fetchCalendarData}/>
      </Card>

    </MyPageContainer>
  );
};

export default Calendar

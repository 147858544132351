import {Form} from "antd";
import classNames from "classnames";

import {EditableContext} from "./EditableContext";
import styles from "./index.less"

interface EditableRowProps {
  index: number;
  className?: string
}
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} className={classNames(props.className, styles.editableRow)} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow

import React from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";


export interface CardHelpTooltipProps {
  title: string | React.ReactNode
}

const CardHelpTooltip: React.FC<CardHelpTooltipProps> =
  ({title}) => {
    return <Tooltip  title={title} placement={"topRight"}>
      <QuestionCircleOutlined />
    </Tooltip>
  };

export default (CardHelpTooltip)

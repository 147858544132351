import React from "react";

import {useSelector} from "umi";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import {F} from "@/utils/intl";
import * as JourneySelectors from "@/selectors/journeySelectors";

const LeadSourceDropdownFilters = ({ onSelectionChange, ...props }: any) => {
  // Lead sources are the current journeys
  const leadSources = useSelector(JourneySelectors.selectJourneys);

  const handleSelectionChange = (selectedValues) => {
    if (onSelectionChange)
      onSelectionChange(selectedValues);
  };

  return (
    <LeadDropdownFilter
      placeholder={<F id={"pages.leads.filters.leadSource.placeholder"} defaultMessage={'Source'}/>}
      filterKey={'lead_source'}
      options={leadSources}
      onChange={handleSelectionChange}
      {...props}
    />
  );
};

export default LeadSourceDropdownFilters;

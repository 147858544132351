import React from 'react';
import {Space, Tooltip} from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {getLanguageFromUmiLocale} from "@/utils/locale";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.locale(getLanguageFromUmiLocale())

export const PrettyDate = ({ date }: {date: string}) => {

  // Format the date to be relative (e.g., "2 days ago")
  const formattedDate = dayjs(date).fromNow();

  // Optionally, show a tooltip with the exact date
  const exactDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return (
    <Tooltip title={exactDate}>
      <span>{formattedDate}</span>
    </Tooltip>
  );
};
export const FormatDate = ({ format, date, onlyDate, onlyTime }: {date: string, format?: string, onlyDate?: boolean, onlyTime?: boolean}) => {
  if (!format) {
    format = 'YYYY-MM-DD HH:mm'
    if (onlyTime) format = 'HH:mm'
    if (onlyDate) format = 'YYYY-MM-DD'
  }
  return <span>{dayjs(date).format(format)}</span>
};

export const PrettyDateWithTime = ({ date }: {date: string}) => {

  // Format the date to be relative (e.g., "2 days ago")
  const formattedDate = dayjs(date).fromNow();
  const formattedTime = dayjs(date).format('HH:mm')
  // Optionally, show a tooltip with the exact date
  const exactDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return (
    <Tooltip title={exactDate}>
      <Space>
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </Space>
    </Tooltip>
  );
};

export const PrettyDuration = ({start, end}) => {
 const startDate = dayjs(start)
 const endDate = dayjs(end)

 const duration = dayjs.duration(endDate.diff(startDate));

 return duration.hours() + ' h'
}

export const PrettyHour = ({date}: {date: string}) => {
  const dateObject = dayjs(date)
  return dateObject.format("HH:mm")
}
export default PrettyDate;

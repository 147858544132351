import { message } from "antd";
import { DELIMITER_OPTIONS } from "./constants";

interface CsvRow {
  [key: string]: string;
}

interface Mapping {
  [key: string]: string[];
}

interface MappedRow {
  [key: string]: string | MappedRow;
  meta?: {
    [key: string]: string;
  };
}

interface MappedData {
  flat: MappedRow[];
  nested: MappedRow[];
}

interface Field {
  key: string;
  label: string;
  name?: string;
  required?: boolean;
  tooltip?: string;
}

export const detectDelimiter = (csvData: string): string => {
  const firstLine = csvData.split('\n')[0];
  const delimiters = DELIMITER_OPTIONS.map((option) => option.value);
  const delimiterCounts = delimiters.map((delimiter) => ({
    delimiter,
    count: (firstLine.match(new RegExp(`\\${delimiter}`, 'g')) || []).length,
  }));
  delimiterCounts.sort((a, b) => b.count - a.count);
  return delimiterCounts[0].delimiter;
};

export const convertFlatToNested = (flatObject: CsvRow): MappedRow => {
  const nestedObject: MappedRow = {};

  Object.keys(flatObject).forEach((key) => {
    const keys = key.split('.');
    keys.reduce((acc, part, index) => {
      if (index === keys.length - 1) {
        acc[part] = flatObject[key];
      } else {
        acc[part] = acc[part] || {};
      }
      return acc[part] as MappedRow;
    }, nestedObject);
  });

  return nestedObject;
};

const mapSystemFields = (row: CsvRow, mapping: Mapping): MappedRow => {
  const mappedRow: MappedRow = {};
  for (const [systemField, csvHeaders] of Object.entries(mapping)) {
    mappedRow[systemField] = csvHeaders?.map(header => row[header]).join(", ");
  }
  return mappedRow;
};

const mapMetaFields = (row: CsvRow, mapping: Mapping): { [key: string]: string } => {
  const meta: { [key: string]: string } = {};
  for (const [customField, csvHeaders] of Object.entries(mapping)) {
    if (customField.includes("meta.")) {
      meta[customField.split("meta.")[1]] = csvHeaders?.map(header => row[header]).join(", ");
    }
  }
  return meta;
};

export const buildMappedData = async (values: Mapping, parsedData: CsvRow[], intl = null): Promise<MappedData | undefined> => {
  try {
    const mapping = values;

    const newMappedData = parsedData.map((row) => {
      const mappedRow = mapSystemFields(row, mapping);
      mappedRow.meta = mapMetaFields(row, mapping);
      return mappedRow;
    });

    const newMappedNestedData = newMappedData.map((row) => convertFlatToNested(row));

    return { flat: newMappedData, nested: newMappedNestedData };
  } catch (error) {
    console.error("Error during mapping:", error);
    intl && message.error(intl.formatMessage({
      id: 'pages.leadsource.csv.parseError',
      defaultMessage: "Failed to map CSV data.",
    }));
  }
};

export const getDefaultFields = (fields: Field[]): { title: string; dataIndex: string; key: string }[] => {
  return fields.map((field) => ({
    title: field.label,
    dataIndex: field.key,
    key: field.key,
  }));
};

export const getMetaFields = (fields: Field[]): { title: string; dataIndex: string[]; key: string }[] => {
  return fields.map((field) => ({
    title: field.name ?? field.label ?? field.key,
    dataIndex: ['meta', field.name ?? field.key],
    key: `custom-${field.key}`,
  }));
};

export const transformObject = (inputObject: Mapping): { target_field: string; sources: string[] }[] => {
  const resultArray = Object.keys(inputObject)
    .filter((key) => inputObject[key] && inputObject[key]?.length)
    .map((key) => {
      return {
        target_field: key,
        sources: inputObject[key],
      };
    });
  return resultArray;
};

export const transformFieldName = (fieldName: string): string => {
  return fieldName.replace(/\./g, ' ').replace(/leads/gi, 'record');
};

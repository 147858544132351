import React from 'react';
import {Table} from 'antd';
import isEmpty from 'lodash/isEmpty';

type DataType = {
  label: string;
  values: {
    label: string;
    value: number;
  }[][];
};

type Props = {
  dataSource: DataType[];
  selectedBreakdownData: string;
};
const createDataSourceWithoutBreakdown = (data: any): DataType[] => {
  if(isEmpty(data)) return [];
  let dataSource: DataType[] = [];
  Object.keys(data ?? {}).forEach((key: any) => {
    dataSource = [...dataSource, { label: key, values: data[key] ?? [] }];
  });
  return dataSource;
};

const createDataSourceWithBreakdown = (data: any): DataType[] => {
  if(isEmpty(data)) return [];
  let dataSource: DataType[] = [];
  Object.keys(data ?? {}).forEach((key: any) => {
    dataSource = [
      ...dataSource,
      {
        label: key,
        values: [
          Object.keys(data[key] ?? {}).map((subValue) => {
            return { label: subValue, value: data[key][subValue] ?? 0 };
          }),
        ],
      },
    ];
  });
  return dataSource;
};

const FunnelDataTable: React.FC<Props> = ({ data, selectedBreakdownData, loading }) => {

  let dataSource = [];
  if(!isEmpty(data))
   dataSource = selectedBreakdownData
     ? createDataSourceWithBreakdown(data) || []
     : createDataSourceWithoutBreakdown(data) || [];
  const columns = [
    {
      title: 'Step',
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.label.localeCompare(b.label),
      filters: dataSource?.map((item) => ({ text: item.label, value: item.label })),
      onFilter: (value, record) => record.label.includes(value),
    },
    ...(selectedBreakdownData && dataSource.length > 0 && dataSource[0].values.length > 0
      ? dataSource[0].values[0].map((item) => ({
          title: item.label,
          dataIndex: 'values',
          key: item.label,
          render: (values) => {
            const valueObj = values?.[0]?.find((v) => v.label === item.label);
            return valueObj ? valueObj.value : '-';
          },
          sorter: (a, b) => {
            const aValue = a.values?.[0]?.find((v) => v.label === item.label)?.value || 0;
            const bValue = b.values?.[0]?.find((v) => v.label === item.label)?.value || 0;
            return aValue - bValue;
          },
        })) || []
      : [
          {
            title: '',
            dataIndex: 'values',
            key: 'values',
            sorter: (a, b) => a.values - b.values,
          },
        ]),
  ];

  return <Table size={"small"} loading={loading} columns={columns} dataSource={dataSource} rowKey="label" />;
};

export default FunnelDataTable;

import React, {useState} from 'react';
import dayjs from "dayjs";
import {Col, Divider, Row, Space} from "antd";
import {NavLink, useDispatch} from "umi";

import MyPageContainer from "@/components/MyPageContainer";
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import {TasksTable} from "@/pages/Tasks/TasksTable";
import {LeadsServiceRequestsTable} from "@/components/Kanban/ServiceRequests/LeadsServiceRequestsTable";
import {F} from "@/utils/intl";
import {fetchLeadTasksList} from "@/services/api/leadTask";
import {fetchCalendarViewServiceRequests} from "@/services/api/serviceRequest";

const TodaySalesAgentView: React.FC = () => {

  const [taskList, setTaskList] = useState([])
  const [taskListForTomorrow, setTaskListForTomorrow] = useState([])
  const [serviceRequestList, setServiceRequestList] = useState([])
  const [serviceRequestListForTomorrow, setServiceRequestListForTomorrow] = useState([])
  const [loading, setLoading] = useState(false)
  const [serviceRequestsLoading, setServiceRequestsLoading] = useState(false)
  const dispatch = useDispatch()
  const fetchTaskList = () => {
    setLoading(true)
    const today = dayjs().format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: today,
      }
    }).then(response => {
      setLoading(false)
      setTaskList(response.data.data)
    })
  }

  const fetchTaskListForTomorrow = () => {
    setLoading(true)
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    fetchLeadTasksList({
      query: {
        due_to: tomorrow,
        due_from: tomorrow,
      }
    }).then(response => {
      setLoading(false)
      setTaskListForTomorrow(response.data.data)
    })
  }

  const fetchServiceRequestsList = () => {
    const today = dayjs().format("YYYY-MM-DD")
    setServiceRequestsLoading(true)
    fetchCalendarViewServiceRequests({
      query: {
        start_date: today,
        end_date: today,
        status: 'confirmed,tentative',
        all: 1
      }
    }).then((response) => {
      const serviceRequests = response?.data?.data || []
      setServiceRequestList(serviceRequests)
      dispatch({
        type: 'leads/saveLeadsData',
        payload: {
          list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
        }
      })
      setServiceRequestsLoading(false)
    })
  }

  const fetchServiceRequestsListForTomorrow = () => {
    const tomorrow = dayjs().add(1, 'day').format("YYYY-MM-DD")
    setServiceRequestsLoading(true)
    fetchCalendarViewServiceRequests({
      query: {
        start_date: tomorrow,
        end_date: tomorrow,
        status: 'confirmed,tentative',
        all: 1
      }
    }).then((response) => {
      const serviceRequests = response?.data?.data || []
      setServiceRequestListForTomorrow(serviceRequests)
      dispatch({
        type: 'leads/saveLeadsData',
        payload: {
          list: serviceRequests.map(serviceRequest => serviceRequest?.lead).filter(lead => lead)
        }
      })
      setServiceRequestsLoading(false)
    })
  }
  return (
    <MyPageContainer extraProps={{topActions: [<LeadTasksOverview/>]}}>
      <Row gutter={12}>
        <Col xl={8}>
          <Divider><Space>
            <span><F id={'pages.leads.todayView.appointment'} defaultMessage={'Appointments'}/></span>
            <NavLink to={'/calendar'}><F id={'pages.leads.todayView.goToCalendar'}
                                         defaultMessage={'Go to Calendar'}/></NavLink>
          </Space></Divider>
          <LeadsServiceRequestsTable loading={serviceRequestsLoading} request={fetchServiceRequestsList}
                                     data={serviceRequestList}/>
        </Col>
        <Col xl={16}>
          <Divider><F id={'pages.lead.drawer.tasks'} defaultMessage={'Tasks'}/></Divider>
          <TasksTable hideCreate hideShowDone data={taskList} loading={loading} request={fetchTaskList}/>
        </Col>
      </Row>
      <Divider><h2><F id={'pages.leads.todayView.tomorrow'} defaultMessage={'Tomorrow'}/></h2></Divider>
      <Row gutter={12}>
        <Col xl={8}>
          <LeadsServiceRequestsTable loading={serviceRequestsLoading} request={fetchServiceRequestsListForTomorrow}
                                     data={serviceRequestListForTomorrow}/>
        </Col>
        <Col xl={16}>
          <TasksTable hideCreate hideShowDone data={taskListForTomorrow} loading={loading}
                      request={fetchTaskListForTomorrow}/>
        </Col>
      </Row>
    </MyPageContainer>
  );
};

export default TodaySalesAgentView

import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'umi';
import {Badge, Space} from 'antd';

import LeadDropdownFilter, {ILeadDropdownFilterProps} from '@/components/Filters/LeadDropdownFilter';
import {F} from '@/utils/intl';
import {LeadStateModel} from '@/typings/models/LeadState';
import * as LeadStateSelectors from "@/selectors/leadStateSelectors";
import isEmpty from "lodash/isEmpty";


export const LeadStatePresentation = ({state}: { state: LeadStateModel }) => <Space>
  <Badge color={state.color}/>
  <span>{state.label}</span>
</Space>

const LeadStateDropdownFilter = (props: Partial<ILeadDropdownFilterProps> = {}) => {
  const dispatch = useDispatch()
  const leadStates = useSelector(LeadStateSelectors.selectStates)
  const optionRender = (state: LeadStateModel) => <LeadStatePresentation state={state}/>

  useEffect(() => {
    if (isEmpty(leadStates)) {
      dispatch({
        type: 'leadStates/fetch'
      })
    }
  }, []);

  return <LeadDropdownFilter
    optionRender={optionRender}
    placeholder={<F id={"pages.leads.filters.status.placeholder"} defaultMessage={'Status'}/>}
    filterKey={'lead_state'}
    options={leadStates}
    {...props}/>
}

export default LeadStateDropdownFilter

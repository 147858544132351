import set from "lodash/set";
import omit from "lodash/omit";
import get from "lodash/get";

import {processAPIResponse} from "@/utils/request";
import {fetchUiPreferencesRequest, setUiPreferencesRequest} from "@/services/api/preferences";

const initialState = {
  list: {},
  initialized: false,
  persistQueue: {}
}
export default {
  namespace: 'preferences',

  state: initialState,

  effects: {
    * fetch({then}, {call, put, select}) {
      const {response, data} = yield call(fetchUiPreferencesRequest);
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'set',
            payload: data
          })
          if (then) then(data)
        },
        * onError(error) {
          if (then) then(null)
        }
      })
    },
    * changeFilters({payload}, {call, put, select}) {
      const newFilter = omit(payload, ['place', 'persist'])
      const place = payload.place
      const key = `ui.${place}__filters`
      yield put({
        type: 'merge',
        payload: {
          key,
          value: newFilter
        }
      })
      if (payload.persist) {
        yield put({
          type: 'persistConfirm',
          payload: {
            key
          }
        })
        return
      }
      yield put({
        type: 'addToPersistQueue',
        payload: {
          key,
          meta: {
            filterPlace: place
          }
        }
      })
    },
    * persistConfirm({payload: {key}}, {call, put, select}) {
      const value = yield select((state: DefaultRootState) => get(state, `preferences.list.${key}`))
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
      yield call(setUiPreferencesRequest, {params: {key: key.split('ui.')[1]}, data: value})

    },
    * persistIgnore({payload: {key}}, {put}) {
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
    },
    * persistReset({payload: {key}}, {put}) {
      yield put({
        type: 'omit',
        payload: {
          key
        }
      })
      yield put({
        type: 'removeFromPersistQueue',
        payload: {
          key
        }
      })
    },
    * update({payload}, {call, put, select}) {
      const {key, value, listKey = 'ui'} = payload
      yield put({
        type: 'modify',
        payload: {
          key: `${listKey}.${key}`, value
        }
      })
      yield call(setUiPreferencesRequest, {params: {key}, data: value})
    }

  },
  reducers: {
    set(state, action) {
      return {
        ...state,
        initialized: true,
        list: {
          ...state.list,
          ...(action.payload || {}),
        },
      };
    },
    modify(state, action) {
      const newList = set(state.list, action.payload.key, action.payload.value);
      return {
        ...state,
        list: newList,
      };
    },
    omit(state, action) {
      return {
        ...state,
        list: omit(state.list, action.payload.key)
      };
    },
    merge(state, action) {
      const newList = set(state.list, action.payload.key, {
        ...get(state.list, action.payload.key, {}),
        ...action.payload.value,
      });
      return {
        ...state,
        list: newList,
      };
    },
    addToPersistQueue(state, action) {
      return {
        ...state,
        persistQueue: {
          ...state.persistQueue,
          [action.payload.key]: {
            time: new Date().getTime(),
            ...(action.payload.meta || {})
          }
        }
      }
    },
    removeFromPersistQueue(state, action) {
      return {
        ...state,
        persistQueue: omit(state.persistQueue, action.payload.key)
      }
    },
    reset() {
      return initialState
    }
  }
};

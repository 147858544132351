import {getIntl} from "umi";

const intl = getIntl()
const AUTOMATION_LIFECYCLE_EVENT_STATE_MAP = {
  APPOINTMENT_BOOKED: 'appointment_booked_lead_state_id',
  APPOINTMENT_CANCELLED: 'appointment_cancelled_lead_state_id',
  APPOINTMENT_DONE: 'appointment_done_lead_state_id',
  APPOINTMENT_REQUESTED: 'appointment_requested_lead_state_id',
  MANUAL: '-1',
}


export const AUTOMATION_EVENTS = [
  {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.MANUAL,
    title: intl.formatMessage({
      id: 'pages.lead.status.lifecycle.manual',
      defaultMessage: 'Manual',
    }),
  },
  {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_REQUESTED,
    title: intl.formatMessage({
      id: 'pages.lead.status.lifecycle.appointmentRequested',
      defaultMessage: 'On Request',
    }),
  },
  {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_BOOKED,
    title: intl.formatMessage({
      id: 'pages.lead.status.lifecycle.appointmentBooked',
      defaultMessage: 'On Booking',
    }),
  },
  {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_CANCELLED,
    title: intl.formatMessage({
      id: 'pages.lead.status.lifecycle.appointmentCancelled',
      defaultMessage: 'On Cancellation',
    }),
  },
  {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_DONE,
    title: intl.formatMessage({
      id: 'pages.lead.status.lifecycle.appointmentDone',
      defaultMessage: 'On Finish',
    }),
  },
];

export const MAGIC_FIELD_TYPES = [
  {label: intl.formatMessage({id: 'pages.leads.status.notRequired', defaultMessage: 'Not required'}), value: 'none'},
  {label: intl.formatMessage({id: 'pages.leads.status.mandatory', defaultMessage: 'Mandatory'}), value: 'mandatory'},
  {label: intl.formatMessage({id: 'pages.leads.status.optional', defaultMessage: 'Optional'}), value: 'optional'},
];


export const LEAD_STATE_TYPES = {
  OPEN: 'OPEN',
  WON: 'WON',
  LOST: 'LOST'
}

export const INITIAL_NEW_STATUS_FORM = {
  color: "#92A8D1",
  label: '',
  state_type: LEAD_STATE_TYPES.OPEN
}

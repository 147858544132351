import React from "react";

import {LeadModel} from "@/typings/models/Lead";

import styles from "./index.less"
import {F} from "@/utils/intl";
import {DatePicker} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import {useDispatch} from "umi";

const LeadAppointmentAt = ({lead, isTable}: { lead: LeadModel, isTable?: boolean }) => {
  const dispatch = useDispatch()
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const handleChange = (value) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        leadId: lead.id,
        appointment_at: value.format("YYYY-MM-DD hh:mm")
      }
    })
  }
  const content = <DatePicker style={{minWidth: 100}} suffixIcon={null} format={value => {
    return value ? dayjs(value).fromNow() : ' - '
  }} size={"small"} bordered={false} showTime={{
    showHour: true,
    showMinute: true,
    showSecond: false
  }}
                              disabledDate={disabledDate}
                              onChange={handleChange}
                              value={lead.appointment_at ? moment(lead.appointment_at) : null}/>

  if (isTable) return content

  return <div className={styles.editableDateWrapper}>
    <div className={styles.tinyDateKey}><F id={"pages.lead.fields.appointmentAt"} defaultMessage={"Appointment at"}/>
    </div>
    <div>{content}</div>
  </div>

}

export default LeadAppointmentAt

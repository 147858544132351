import {request} from "./request";
import * as PATHS from "@/constants/path"
import {ForgotPasswordRequest} from "@/typings/api/forgotPassword";
import {ResetPasswordRequest} from "@/typings/api/resetPassword";
import {URLParamBuilder} from "@/utils/functions";
import {CreateUserRequest, DeleteUserRequest, FetchAllUsersRequest, RolesRequest, UpdateUserSettingRequest, UserSettingRequest} from "@/typings/api/user";
import { API } from "./typings";

/** Login interface | POST /login */
export function loginRequest(body: API.LoginRequestParams, options?: { [key: string]: any }) {
  return request(PATHS.LOGIN_API_PATH, {
    method: 'POST',
    data: {
      Customer: body
    },
    ...(options || {}),
  });
}

export function loginAccessTokenRequest(body: API.LoginParams) {
  return request(PATHS.LOGIN_ACCESS_TOKEN_API_PATH, {
    method: 'POST',
    data: body,
    noAuthHeader: true
  });
}

export function refreshTokenRequest(body: API.RefreshTokenParams) {
  return request(PATHS.LOGIN_REFRESH_TOKEN_API_PATH, {
    method: 'POST',
    data: body,
    noAuthHeader: true
  });
}



export function fetchMeRequest() {
  return request(PATHS.GET_ME_API_PATH, {
    method: 'GET',
    data: {}
  });
}

export function fetchUserSettingsRequest() {
  return request<UserSettingRequest["response"]>(PATHS.GET_SETTINGS_API_PATH, {
    method: 'GET'
  });
}
export function fetchAllUsersRequest( 
  { query, url }) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_ALL_USER_API_PATH, {
      queryString: query,
    },false)
  return request<FetchAllUsersRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}
export function fetchUserBranches( 
  query = {}) {
  const finalUrl = URLParamBuilder(PATHS.GET_USER_BRANCHES_API_PATH, {
      queryString: query,
    },false)
  return request<FetchAllUsersRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}


type ChangeUserSettingsRequestOptions = {
  payload: UpdateUserSettingRequest['payload']
}
export function changeUserSettingsRequest({payload}: ChangeUserSettingsRequestOptions) {
  return request<UpdateUserSettingRequest["response"]>(PATHS.GET_SETTINGS_API_PATH, {
    method: 'POST',
    data: payload
  });
}


type ForgotPasswordRequestOptions = {
  payload: ForgotPasswordRequest["payload"]
}

export function forgotPasswordRequest({payload}: ForgotPasswordRequestOptions) {
  return request<ForgotPasswordRequest["response"]>(PATHS.FORGOT_PASSWORD_API_PATH, {
    method: 'POST',
    data: payload,
  });
}


type ResetPasswordRequestOptions = {
  payload: ResetPasswordRequest["payload"],
  params: ResetPasswordRequest["parameters"],
}

export function resetPasswordRequest({payload, params}: ResetPasswordRequestOptions) {
  const url = URLParamBuilder(PATHS.RESET_PASSWORD_API_PATH, {
    params
  })
  return request<ResetPasswordRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}

type CreateUserRequestOptions = {
  payload: CreateUserRequest["payload"]
}
export function createUserRequest({ payload }: CreateUserRequestOptions) {
  const url = PATHS.CREATE_USER_API_PATH
  return request<CreateUserRequest["response"]>(url, {
    method: 'POST',
    data: payload,
  });
}
type DeleteUserOptions = {
  params: DeleteUserRequest["parameters"]
}
export function deleteUserRequest({params}: DeleteUserOptions) {
  const url = URLParamBuilder(PATHS.DELETE_USER_API_PATH, {
    params
  })
  return request<DeleteUserRequest["response"]>(url, {
    method: 'DELETE'
  });
}
export function fetchConversionalRolesRequest() {
  return request<RolesRequest["response"]>(PATHS.GET_ROLES_API_PATH, {
    method: 'GET'
  });
}
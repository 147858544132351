import {transformObjectToLocaleMap} from '@/utils/intl';

export default {
  // region Login Page
  'pages.login.welcome': 'Willkommen! Melden Sie sich an',
  'pages.layouts.userLayout.title': 'Starten Sie Ihr Küchenverkaufsgespräch online',
  'pages.login.username.placeholder': 'E-Mail Adresse',
  'pages.login.password.placeholder': 'Passwort',
  'pages.login.rememberMe': 'Angemeldet bleiben',
  'pages.login.forgotPassword': 'Passwort vergessen?',
  'pages.login.dontHaveAnAccount': 'Noch kein Account?',
  'pages.login.requestDemo': 'Demo termine',
  'pages.login.submit': 'Login',
  'pages.login.password.required': 'Bitte geben Sie Ihr Passwort ein.',
  'pages.login.failure': 'Login fehlgeschlagen, bitte versuchen Sie es erneut.',
  'pages.login.accountLogin.errorMessage': 'Fallscher Nutzername/Passwort',
  'pages.login.username.required': 'Bitte geben Sie Ihren Nutzernamen ein.',
  'pages.login.success': 'Login erfolgreich!',
  'pages.login.loginTitle': 'Willkommen! Loggen Sie sich ein',
  'pages.login.dontHaveAccount': 'Noch keinen Account?',
  'app.footer.imprint': 'Impressum',
  'app.footer.policy': 'Richtlinie',
  'pages.login.email.required': 'Bitte geben Sie Ihre E-Mail Adresse ein',

  // endregion

  // region Reset Password Page
  'pages.resetPassword.setNewPassword': 'Neues Passwort festlegen',
  'pages.resetPassword.placeholder': 'Passwort',
  'pages.resetPassword.placeholderConfirm': 'Passwort bestätigen',
  'pages.resetPassword.passwordRequired': 'Bitte Passwort eingeben',
  'pages.resetPassword.backToLogin': 'Zurück zum Login',
  'pages.resetPassword.title': 'Setzen Sie ein neues Passwort',
  'pages.resetPassword.subTitle': 'Geben Sie ein neues Passwort ein',
  'pages.resetPassword.errorMessage':
    'Ihr Passwort muss aus mindestens 8 Zeichen bestehen, einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen.',
  'pages.resetPassword.wrongConfirmation':
    'Die beiden von Ihnen eingegebenen Passwörter stimmen nicht überein',
  'pages.forgotPassword.hint':
    'An welche E-Mail Adresse sollen die Passwort-Zurücksetzen Informationen gehen?',
  'pages.forgotPassword.forgotPasswordTitle': 'Passwort zurücksetzen',
  'pages.forgotpassword.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail an.',
  // endregion

  // region Forgot Password Page
  'pages.forgotPassword.label': 'E-Mail Adresse',
  'pages.forgotPassword.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail an.',
  'pages.forgotPassword.placeholder': 'E-Mail Adresse',
  'pages.forgotPassword.tooltip':
    'Wenn Sie Ihre E-Mail Adresse vergessen haben, kontaktieren Sie uns bitte über support@conversional.de',
  'pages.forgotPassword.errorMessage':
    'Es ist ein Fehler mit Ihrer E-Mail Adresse aufgetreten, bitte schreiben Sie uns über die Kontaktseite.',
  'pages.forgotPassword.invalid':
    'Ihre eingegebene E-Mail ist nicht registriert, bitte geben Sie eine gültige Adresse ein.',
  'pages.forgotPassword.submit': 'Termin senden',
  'pages.forgotPassword.login': 'Zurück zum Login',
  'pages.forgotPassword.guidText':
    'An welche E-Mail Adresse sollen die Passwort-Zurücksetzen Informationen gehen?',
  'pages.forgotPassword.sentMail': 'E-Mail zum Zurücksetzen des Passworts erfolgreich gesendet.',
  'pages.forgotPassword.sentMailSubtitle': 'Bitte überprüfen Sie auch Ihren Spam-Ordner.',
  // endregion

  // region Dashboard Page
  'pages.dashboard.header': 'Dashboard',
  // endregion

  // region Marketing Page
  'pages.marketing.header': 'Marketing',
  'pages.marketing.noChart': 'Kein Chart vorhanden. Ändern Sie den Datumsfilter',
  // endregion

  // region Performance Page
  'pages.performance.header': 'Performance',
  'pages.performance.alertMessage': 'Dies ist die Performance Seite',
  'pages.performance.cardTitle': 'Performance Funnel',
  'pages.performance.instantAppointmentPath': 'Terminpfad',
  'pages.performance.inspirationPath': 'Inspirationspfad',
  'pages.performance.infoText':
    'Diese Daten basieren auf Tracking-Ereignissen. Durch Tracking-Blocker und Sampling werden dabei nicht alle User erfasst. Es kann zu Abweichungen vom Dashboard kommen.',
  'pages.performance.paths': 'Pfade',
  'pages.performance.explanation':
    'Pfade werden aus dem Fragebogen heraus gestartet. Ihre "gestartet" werte beziehen sich auf den Pfad, nicht auf die Tool-Starts. Daher sind die Starts aller Pfade geringer als die Tool-Starts, wenn Nutzer im ersten Schritt abbrechen.',
  // endregion

  // region Customers Page
  'pages.customers.header': 'Kunden',
  'pages.customers.tableTitle': 'Kunden',
  'pages.customers.engagementScore': 'Engagement-Bewertung',
  'pages.customers.scoreExplanation':
    'Wahrscheinlichkeit, dass der Kunde bei Ihnen kauft basierend auf allen bisherigen Interaktionen.',
  'pages.customers.name': 'Name',
  'pages.customers.gender': 'Geschlecht',
  'pages.customers.email': 'E-Mail',
  'pages.customers.utmMedium': 'UTM Medium',
  'pages.customers.status': 'Status',
  'pages.customers.avtiveRecipient': 'Aktiver Empfänger',
  'pages.customers.doubleOptin': 'Double opt-in',
  'pages.customers.createDate': 'Erstellungsdatum',
  'pages.customers.actions': 'Aktionen',
  'pages.customers.details': 'Details',
  'pages.customers.actions.download': 'Als CSV herunterladen',
  'pages.customers.profile': 'Profil',
  'pages.customers.firstName': 'Vorname',
  'pages.customers.lastName': 'Nachname',
  'pages.customers.styles': 'Vorlieben',
  'pages.customers.qualification': 'Qualifizierung',
  'pages.customers.phone': 'Telefon',
  'pages.customers.activeRecipient': 'Aktiver Empfänger',
  'pages.customer.unsubscribe': 'Abmelden',
  'pages.customer.qualification': 'Qualifizierung',
  'pages.customer.state': 'Status',
  'pages.customer.orderVolume': 'Order Volumen',
  'pages.customer.notes': 'Notizen',
  'pages.customer.results': 'Ergebnisse',
  'pages.customer.uploadedFiles': 'Hochgeladene Dateien',
  'pages.customer.noUploadedFiles': 'Keine Hochgeladenen Dateien',
  // endregion

  // region Requests Page
  'pages.requests.header': 'Kunden',
  'pages.requests.tableTitle': 'Termine',
  'pages.requests.customer': 'Kunde',
  'pages.requests.appointmentRequest': 'Terminanfrage',
  'pages.requests.what': 'Was',
  'pages.requests.dateOfRequest': 'Termindatum',
  'pages.requests.where': 'Wo',
  'pages.requests.actions': 'Aktionen',
  // endregion

  // region Locations page
  'pages.locations.header': 'Standorte',
  'pages.locations.alertMessage': 'Dies ist die Standortseite',
  'pages.locations.location': 'Standort',
  'pages.locations.address': 'Adresse',
  'pages.locations.videoCall': 'Videoanruf',
  'pages.locations.created': 'Erstellungsdatum',
  'pages.locations.actions': 'Aktionen',
  'pages.locations.details': 'Details',
  'pages.location.edit': 'Bearbeiten',
  'pages.locations.availability': 'Öffnungszeiten',
  'pages.locations.blockHours': 'Nichtverfügbarkeit',
  'pages.locations.additionalOpenHours': 'Zusätzliche Öffnungszeiten',
  'pages.locations.from': 'Von',
  'pages.locations.to': 'bis',
  'pages.locations.toHour': 'bis',
  'pages.locations.fromHour': 'Von',
  'pages.location.removeRecurringTime': 'Möchten Sie die wiederkehrenden Zeiten entfernen?',
  'pages.locations.download': 'Als CSV herunterladen',
  'pages.locations.optionalLeadSourceTooltip': 'Optional. Wählen, wenn der Standort in einer bestimmten Journey UI angezeigt werden soll.',

  'pages.locations.appointments': 'Termine',
  'pages.locations.appointment_duration': 'Buchung frühestens in',
  'pages.locations.appointment_duration_tooltip': 'Zeit in Stunden bis zu ersten möglichen Terminbuchung',
  'pages.locations.appointment_duration_placeholder': '48 Stunden',
  'pages.locations.hours': 'Stunden',
  'pages.locations.appointment_available_to': 'Termine mögliche bis in',
  'pages.locations.appointment_available_to_placeholder': '90 Tage',
  'pages.locations.appointment_available_to_tooltip': 'Maximale Zeit in Tagen, die ein Termin im Voraus gebucht werden kann',
  'pages.locations.days': 'Tage',
  'pages.locations.timeslot_increment': 'Abstand für Terminvorschläge',
  'pages.locations.timeslot_increment_tooltip': 'Zeitabstände in Minuten in denen Termine vorgeschlagen werden ',
  'pages.locations.timeslot_increment_placeholder': '60 Minuten',
  'pages.locations.minutes': 'Minuten',
  'pages.locations.appointment_buffer': 'Puffer zwischen Terminen in Minuten',
  'pages.locations.appointment_buffer_tooltip': 'Zeit, die zwischen Terminen freigehalten wird.',
  'pages.locations.appointment_buffer_placeholder': '0 Minuten',

  'pages.common.selectFor': 'Bitte ein Feld wählen für',
  'pages.common.required': 'ist erforderlich',
  'pages.common.add': 'Hinzufügen',
  'pages.common.previous': 'Zurück',
  'pages.common.submit': 'Abschicken',
  'pages.common.preview': 'Vorschau',
  'pages.common.next': 'Weiter',
  'pages.common.remove': 'Verwerfen',
  'pages.common.edit': 'Bearbeiten',
  'pages.common.actions': 'Aktionen',
  'pages.common.clear': 'Abwählen',
  'pages.common.clearAll': 'Alle entfernen',
  'pages.common.save': 'Speichern',
  'pages.common.discard': 'Verwerfen',
  'pages.common.selected': 'Ausgewählt',
  'pages.common.yes': 'ja',
  'pages.common.no': 'nein',
  'pages.common.ok': 'OK',
  'pages.common.cancel': 'Abbrechen',
  'pages.common.tutorial': 'Tutorial',
  'pages.common.confirm': 'Weiter',
  'pages.common.update': 'Aktualisieren',
  'pages.images.deleteSelectedImagesSuccess': 'Bilder wurden gelöscht',
  'pages.location.removeBlockPeriod': 'Möchten Sie die nichtverfügbaren Zeiten entfernen?',
  'pages.location.removeOpenPeriod': 'Sollen diese Öffnungszeiten wirklich entfernt werden?',
  'pages.location.name': 'Name',
  'pages.location.home': 'Zu Hause',
  'pages.location.store': 'Filiale',
  'pages.location.online': 'Online',
  'pages.location.add': 'Hinzufügen',
  'pages.location.type': 'Art',
  'pages.location.type_tooltip':
    'Der Typ eines Standorts beschreibt, welche Art von Termin gerade gebucht wird.',
  'pages.location.address': 'Addresse',
  'pages.location.createDate': 'Erstellungsdatum',
  'pages.location.videoCallEnabled': 'Aktiv',
  'pages.location.videoCallDisabled': 'Inaktiv',
  'pages.location.videoCall': 'Videoanruf',
  'pages.location.details': 'Details',
  'pages.locations.currentRecurring': 'Aktuelle wiederkehrende Zeiten',
  'pages.locations.resources': 'Ressourcen',
  'pages.locations.meta': 'Benutzerdefinierte Felder',
  'pages.locations.resourceCountLimitation':
    'Jeder Standort kann über maximal 5 Ressourcen verfügen. Bitte wenden Sie sich an den Support, falls Sie mehr Ressourcen benötigen.',
  'pages.locations.atLeastOneResource':
    'Jeder Standort muss über wenigstens eine Ressource verfügen.',
  'pages.location.removeResource': 'Sind Sie sicher, dass Sie die Ressource entfernen wollen?',
  'pages.locations.addNewResource': 'Neue Ressource',
  'pages.locations.removeResourceSuccess': 'Ressource wurde entfernt.',
  'pages.locations.removeResourceFailed': 'Ressource konnte nicht entfernt werden.',
  'pages.locations.addNewResourceSuccess': 'Neue Ressource hinzugefügt.',
  'pages.locations.addNewResourceFailed': 'Ressource konnte nicht hinzugefügt werden.',
  'pages.location.resource.name': 'Name',
  'pages.location.resource.email': 'Email',
  'pages.locations.agents': 'Agenten',
  'pages.locations.agentsRequired': 'Bitte wählen Sie mindestens einen Agenten aus!',
  'pages.locations.addNewAgent': 'Neuen Agenten hinzufügen',
  'pages.locations.deleteServiceMessage': 'Sind Sie sicher, dass Sie diesen Dienst entfernen möchten?',
  'pages.locations.deleteService': 'Dienst löschen',
  'pages.locations.createService': 'Dienst erstellen',
  'pages.locations.name': 'Name',

  "pages.locations.branchStatus": "Filialstatus",
  'pages.locations.services': 'Dienstleistungen',
  'pages.locations.deleteBranchMessage': 'Sind Sie sicher, dass Sie diese Filiale entfernen möchten?',
  'pages.locations.addBranch': 'Filiale hinzufügen',

  // Location Removal
  'pages.locations.removeLocationConfirm': 'Den Standort jetzt entfernen?',
  'pages.locations.removeLocationSuccess': 'Der Standort wurde entfernt.',
  'pages.locations.removeLocationFailed': 'Standort entfernen fehlgeschlagen',
  //
  'pages.resource.name': 'Name',
  'pages.resource.surname': 'Nachname',
  'pages.resource.email': 'Email',


  // endregion

  // region Account Settings
  'pages.settings.header': 'Account Einstellungen',
  'pages.settings.profileTab': 'Profil',
  'pages.settings.billingTab': 'Abrechnung',
  'pages.settings.languageTab': 'Sprache',
  'pages.settings.firstName.label': 'Vorname',
  'pages.settings.firstName.required': 'Erforderliches Feld, bitte geben Sie Ihren Vornamen an',
  'pages.settings.firstName.placeholder': 'Vorname',
  'pages.settings.firstName.errorMessage':
    'Ihr Vorname kann keine Zahlen und Symbole enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.lastName.label': 'Nachname',
  'pages.settings.lastName.required': 'Erforderliches Feld, bitte geben Sie Ihren Nachnamen an',
  'pages.settings.lastName.placeholder': 'Nachname',
  'pages.settings.lastName.errorMessage':
    'Ihr Vorname kann keine Zahlen und Symbole enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.email.label': 'E-Mail Adresse',
  'pages.settings.email.required': 'Erforderliches Feld, bitte geben Sie Ihre E-Mail Adresse an',
  'pages.settings.email.placeholder': 'E-Mail Adresse',
  'pages.settings.email.errorMessage':
    'Es ist ein Fehler mit Ihrer E-Mail Adresse aufgetreten, bitte verwenden Sie ein gültiges Format',
  'pages.settings.phone.label': 'Telefon',
  'pages.settings.phone.required': 'Erforderliches Feld, bitte geben Sie Ihre Telefonnummer an',
  'pages.settings.phone.placeholder': 'Telefon',
  'pages.settings.phone.errorMessage':
    'Ihre Telefonnummer kann keine Buchstaben enthalten, bitte korrigieren Sie Ihre Eingabe',
  'pages.settings.address.label': 'Adresse',
  'pages.settings.address.required': 'Erforderliches Feld, bitte geben Sie Ihre Adresse an',
  'pages.settings.address.placeholder': 'Adresse',
  'pages.settings.saveSettings': 'Änderungen speichern',
  // region Account Settings page - Billings tab
  'pages.billings.header': 'Abrechnung',
  'pages.billings.alertMessage': 'Dies ist die Abrechnungsseite',
  'pages.billings.email': 'E-Mail',
  'pages.billings.questionnaire': 'Fragebogen',
  'pages.billings.customer': 'Kunde',
  'pages.billings.journey': 'Journey',
  'pages.billings.event': 'Ereignis',
  'pages.billings.created': 'Erstellt',
  'pages.billings.mode': 'Modus',
  'pages.billings.subtotal': 'Zwischensumme',
  'pages.billings.discount': 'Rabatt',
  'pages.billings.total': 'Gesamt',
  'pages.BillingDetails': 'Abrechnung',
  // endregion
  // region Account Settings page - Language tab
  'pages.language.title': 'Sprache wählen',
  'pages.language.english': 'Englisch',
  'pages.language.german': 'Deutsch',
  'pages.language.french': 'Französisch',
  // endregion
  // endregion

  // region Profile Page
  'pages.profile.firstName': 'Vorname',
  'pages.profile.lastName': 'Nachname',
  'pages.profile.email': 'E-Mail',
  'pages.profile.phone': 'Telefon',
  'pages.profile.address': 'Addresse',
  'pages.profile.saveChanges': 'Änderungen speichern',
  // endregion

  // region Support Page
  'pages.support.header': 'Support',
  'pages.settings.telephone': 'Telefon',
  'pages.settings.email': 'E-Mail',
  'pages.support.telephone': 'Telefon',
  'pages.support.email': 'E-Mail',
  'pages.support.title': 'Kontaktieren Sie unseren technischen Support',
  // endregion

  // region Service Request
  'pages.serviceRequest.customer': 'Kunde',
  'pages.customers.created': 'Erstellt am',
  'pages.serviceRequest.inquiry': 'Termin',
  'pages.serviceRequest.what': 'Was',
  'pages.serviceRequest.when': 'Wann',
  'pages.serviceRequest.where': 'Wo',
  'pages.serviceRequest.qualification': 'Qualifizierung',
  // endregion

  // region Date and Weekdays
  'utils.date.7Days': 'Letzte 7 Tage',
  'utils.date.30Days': 'Letzte 30 Tage',
  'utils.date.90Days': 'Letzte 90 Tage',
  'utils.date.180Days': 'Letzte 180 Tage',
  // endregion

  // region Images Page

  'pages.images.tagGroups': 'Tag-Gruppe',
  'pages.images.createTagGroup': 'Tag-Gruppe erstellen',

  'pages.images.tags': 'Tags',
  'pages.images.removeImageConfirmation':
    'Soll das Bild wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.',
  'pages.images.chooseGroup': 'Wähle eine Gruppe für das Tag:',
  'pages.images.newTag': 'Neues Tag',
  'pages.images.createTag': 'Tag erstellen',
  'pages.images.editTag': 'Tag bearbeiten',
  'pages.images.remove': 'Entfernen',
  'pages.images.removeTagGloballyTitle': 'Soll das Tag wirklich entfernt werden?',
  'pages.images.removeTagGloballySubTitle': 'Das Tag wird dann von allen Bildern entfernt.',
  'pages.images.upload': 'Hochladen',
  'pages.images.uploadDrawerTitle': 'Bild hochladen',
  'pages.images.uploadDraggerHint': 'Bild mit der höchsten Auflösung hochladen',
  'pages.images.uploadDraggerText':
    'Zum Hochladen, Dateien oder Ordner auf diesen Bereich ziehen oder klicken.',
  'pages.images.file': 'Datei',
  'pages.images.directory': 'Ordner',
  'pages.images.selectToBulkEdit': 'Wählen Sie Bilder aus, die Sie in großen Mengen taggen möchten',
  'pages.images.selectTagStatistics': 'Statistiken',
  'pages.images.deleteSelectedImagesConfirm': 'Wollen Sie (x) Bilder löschen?',
  'pages.images.setupCustomImageGallery':
    'Wir können Ihre ganz eigene Bildergalerie einrichten. Bitte kontaktieren Sie unseren Support für die nächsten Schritte.',
  'pages.images.noCustomImageGalleryAvailable':
    'Sie haben noch keine benutzerdefinierte Bildergalerie.',
  'pages.images.contactSupportToSetUpImageGallery':
    'Aktuell verwenden Sie keine eigene Bildergalerie. Wenn Sie mehr Kontrolle über das Bildmaterial möchten, können Sie hier eine eigene Galerie anfragen.',
  'pages.images.requestAccess': 'Eigene Galerie anfragen',

  // endregion

  // region CTA Page
  'pages.cta.performance': 'Click / Conversion',
  'pages.cta.page': 'Seite',
  'pages.cta.conversions.help': 'Benutzer, die den Fragebogen mit und ohne Termin ausgefüllt haben',
  // endregion

  // region Promotions Page

  'pages.promotions.help_1': 'Was sind Promotions?',
  'pages.promotions.help_2':
    'Promotions fügen automatisch aktuelle Werbeaktionen zu Ihren E-Mails hinzu. Sobald Sie eine Promotion anlegen und aktivieren, werden Kunden im angegebenen Zeitraum auf diese Aktion aufmerksam gemacht.',
  'pages.promotions.tableTitle': 'Promotions',
  'pages.promotions.showVideoTutorial': 'Video Tutorial anzeigen',
  'pages.promotions.newPromotion': 'Neue Promotion',
  'pages.promotions.addPromotion': 'Hinzufügen',
  'pages.promotions.editPromotion': 'Promotion bearbeiten',
  'pages.promotions.removePromotionConfirm':
    'Sind Sie Sicher, dass Sie diese Promotion löschen wollen?',
  'pages.promotions.type': 'Promotion Typ',
  'pages.promotions.modifiedDate': 'Zuletzt geändert',
  'pages.promotions.title': 'Titel',
  'pages.promotions.start': 'Start',
  'pages.promotions.end': 'Ende',
  'pages.promotions.dates': 'Laufzeit',
  'pages.promotions.removePromotionsSuccess': 'Promotion gelöscht',
  'pages.promotions.removePromotionsFailed': 'Promotion konnte nicht gelöscht werden',
  'pages.promotions.form': 'Formular',
  'pages.promotions.preview': 'Vorschau',
  'pages.promotions.promotionCondition': 'Bedingungen und Konditionen',
  'pages.promotions.addPromotionCondition': 'Bedingungen und Konditionen hinzufügen',
  'pages.promotion.selectPromotionConditionPlaceholder': 'Bitte auswählen',
  'pages.promotions.invalid_error': 'Bitte geben Sie eine gültige URL ein',

  // endregion

  //tag statistics
  'pages.stats.selectTagGroups': 'Wählen Sie bis zu 3 Tag-Gruppen aus, die Sie vergleichen möchten',
  'pages.stats.tagCombination': 'Tag Kombination',
  'pages.stats.frequency': 'Häufigkeit',
  //endregion

  // region Settings - visibility
  'pages.settings.visibility.active': 'Aktiv',
  'pages.settings.visibility.active.tooltip':
    'Sie können diese Journey auf allen Seiten deaktivieren',
  'pages.settings.visibility.clearRules':
    'Bitte entfernen Sie zuerst alle Regeln, um den Modus zu ändern.',
  'pages.settings.visibility.urlExample': `Sie können eine URL oder einen regulären Ausdruck verwenden, um regelbasiert die URL zu filtern.

Beispiele

kuechen         Alle Seiten mit URLs, die "kuechen" enthalten
kuechen/(.*)        Alle Unterseiten von "kuechen"
^https://domain.de/kuechen          Alle Seiten, die mit "^https://domain.de/kuechen" beginnen`,
  ...transformObjectToLocaleMap('pages.settings.visibility.example', {
    hint: 'Sie können eine URL oder einen regulären Ausdruck verwenden, um regelbasiert die URL zu filtern.',
    exampleTitle: 'Beispiele',
    urls: [
      {
        value: '/kuechen/i',
        description: 'Alle Seiten mit URLs, die "kuechen" enthalten',
      },
      {
        value: '/kuechen/(.*)/i',
        description: 'Alle Unterseiten von "kuechen"',
      },
      {
        value: '/https://domain.de/kuechen/i',
        description: 'Alle Seiten, die mit "https://domain.de/kuechen" beginnen',
      },
    ],
  }),
  'pages.settings.visibility.blackList': 'Seiten ausschließen',
  'pages.settings.visibility.blackList.tooltip':
    'Die Journey wird auf allen Seiten außer den hier aufgeführten angezeigt',
  'pages.settings.visibility.whiteList': 'Nur sichtbar auf',
  'pages.settings.visibility.whiteList.tooltip':
    'Die Journey wird nur auf den hier aufgeführten Seiten gezeigt',
  'pages.settings.configuration.editSuccessMessage': 'Einstellungen erfolgreich aktualisiert!',
  // endregion

  // Lead management
  'pages.leads': 'Leads',
  'pages.leads.CSVExport': 'Als CSV exportieren',
  'pages.leads.Service': 'Service',

  'pages.leads.appointment': 'Termin',
  'pages.leads.assignLeads': 'Leads zuweisen',

  'pages.lead.assignee.unassigned': 'Nicht zugewiesen',
  'pages.lead.assignee.createAssignee': 'Beauftragten hinzufügen',

  'pages.leads.assignee': 'Zuständiger',
  'pages.leads.branch': 'Filiale',
  'pages.leads.by': 'nach',
  'pages.leads.conversionRate': 'Konversionsrate',
  'pages.leads.deals': 'Deals',
  'pages.leads.filters': "Filter",
  'pages.leads.groupBy.label': 'Spalten wählen',
  'pages.leads.lead_state': 'Lead Status',
  'pages.leads.location': 'Standort',
  'pages.leads.marketingChannel': 'Marketing Kanal',
  'pages.leads.metrics': 'Metriken',
  'pages.leads.revenue': 'Umsatz',
  'pages.lead.tasks.taskLead': 'Lead',
  'pages.lead.tasks.taskTitle': 'Titel',
  'pages.lead.tasks.create': 'Neue Aufgabe',
  "pages.users.createUserFailed": 'Benutzer anlegen fehlgeschlagen.',
  "pages.users.createUserSuccess": 'Benutzer erfolgreich angelegt.',
  "pages.users.phone.invalidFormat": 'Die Telefonnummer hat ein ungültiges Format.',
  "pages.users.removeUserFailed": 'Benutzer entfernen fehlgeschlagen',
  "pages.users.removeUserSuccess": 'Benutzer erfolgreich entfernt',
  "pages.users.role": "Rolle",
  'pages.ForbiddenPage.message': 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen',
  'pages.users.addUser': 'Neuen Nutzer anlegen',
  'pages.users.close': 'Schließen',
  'pages.users.email': 'E-Mail',
  'pages.users.newUser': 'Nutzer hinzufügen',
  'pages.users.phone': 'Telefon',
  'pages.users.phone.errorMessage': 'Bitte gültige Telefonnummer eingeben.',
  'pages.users.removeUserConfirmation': 'Sind Sie sicher, dass Sie den Nutzer löschen möchten?',
  'pages.users.selectPlaceholder': 'Bitten wählen Sie eine Rolle',
  'pages.users.tableTitle': 'Benutzer',
  'pages.users.user': 'Benutzer',

  // endregion

  // Region Lead Sources
  'pages.leadsources.assignedTo': 'Zugewiesen an',
  'pages.leadsources.branch': 'Filiale',
  'pages.leadsources.configureWebhookStep': 'Webhook konfigurieren',
  'pages.leadsources.name': 'Name',
  'pages.leadsources.name.placeholder': 'Bitte Namen eingeben',
  'pages.leadsources.priority': 'Priorität',
  'pages.leadsources.reviewMappingAlert': 'Bitte überprüfen Sie Ihre Zuordnungen, bevor Sie fortfahren',
  'pages.leadsources.sepratorProblem': 'Gibt es ein Problem mit dem CSV-Trennzeichen?',
  'pages.leadsources.testAndMapStep': 'Testen und Daten zuordnen',
  'pages.leadsources.title': 'Lead Quelle wählen',
  'pages.leadsources.manual.chooseStatus': 'Status wählen',
  'pages.leadsources.delete': 'Löschen',
  'pages.leadsources.import': 'Importieren',
  'pages.leadsources.actions': 'Aktionen',
  'pages.leadsources.search': 'Suchen',
  'pages.leadsources.csv.new': 'Neue CSV Quelle anlegen',
  'pages.leadsources.removeConfirm': 'Soll diese Leadquelle wirklich entfernt werden?',
  'pages.leadsources.webhook.edit': 'Bearbeiten',
  'pages.leadsources.webhook.new': 'Neuen Webhook anlegen',
  'pages.leadsource.csv.parseError': 'Fehler beim Lesen der CSV-Datei',
  'pages.leadsource.csv.parseSuccess': 'CSV-Datei erfolgreich gelesen',
  'pages.leadsource.csv.mappingSuccessful': 'Daten erfolgreich zugeordnet',
  'pages.leadsources.csv.upload': 'Upload CSV',
  'pages.leadsources.csv.map': 'Spalten zuordnen',
  'pages.leadsources.csv.preview': 'Vorschau & Abschicken',
  'pages.leadsources.csv.errorReadingFile': 'Fehler beim Lesen der Datei',
  'pages.leadsources.csv.entryState': 'Status',
  'pages.leadsources.csv.state': 'Status',
  'pages.leadsources.csv.dropFile': 'Datei (CSV) auswählen oder hierhin ziehen',
  "pages.leadsources.selectCSVColumnsFor": "Wählen Sie CSV-Spalten für",
  "pages.leadsources.customFieldName": "Benutzerdefinierter Feldname",
  "pages.leadsources.enterCustomFieldName": "Geben Sie den benutzerdefinierten Feldnamen ein",
  "pages.leadsources.mapCSVColumns": "Zu CSV-Spalten zuordnen",
  'pages.leadsources.webhook.successfulMapping': 'Daten erfolgreich gesendet',
  'pages.leadsources.webhook.created': 'Webhook erfolgreich erstellt',
  'pages.leadsources.webhook.noSampleData': 'Der Webhook wurde erstellt, aber Sie haben noch keine Daten gesendet, um diese zuzuordnen.',
  'pages.leadsources.webhook.later': 'Später',
  'pages.leadsources.webhook.polling': 'Es wird alle 10 Sekunden geprüft, ob neue Daten vorliegen.',
  'pages.leadsurces.webhook.checkingForData': 'Prüfe, ob Daten empfangen wurden',
  "pages.leadsources.import_error_alert": "Die hochgeladene CSV-Datei enthält",
  "pages.leadsources.errors": "Fehler.",
  "pages.leadsources.errorDetails": "Fehlerdetails",
  "pages.leadsources.field": "Feld",
  "pages.leadsources.message": "Nachricht",
  "pages.leadsources.mappingConflictMessage": "Dieses Feld ist mit einem anderen CSV-Header verknüpft, der in Ihrem aktuellen CSV nicht mehr vorhanden ist",
  "pages.leadsources.addCustomField": "Benutzerdefiniertes Feld hinzufügen",
  "pages.leadsources.sendDataToServer": "Daten an den Server senden",
  "pages.leadsources.namePlaceholder": "Geben Sie den Namen der Quelle ein",
  "pages.leadsources.createWebhook": "Webhook erstellen",

  // endregion


  'pages.emailAutomation.name': 'Name',
  'pages.emailAutomation.trigger': 'Auslöser',
  'pages.emailAutomation.status': 'Status',
  'pages.emailAutomation.active': 'Aktiv',
  'pages.emailAutomation.actions': 'Aktionen',
  'pages.emailAutomation.removeConfirm': 'Soll diese Automatisierung wirklich entfernt werden?',
  'pages.emailAutomation.newAutomation': 'Neue Automatisierung',
  'pages.emailAutomation.to': 'An',

  'pages.emailAutomation.titleRequired': 'Bitte Titel eingeben',
  'pages.emailAutomation.toRequried': 'Bitte Empfänger auswählen',
  'pages.emailAutomation.selectPerson': 'Bitte Empfänger wählen',
  'pages.emailAutomation.lead': 'Lead',
  'pages.emailAutomation.locationManager': 'Location Manager',
  'pages.emailAutomation.assignee': 'Zuständiger',
  'pages.emailAutomation.otherUsers': 'Andere Benutzer',
  'pages.emailAutomation.type': 'Typ',
  'pages.emailAutomation.typeRequired': 'Bitte Typ wählen',
  'pages.emailAutomation.fromState': 'Von',
  'pages.emailAutomation.fromStaterRequired': 'Bitte Ausgangszustand wählen',
  'pages.emailAutomation.toState': 'nach',
  'pages.emailAutomation.toStateStaterRequired': 'Bitte Zielzustand wählen',
  'pages.emailAutomation.emailSubject': 'Betreff',
  'pages.emailAutomation.variables': 'Variablen',
  'pages.emailAutomation.emailContent': 'Inhalt',


  "pages.leads.addLead": "Lead hinzufügen",
  "pages.leads.assignLead": "Lead zuweisen",
  "pages.leads.leadGeneration": "Lead Generierung",
  "pages.leads.leadManagement": "Lead Management",
  "pages.leads.filters.leadSource.placeholder": "Lead Quelle",
  "pages.leads.filters.location.placeholder": "Filiale",
  "pages.leads.filters.assignee.placeholder": "Verkäufer",
  "pages.leads.filters.status.placeholder": "Status",
  "pages.leads.filters.marketingChannel.placeholder": "Marketing Kanal",
  "pages.leads.filters.priority.placeholder": "Priorität",
  "pages.lead.fields.name": "Name",
  "pages.lead.fields.email": "E-Mail",
  "pages.lead.fields.assignee": "Verantwortlicher",
  "pages.lead.fields.comments": "Kommentare",
  "pages.lead.fields.priority": "Priorität",
  "pages.lead.fields.createdAt": "Erstellungsdatuum",
  "pages.lead.fields.appointmentAt": "Termin",
  "pages.lead.fields.updatedAt": "Aktualisiert",
  "pages.lead.fields.dealSize": "Deal Volumen",
  "pages.lead.fields.leadSource": "Quelle",
  "pages.lead.fields.phone": "Telefon",
  "pages.lead.fields.branch": "Filiale",
  "pages.lead.fields.status": "Status",
  "pages.lead.fields.marketingChannel": "Marketing Kanal",
  "pages.lead.fields.note": "Notiz",
  "pages.lead.toolbar.view.kanban": "Kanban Ansicht",
  "pages.lead.toolbar.view.table": "Tabellen Ansicht",
  "pages.lead.toolbar.view.calendar": "Kalender Ansicht",
  "pages.lead.toolbar.reload": "Neu laden",
  "pages.lead.toolbar.filters": "Filter",
  "pages.lead.toolbar.propertyVisibilitySettings": "Sichtbare Eigenschaften",
  "pages.lead.toolbar.heatMapView": "Heatmap Ansicht",
  "pages.lead.toolbar.notHeatMapView": "Standardansicht",
  "pages.lead.sort.highestPriority": "Höchste Priorität",
  "pages.lead.sort.nameAscending": "Name A-Z",
  "pages.lead.sort.nameDescending": "Name Z-A",
  "pages.lead.sort.createdAscending": "Zuletzt erstellt",
  "pages.lead.sort.createdDescending": "Zuerst erstellt",
  "pages.lead.sort.modifiedAscending": "Zuletzt bearbeitet",
  "pages.lead.sort.modifiedDescending": "Zuerst bearbeitet",
  "pages.lead.toolbar.statusManagementSettings": "Status bearbeiten",
  "pages.lead.toolbar.settings": "Einstellungen",
  "pages.lead.visibilitySettings.search": "Suche...",
  "pages.lead.visibilitySettings.shown": "SIchtbar",
  "pages.lead.visibilitySettings.hidden": "Unsichtbar",
  "pages.lead.status.selectDealState": "Abschluss",
  "pages.lead.status.removeStatusConfirmation": "Status wirklich entfernen?",
  "pages.lead.status.selectLifeCycleAutomation": "Aktualisieren wenn",
  "pages.lead.status.new": "Status hinzufügen",
  "pages.lead.status.lifecycle.manual": "Manuell",
  "pages.lead.status.lifecycle.appointmentBooked": "Bei Terminbuchung",
  "pages.lead.status.lifecycle.appointmentCancelled": "Bei Terminabsage",
  "pages.leads.status.magicFields.settingsTitle": "Magic Fields bearbeiten",
  "pages.leads.status.magicFields.settingsDescription": "Es können einzelne Felder gewählt werden, die erscheinen, wenn ein Lead einen bestimmten Status erreicht. Verkäufer können die Daten dann schneller und effizienter eingeben.",
  "pages.leads.status.magicFields.modalTitle": "Hinweis beim Eintragen",
  "pages.leads.status.magicFields.modal.mandatoryFields.errorMessage": "Nicht alle erforderlichen Felder befüllt.",
  "pages.leads.status.magicFields.modal.mandatoryFields.title": "Erforderliche Felder",
  "pages.leads.status.magicFields.modal.mandatoryFields.description": "All diese Felder müssen befüllt sein, um die Status Änderung zu speichern.",
  "pages.leads.status.magicFields.modal.optionalFields.title": "Optionale Felder",
  "pages.leads.status.magicFields.modal.optionalFields.description": "Optionale Felder, die für künftige Berichte gesetzt werden sollten.",
  "pages.leads.status.magicFields.mandatory": "Erforderlich",
  "pages.leads.status.magicFields.optional": "Optional",
  "pages.leads.status.magicFields.notRequired": "Nihct erforderlich",
  "pages.lead.drawer.details": "Lead Details",
  "pages.lead.drawer.activities": "Aktivitäten",
  "pages.lead.drawer.tasks": "Aufgaben",
  "pages.lead.drawer.comments": "Kommentare",
  "pages.lead.action.archive.confirmation": "Lead wirklich archivieren?",
  "pages.lead.comment.newComment": "Kommentar hinzufügen",
  "pages.lead.tasks.new": "Neue Aufgabe",
  "pages.lead.tasks.title": "Titel",
  "pages.lead.tasks.taskDescription": "Beschreibung",
  "pages.lead.tasks.taskDue": "Fälligkeitsdatum",
  "pages.lead.tasks.showDone": "Abgeschlossene zeigen",
  "pages.lead.tasks.showOpen": "Offene Zeigen",
  "pages.leads.todayView.appointments": "Termine",
  "pages.leads.todayView.goToCalendar": "Zum Kalender",
  "pages.leads.todayView.today": "Heute",
  "pages.leads.todayView.tomorrow": "Morgen",

  'pages.leads.serviceRequests.upcomingServiceRequests': 'Bevorstehende Terminanfragen',
  'pages.leads.serviceRequests.pastServiceRequests': 'Vergangene Terminanfragen',
  "pages.lead.activities.changedStatus": "Status geändert",
  "pages.lead.activities.assigned": "Verantwortlich",
  "pages.lead.activities.created": "Erstellt",
  "pages.lead.activities.changedBranch": "Filiale geändert",
  "pages.lead.activities.customerChanged": "Kundendaten geändert",
  "pages.lead.activities.archived": "Archiviert",
  "pages.lead.activities.unarchived": "Unarchiviert",
  "pages.lead.activities.rescheduledAppointment": "Termin verschoben"
};

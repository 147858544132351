import {processAPIResponse} from "@/utils/request";
import {message} from "antd";

import {
  createCSVLeadSourceRequest,
  deleteLeadSource,
  fetchLeadFields,
  fetchLeadSources,
  getLeadSource,
  updateCSVLeadSourceRequest,
} from "@/services/api/lead";
import {fetchMeRequest} from "@/services/api/user";


const initialState = {
  leadSourceList: [],
  leadDetailsDrawer: null,
  leadSourceDetail: {lead_source: {}},
  leadFields: {
    default: [],
    meta: []
  },

}
export default {
  namespace: 'leadSources',

  state: initialState,

  effects: {

    * fetchLeadSources({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchLeadSources);
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setLeadSources',
            payload: {
              leadSourceList: data
            }
          })
        }
      })
    },
    * fetchLeadFields({payload = {}}: { payload: { search?: string, url?: string } }, {call, put, select}) {
      const {response, data} = yield call(fetchLeadFields);
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadFields',
            payload: data?.data
          })
        }
      })
    },

    * fetchMe({payload = {}}, {call, put, select}) {
      const {response, data} = yield call(fetchMeRequest);
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'journey/gotJourneys',
            payload: data?.Journeys
          })
        }
      })
    },

    * createWebhookLeadSource({payload}, {call, put, select}) {
      const {response, data} = yield call(createCSVLeadSourceRequest, {
        data: {...payload.allData}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetchMe',
          })
          yield put({
            type: 'fetchLeadSource',
            payload: {id: data?.data?.lead_source?.id}
          })
          try {
            if (payload?.cb) payload?.cb(null, data?.data);
          } catch (error) {
            console.error('Error in callback:', error);
          }
        }
        , * onError(error) {
          message.error(error?.error?.message)

        }
      })
    },
    * updateWebhookLeadSource({payload}, {call, put, select}) {
      const {response, data} = yield call(updateCSVLeadSourceRequest, {
        data: {...payload.allData},
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          try {
            yield put({
              type: 'fetchMe',
            })
            yield put({
              type: 'fetchLeadSource',
              payload: {id: data?.data?.lead_source?.id}
            })
            if (payload?.cb) payload?.cb(null, data?.data);
          } catch (error) {
            console.error('Error in callback:', error);
          }
        }
        , * onError(error) {

          message.error(error?.error?.message)

        }
      })


    },
    * createCSVLeadSource({payload}, {call, put, select}) {
      const {response, data} = yield call(createCSVLeadSourceRequest, {
        data: {...payload.allData}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetchMe',
            payload: data?.data
          })
          yield put({
            type: 'leads/createCSVLeads',
            payload: {
              ...payload, sourceId:
              data?.data?.lead_source?.id
            }
          })
        }
        , * onError(error) {
          message.error(error?.error?.message)

        }
      })


    },

    * deleteLeadSource({payload: {id, cb, intl}}, {call, put}) {

      const {response, data} = yield call(deleteLeadSource, {params: {sourceId: id}});
      yield processAPIResponse({response, data}, {
        * onSuccess() {
          yield put({
            type: 'fetchMe',
          })
          intl && message.success(intl.formatMessage({
            id: "pages.leads.removeLeadSourceSuccess",
            defaultMessage: 'Lead Source deleted successfully'
          }))
          cb && cb(data)
        },
        * onError({error}) {
          cb && cb(error)
          message.error(error?.message || intl.formatMessage({
            id: "pages.leads.removeLeadSourceFailed",
            defaultMessage: 'Remove Lead Source failed'
          }))
        },
      })
    },
    * fetchLeadSource({payload: {id}}: { payload: { id?: string } }, {call, put, select}) {
      const {response, data} = yield call(getLeadSource, {params: {sourceId: id}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveLeadSourceDetail',
            payload: data,
          })
        }
      }, 'data')
    },
    * updateCSVLeadSource({payload}, {call, put, select}) {
      const callback = payload?.cb

      const {response, data} = yield call(updateCSVLeadSourceRequest, {
        data: {...payload.allData},
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {

          yield put({
            type: 'fetchMe',
          })
          yield put({
            type: 'leads/createCSVLeads',
            payload: {
              ...payload, sourceId:
              data?.data?.lead_source?.id
            }
          })
        }
        , * onError({error}) {
          callback && callback(error, false)

          message.error(error?.error?.message)

        }
      })


    },
  },

  reducers: {
    setLeadSources(state, action) {
      const {
        payload: {leadSourceList},
      } = action;

      return {
        ...state,
        leadSourceList
      };
    },

    saveLeadFields(state, action) {
      return {
        ...state,
        leadFields: action.payload,
      };
    },

    saveLeadSourceDetail(state, action) {
      return {
        ...state,
        leadSourceDetail: action.payload,
      };
    },
    reset(){
      return initialState
    }
  }
};

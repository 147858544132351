import React from 'react';
import {Input} from 'antd';
import {useIntl} from "umi";

export default function SearchBar({ value, onSearch }) {
  const intl = useIntl()
  return (
    <Input
      placeholder={intl.formatMessage({
        id: 'pages.lead.visibilitySettings.search'
      })}
      value={value}
      onChange={(e) => onSearch(e.target.value)}
      style={{ marginBottom: 16 }}
    />
  );
}

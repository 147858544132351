import * as React from "react"
import {useEffect} from "react"
import {useDispatch, useIntl, useSelector} from "umi";

import {F} from "@/utils/intl";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import * as LocationSelectors from "@/selectors/locationSelectors";

const BranchServiceSelect = ({ onSelectionChange, ...props }: any) => {
  const branchServices = useSelector(LocationSelectors.selectBranchServices);
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    if ((branchServices || []).length === 0){
      dispatch({
        type: 'location/fetch'
      })
    }
  }, []);

  const handleSelectionChange = (branchServiceId, branchService) => {
    onSelectionChange && onSelectionChange(branchServiceId);
    props.onChange && props.onChange(branchServiceId, branchService)
  };

  const optionRender = branchService => {
    return <div>
      <strong>{branchService.branch.name}</strong> - <span>{branchService.service_type}</span>
    </div>
  }

  return (
    <LeadDropdownFilter
      placeholder={<F id={"pages.leads.filters.branchService.placeholder"} defaultMessage={'Service'}/>}
      filterKey={'branchService'}
      options={branchServices.map(service => ({
        ...service,
        label: `${service.service_type}_${service.branch.name}`.toLowerCase()
      }))}
      {...props}
      onChange={handleSelectionChange}
      optionRender={optionRender}
    />
  );
};

export default BranchServiceSelect;

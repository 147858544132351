export default {
    'tour.common.back': 'Zurück',
    'tour.common.close': 'Schließen',
    'tour.common.last': 'Letzte',
    'tour.common.next': 'Nächste',
    'tour.common.skip': 'Überspringen',
    'tour.leads.rangePicker': 'Zeitraum zum Filtern der Daten wählen.',
    'tour.leads.filtersCard': 'Hier filtern Sie Daten nach Agent, Filiale, Marketing Kanal und Quelle.',
    'tour.leads.metricSection': 'Sie können die Daten nach bestimmten Kriterien filtern und gruppieren.',
    'tour.leads.groupBySection': 'Sie können die Daten nach bestimmten Kriterien filtern und gruppieren.',
    'tour.leads.exportBtn': 'Hier klicken, um die Diagramm-Daten als CSV zu exportieren.',
    'tour.leads.barChartCard': 'Dieses Balkendiagramm zeigt die Metriken gefiltert und gruppiert nach Ihren Auswahlen.',
}
import {message} from "antd";
import {RequestResponse, ResponseError} from "umi-request";
import get from "lodash/get";
import {getIntl} from "umi";

export function getFromAndToDate(date) {
  return {
    from: date.start,
    to: date.end
  };
}


interface RequestCallbacks {
  onError?: (error?: ResponseError) => void,
  onSuccess: (data: any) => void | any,
  onEnd?: () => void,
  defaultErrorMessage?: string
}

export function* processAPIResponse({response, data}: RequestResponse, {
  onError,
  onSuccess,
  onEnd,
  defaultErrorMessage
}: RequestCallbacks, dataKey?: string) {
  onEnd && onEnd()
  if (!defaultErrorMessage)
    defaultErrorMessage = getIntl().formatMessage({id: 'pages.common.serverError', defaultMessage: 'There was an error processing your request.'})

  try {
    if (response.status >= 200 && response.status < 300) {
      yield onSuccess(dataKey ? get(data, dataKey) : data);
    } else {
      if (!onError) {
        message.error(data?.message || data?.error?.message || defaultErrorMessage);
      } else {
        yield onError(data)
      }
    }
  } catch (e) {
    if (!onError) {
      let errorMessage = "Error"
      if (data) {
        errorMessage = data.message
      }
      message.error(errorMessage);
    } else {
      yield onError(data)
    }
  }

}


export const LEAD_PROPERTIES = {
  NAME: 'name',
  EMAIL: 'email',
  ASSIGNEE: 'assignee',
  COMMENTS: 'comments',
  PHONE: 'phone',
  PRIORITY: 'priority',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  APPOINTMENT_AT: 'appointmentAt',
  DEAL_SIZE: 'dealSize',
  LEAD_SOURCE: 'leadSource',
  MARKETING_CHANNEL: 'marketingChannel',
  STATUS: 'status',
  BRANCH: 'branch',
  SOURCE: 'leadSource',
  NOTE: 'note',
}
export const ORDERED_LEAD_PROPERTIES = [LEAD_PROPERTIES.NAME, LEAD_PROPERTIES.EMAIL, LEAD_PROPERTIES.STATUS, LEAD_PROPERTIES.BRANCH, LEAD_PROPERTIES.PHONE, LEAD_PROPERTIES.CREATED_AT,
  LEAD_PROPERTIES.UPDATED_AT, LEAD_PROPERTIES.DEAL_SIZE, LEAD_PROPERTIES.PRIORITY, LEAD_PROPERTIES.MARKETING_CHANNEL, LEAD_PROPERTIES.NOTE, LEAD_PROPERTIES.ASSIGNEE]

export const STATUS_MAGIC_PROPERTIES = [LEAD_PROPERTIES.DEAL_SIZE, LEAD_PROPERTIES.PRIORITY, LEAD_PROPERTIES.ASSIGNEE, LEAD_PROPERTIES.PHONE, LEAD_PROPERTIES.BRANCH, LEAD_PROPERTIES.NOTE]

export const INITIAL_PROPERTY_VISIBILITY_SETTINGS = {
  shown: [LEAD_PROPERTIES.NAME, LEAD_PROPERTIES.STATUS, LEAD_PROPERTIES.EMAIL, LEAD_PROPERTIES.PHONE, LEAD_PROPERTIES.ASSIGNEE],
  hidden: [LEAD_PROPERTIES.CREATED_AT, LEAD_PROPERTIES.UPDATED_AT, LEAD_PROPERTIES.DEAL_SIZE, LEAD_PROPERTIES.MARKETING_CHANNEL, LEAD_PROPERTIES.SOURCE, LEAD_PROPERTIES.NOTE]
}
